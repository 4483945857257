import React, { Component, forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { Field, Label, Control, Error } from 'components/form/field/s'
import { Actions, Button, Plain } from './s'

const empty = null

class EditYepNopeField extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field.required !== this.props.field.required
        const includeChanged = field.include !== this.props.field.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        let {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            type: 'trilean',

            empty,
            required,
            include,

            unset: this.unset,

            validator: value => required ?
                [true, false].includes(value) :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    set = value => {
        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })
    }

    unset = () => this.set(empty)

    render() {
        const { value } = this.state

        const {
            className,
            field = {},
            label,
            name,
            enabled = true,
            loading = false,
            error: outsideError
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = ((name in this.props.form.errors) && touched) || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const {
            required,
            optional,
            unsettable = true
        } = field

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        required={required}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        type="hidden"
                        name={name}
                        value={value ?? ''} />
                    <Actions className="start compact">
                        <Button
                            className={cls(['neutral', value === true && 'selected constructive'])}
                            disabled={!enabled}
                            onClick={() => this.set(true)}>
                            <FormattedMessage
                                id="boolean_true"
                                defaultMessage="Yes" />
                        </Button>
                        <Button
                            className={cls(['neutral', value === false && 'selected constructive'])}
                            disabled={!enabled}
                            onClick={() => this.set(false)}>
                            <FormattedMessage
                                id="boolean_false"
                                defaultMessage="No" />
                        </Button>
                        {(!required && unsettable) && (
                            <Plain
                                onClick={this.unset}
                                disabled={![true, false].includes(value)}>
                                <FormattedMessage
                                    id="action_reset"
                                    defaultMessage="Reset" />
                            </Plain>
                        )}
                    </Actions>
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                </Control>
            </Field>
        )
    }
}

export default forwardRef((props, ref) => {
    const form = useForm()

    return (
        <EditYepNopeField
            {...props}
            form={form}
            forwardedRef={ref} />
    )
})
