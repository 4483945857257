import styled from 'styled-components'
import { fromPhone } from 'utilities/styled'
import HeroActionsBase from 'components/hero-actions'
import TutorialBase from 'components/tutorial'

export const HeroActions = styled(HeroActionsBase)`
    margin-bottom: 32px;
`

export const Tutorial = styled(TutorialBase)`
    ${fromPhone`
        margin-top: 32px;
    `}
`