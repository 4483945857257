import styled from 'styled-components'
import { screenReaderOnly } from 'utilities/styled'
import { RadiobuttonInput } from 'components/form/input/radiobutton'

export const OptionsColumns = styled.div`
    display: flex;
    align-items: start;
    column-gap: 8px;

    min-height: 56px;

    &.centered {
        align-items: center;
    }
`

export const Radiobutton = styled(RadiobuttonInput)`
    ${screenReaderOnly}
`

export const RadiobuttonColumn = styled.label`
    cursor: pointer;
    display: grid;
    place-items: center;

    border-radius: 8px;
    width: 56px;
    height: 56px;
    background-color: var(--huma-color-surface-minimal);

    transition: .1s ease-in-out;
    transition-property: outline, background-color, color;

    color: var(--huma-color-foreground-default);

    &:has(> ${Radiobutton}:disabled) {
        cursor: not-allowed;

        color: var(--huma-color-foreground-disabled);
    }

    &:has(> ${Radiobutton}:disabled:checked) {
        background-color: var(--huma-color-surface-selected-disabled);

        color: var(--huma-color-foreground-on-selected-disabled);
    }

    &:not(:has(> ${Radiobutton}:disabled), :has(> ${Radiobutton}:checked)):hover {
        background-color: var(--huma-color-surface-minimal-hover);
    }

    &:not(:has(> ${Radiobutton}:disabled)):focus-within {
        outline: 2px solid var(--huma-color-border-constructive-focus);
        outline-offset: 1px;
    }

    &:has(> ${Radiobutton}:checked) {
        background-color: var(--huma-color-surface-constructive-bold);

        color: var(--huma-color-foreground-inverted);
    }
`