import React, { useRef, useState, useCallback, useEffect, useLayoutEffect, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useIntegrations } from 'contexts/integrations'
import { useAccess } from 'contexts/access'
import { useMatch } from 'react-router-dom'
import { categoriesInOrder } from 'utilities/categories'
import { filter } from 'utilities/object'
import { compact } from 'utilities/array'
import paths from 'app/paths'
import integrationData from 'pages/integrations/pages/integration/data'
import {
    PlainNavigations, AnimatedNavigations,
    PlainNavigation, AnimatedNavigation,
    NavigationItems
} from './s'

import Corporation from './items/corporation'
import Dashboard from './items/dashboard'
import People from './items/people'
import Salary from './items/salary'
import Handbook from './items/handbook'
import Absence from './items/absence'
import Meetings from './items/meetings'
import News from './items/news'
import Onboarding from './items/onboarding'
import Offboarding from './items/offboarding'
import Processes from './items/processes'
import Documents from './items/documents'
import Competence from './items/competence'
import Equipment from './items/equipment'
import Tasks from './items/tasks'
import Deviation from './items/deviation'
import Insights from './items/insights'
// import EmployeeSatisfaction from './items/employee-satisfaction'
import Surveys from './items/surveys'
import Whistleblowing from './items/whistleblowing'
import ActivityLog from './items/activity-log'
import Integrations from './items/integrations'
import SystemSettings from './items/system-settings'
import Back from './items/back'
import ServiceOnboarding from './items/service-onboarding'

import { MenuSeparator, SubmenuTitleSeparator } from './items/s'

import SystemSettingsGeneral from './items/system-settings/general'
import SystemSettingsModules from './items/system-settings/modules'
import SystemSettingsSubscription from './items/system-settings/subscription'
import SystemSettingsCustomProfileFields from './items/system-settings/custom-profile-fields'
import SystemSettingsJobTitles from './items/system-settings/job-titles'
import SystemSettingsRoles from './items/system-settings/roles'
import SystemSettingsDomainVerification from './items/system-settings/domain-verification'

import IntegrationsOverview from './items/integrations/overview'
import IntegrationsItem from './items/integrations/item'

import CorporationSubsidiaries from './items/corporation/subsidiaries'
import CorporationInsights from './items/corporation/insights'

const primaryItemGroups = [
    {
        dashboard: Dashboard,
        people: People,
        salary: Salary,
        handbook: Handbook,
        absence: Absence,
        meetings: Meetings,
        news: News,
        onboarding: Onboarding,
        offboarding: Offboarding,
        processes: Processes,
        documents: Documents,
        competence: Competence,
        equipment: Equipment,
        tasks: Tasks,
        deviation: Deviation,
        insights: Insights,
        // employeeSatisfaction: EmployeeSatisfaction,
        surveys: Surveys,
        whistleblowing: Whistleblowing
    },
    {
        activityLog: ActivityLog,
        integrations: Integrations,
        systemSettings: SystemSettings
    }
]

const useGetSecondaryItemGroup = () => {
    const { integrations } = useIntegrations()

    const [groups, setGroups] = useState({
        systemSettings: [
            {
                settings: SystemSettingsGeneral,
                subscription: SystemSettingsSubscription,
                customProfileFields: SystemSettingsCustomProfileFields,
                modules: SystemSettingsModules,
                jobTitles: SystemSettingsJobTitles,
                roles: SystemSettingsRoles,
                domainVerification: SystemSettingsDomainVerification
            }
        ],
        corporation: [
            {
                subsidiaries: CorporationSubsidiaries,
                insights: CorporationInsights
            }
        ]
    })

    useEffect(() => {
        setGroups(groups => ({
            ...groups,
            integrations: [
                {
                    overview: IntegrationsOverview,
                    ...integrations.reduce((accumulator, integration) => {
                        const data = integrationData[integration.type]
                        const { id, name, icon } = data()

                        return {
                            ...accumulator,
                            [id]: props => (
                                <IntegrationsItem
                                    {...props}
                                    integration={integration}
                                    type={id}
                                    name={name}
                                    icon={icon} />
                            )
                        }
                    }, {})
                }
            ]
        }))
    }, [integrations.map(({ type, status }) => `${type}:${status}`).join('+')])

    return useCallback(drill => groups[drill?.to] ?? [], [Object.keys(groups)])
}

const sort = items => Object.entries(items).sort(([one], [two]) => {
    const oneIndex = categoriesInOrder.indexOf(one)
    const twoIndex = categoriesInOrder.indexOf(two)

    if(!!~oneIndex && !~twoIndex) {
        return -1
    } else if(!~oneIndex && !!~twoIndex) {
        return 1
    } else if(!~oneIndex && !~twoIndex) {
        return 0
    }

    return oneIndex - twoIndex
})

const getInert = criteria => !!criteria ? ({ inert: 'true' }) : null

const MainNavigation = ({ parentRefs, closeMenu }) => {
    const { organization } = useOrganization()

    const {
        initialized,

        check,
        checkModule
    } = useAccess()

    const access = {
        systemSettings: [
            'users:manage',
            'users:custom-fields:manage',
            'roles:manage',
            'organization:manage'
        ]
            .map(check)
            .some(Boolean),
        integrations: checkModule('integrations') && check('organization:manage'),
        corporation: !!organization?.subsidiaryCount && check('subsidiaries:manage')
    }

    const inSubTree = {
        systemSettings: useMatch(`${paths.settings.base}/*`),
        integrations: useMatch(`${paths.integrations.base}/*`),
        corporation: useMatch(`${paths.corporation.base}/*`)
    }

    const navigations = useRef()

    const [drill, setDrill] = useState({
        to: null,
        enabled: false
    })

    useLayoutEffect(() => {
        const [key] = Object.keys(filter(inSubTree, value => !!value))
        const enabled = initialized && Object.keys(access).some(Boolean)

        const to = (enabled && key && access[key]) ?
            key :
            null

        setDrill({ to, enabled })
    }, [Object.values(inSubTree).join('+'), initialized, Object.values(access).join('+')])

    const onNavigate = useCallback(({ target }) => {
        if(!target) {
            return
        }

        if(target.type !== 'a') {
            target = target.closest('a')
        }

        // This is the stuff that ensures that the menu doesn’t close on small
        // screens if there’s a submenu. Add the className to parent items.
        if(target?.classList.contains('xscender')) {
            requestAnimationFrame(() => {
                const toTheTop = compact([...parentRefs, navigations])
                toTheTop.forEach(ttt => ttt.current?.scrollTo(0, 0))
            })
        } else closeMenu()
    }, [parentRefs])

    if(!initialized) {
        return null
    }

    const [Navigations, navigationsProps] = drill.enabled ?
        [AnimatedNavigations, { ref: navigations }] :
        [PlainNavigations, null]

    const [Navigation, navigationProps] = drill.enabled ?
        [AnimatedNavigation, {
            animate: !!drill.to ? 'out' : 'in',
            initial: !!drill.to ? 'out' : 'in',
            className: 'primary'
        }] :
        [PlainNavigation, null]

    return (
        <Navigations {...navigationsProps}>
            <Navigation {...navigationProps}>
                <NavigationItems onClickCapture={onNavigate}>
                    <Corporation {...getInert(drill.to)} />
                    <ServiceOnboarding />
                    {primaryItemGroups.map((items, index) => (
                        <Fragment key={`navigation:primary:group:${index}`}>
                            {sort(items).map(([key, PrimaryMenuItem]) => (
                                <PrimaryMenuItem
                                    {...getInert(drill.to)}
                                    key={`navigation:primary:${key}`} />
                            ))}
                            {(index < (primaryItemGroups.length - 1)) && (
                                <MenuSeparator />
                            )}
                        </Fragment>
                    ))}
                </NavigationItems>
            </Navigation>
            {!!drill.enabled && (
                <Navigation
                    animate={!!drill.to ? 'in' : 'out'}
                    initial={!!drill.to ? 'in' : 'out'}
                    className="secondary">
                    <NavigationItems onClickCapture={onNavigate}>
                        <Back {...getInert(!drill.to)} />
                        <ServiceOnboarding dividerAbove />
                        <DrillItems drill={drill} />
                    </NavigationItems>
                </Navigation>
            )}
        </Navigations>
    )
}

const DrillItems = ({ drill }) => {
    const getSecondaryItemGroup = useGetSecondaryItemGroup()

    if(!drill.to) {
        return null
    }

    const title = drill.enabled && getSubmenuTitle(drill.to)
    const groups = getSecondaryItemGroup(drill)

    return (
        <>
            {!!title && (
                <SubmenuTitleSeparator className="caption compact">
                    <FormattedMessage {...title} />
                </SubmenuTitleSeparator>
            )}
            {groups.map((items, index) => {
                const groupKey = `navigation:secondary:${drill.to}:group:${index}`

                return (
                    <Fragment key={groupKey}>
                        {sort(items).map(([key, SecondaryMenuItem]) => (
                            <SecondaryMenuItem
                                {...getInert(!drill.to)}
                                key={`${groupKey}:item:${key}`} />
                        ))}
                        {(index < (groups.length - 1)) && <MenuSeparator />}
                    </Fragment>
                )
            })}
        </>
    )
}

const getSubmenuTitle = submenu => ({
    systemSettings: {
        id: 'noun_settings_system',
        defaultMessage: 'System settings'
    },
    integrations: {
        id: 'noun_integrations',
        defaultMessage: 'Integrations'
    },
    corporation: {
        id: 'noun_corporation',
        defaultMessage: 'Corporation'
    }
})[submenu] ?? null

export default MainNavigation