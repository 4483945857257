import React from 'react'
import { useIntl } from 'react-intl'
import { Wrapper, Status } from './s'
import Tooltip, { useSingleton } from 'components/tooltip'

const StatusGroup = ({ statuses, active, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const ActiveStatus = active.node

    const [source, target] = useSingleton()

    return (
        <Wrapper {...props}>
            <Tooltip
                singleton={source}
                delay={[0, 250]} />
            {Object.entries(statuses).map(([id, label]) => {
                const isActive = id === active.id

                if(isActive) {
                    return (
                        <ActiveStatus
                            status={id}
                            key={`${salt}:${id}`} />
                    )
                }

                return (
                    <Tooltip
                        singleton={target}
                        content={formatMessage(label)}
                        key={`${salt}:${id}`}>
                        <Status />
                    </Tooltip>
                )
            })}
        </Wrapper>
    )
}

export default StatusGroup