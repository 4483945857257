import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrapper, Name, PreviewButton, Subheading, ActionButton, Symbol } from './s'
import Ellipsify from 'components/ellipsify'
import { fallbackSymbol } from 'pages/meetings'

const MeetingTemplateItem = forwardRef(({ template, preview, action, ...props }, ref) => {
    const {
        id,
        name,
        symbol
    } = template ?? {}

    return (
        <Wrapper
            {...props}
            ref={ref}>
            <Name>
                <Ellipsify
                    text={name}
                    head={5} />
            </Name>
            {!!id && (
                <PreviewButton onClick={preview}>
                    <FormattedMessage
                        id="action_preview"
                        defaultMessage="Preview" />
                </PreviewButton>
            )}
            {!id && (
                <Subheading>
                    <FormattedMessage
                        id="action_start_from_scratch"
                        defaultMessage="Start from scratch" />
                </Subheading>
            )}
            {!!action && (
                <ActionButton
                    className="constructive"
                    onClick={() => action?.onClick()}
                    disabled={action?.disabled}>
                    {action.label}
                </ActionButton>
            )}
            <Symbol
                symbol={symbol ?? fallbackSymbol}
                className="hover-effect" />
        </Wrapper>
    )
})

export default MeetingTemplateItem
