import styled from 'styled-components'
import { belowContainer } from 'utilities/styled'
import { containerProps as usersContainerProps } from '../../s'
import { Grid } from 'components/grid'
import CardListBase from 'components/card-list'

export const RolesWrapper = styled(Grid)`
    position: relative;

    row-gap: 24px;

    min-height: 72px;
    padding: 16px 56px;

    &.compact {
        padding: 0;
    }

    ${belowContainer(usersContainerProps)`
        padding-inline-end: 0;
    `}
`

export const containerProps = {
    name: 'roles-user',
    breakpoint: 400
}

export const CardList = styled(CardListBase)`
    grid-template-columns:
        [role] minmax(0, 1fr)
        [permissions] min-content
        [actions] 40px;

    .header {
        padding-block-end: 0;
        box-shadow: none;

        > div {
            grid-column: 1 / -1;
        }
    }

    ${belowContainer(containerProps)`
        display: grid;
        grid-template-columns:
            [role] minmax(0, 1fr)
            [actions] 40px;
    `}
`

export const CardListRow = styled(CardListBase.Row)`
    &:last-child {
        padding-block-end: 0;
    }

    ${belowContainer(containerProps)`
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
    `}
`

export const CardListCell = styled(CardListBase.Cell)`
    ${belowContainer(containerProps)`
        ${p => p.$cell === 'permissions' && `
            display: none;
        `}
    `}
`