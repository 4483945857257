import styled from 'styled-components'
import {
    svgStroke,
    belowLaptop
} from 'utilities/styled'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { linkCss } from 'components/link'
import { h3Css } from 'components/typography/heading'
import { tiptapContentCss } from 'components/tiptap/s'
import { Grid } from 'components/grid'
import SymbolBase from 'components/symbol'
import { Background, hoverEffect as symbolHoverEffect } from 'components/symbol/s'
import { InlineMessage as InlineMessageBase } from 'components/message'
import { Plain } from 'components/button'

export const Symbol = styled(SymbolBase)`
    flex-shrink: 0;
    flex-basis: var(--symbol-size);
`

export const Heading = styled(Link)`
    ${h3Css}
    ${linkCss}

    display: inline-flex;
    justify-content: space-between;
    column-gap: 16px;

    margin: 0;
    min-height: 40px;
    padding-top: 6px;

    line-height: 30px;

    svg {
        ${svgStroke()}

        transition: transform .2s ease-in-out;
    }

    &:hover,
    &:focus {
        svg {
            transform: translateX(4px);
        }
    }
`

export const Summary = styled.div`
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    ${tiptapContentCss}
`

export const Drawer = styled(motion.div).attrs(() => ({
    variants: {
        collapsed: {
            height: 0,
            opacity: 0,
            marginBottom: '-8px'
        },
        expanded: {
            height: 'auto',
            opacity: 1,
            marginBottom: 0
        },
    },
    transition: {
        duration: .15
    },
    initial: 'expanded'
}))`
    overflow: clip;
    overflow-clip-margin: 4px;
`

export const CategoryContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`

export const InlineMessage = styled(InlineMessageBase)`
    grid-column: 2 / -1;

    max-width: calc(100% - 40px);

    transition: margin .15s ease-in-out;

    section:not(.expanded) > & {
        margin-top: -24px;
    }
`

export const AddButton = styled(Plain)`
    svg {
        ${svgStroke()}

        margin-inline-end: 16px;
    }
`

export const Category = styled(Grid).attrs(({ as: 'section' }))`
    grid-template-columns: 40px minmax(0, 1fr);
    row-gap: 16px;
    column-gap: 32px;

    padding-block: 16px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    transition: padding .15s ease-in-out, margin .15s ease-in-out;

    ${belowLaptop`
        column-gap: 16px;
    `}

    &.expanded {
        padding-block: 24px;
    }

    &:not(.expanded):has(${InlineMessage}) ${Heading} span {
        margin-top: -6px;
    }

    &.fade :where(${Symbol}, ${Heading} > span) {
        opacity: .5;
    }

    &:has(${Heading}:hover, ${Heading}:focus) > ${Symbol} ${Background} {
        ${symbolHoverEffect}
    }
`