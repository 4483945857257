import React from 'react'
import loadable from '@loadable/component'
import { useInstanceId } from 'hooks/instance-id'
import { useCssVariables } from 'hooks/viewport'
import { useLocationChange } from 'hooks/location-change'
import { useAuth } from 'contexts/auth'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import paths from 'app/paths'
import { Routes, Route } from 'react-router-dom'

// Public stuff
import RouteErrorBoundary from './route-error-boundary'

import LoginPage from 'pages/login'
import SignupPage from 'pages/signup'
import RelinquishPage from 'pages/relinquish'

// Private stuff
import PrivateLayout from './layouts/private'
import RouteAuthorization from './route-authorization'
import PathRedirect from './path-redirect'

const CorporationPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/corporation'))
const DashboardPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/dashboard'))
const PeoplePage = loadable(() => import(/* webpackPrefetch: true */ 'pages/people'))
const SalaryPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/salary'))
const HandbookPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/handbook'))
const AbsencePage = loadable(() => import(/* webpackPrefetch: true */ 'pages/absence'))
const MeetingsPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/meetings'))
const NewsPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/news'))
const ProcessesPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/processes'))
const DocumentsPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/documents'))
const CompetencePage = loadable(() => import(/* webpackPrefetch: true */ 'pages/competence'))
const EquipmentPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/equipment'))
const TasksPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/tasks'))
const DeviationPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/deviation'))
const InsightsPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/insights'))
const SurveysPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/surveys'))
const WhistleblowingPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/whistleblowing'))
const ActivityLogPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/activity-log'))
const IntegrationsPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/integrations'))
const SettingsPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/settings'))
const NotFoundPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/not-found'))


/*******************************************************************
    In the case of page deprecations or migrations, make sure to
    keep the old path in app/paths.js, and add appropriate
    redirects to the affected routes.

    <Route
        path={paths.module.__DEPRECATED__page}
        element={<PathRedirect path={paths.module.page} />} />

    <Route
        path={paths.module.__MIGRATED__page}
        element={<PathRedirect path={paths.module.page} />} />

*******************************************************************/


const App = () => {
    useInstanceId()
    useCssVariables()
    useLocationChange(() => global.HubSpotConversations?.widget?.refresh?.())

    const {
        status,
        native: { platform }
    } = useAuth()

    const { check } = useAccess()
    const { organization } = useOrganization()

    const corporationRestricted = !organization?.subsidiaryCount || !check('subsidiaries:manage')

    return (
        <>
            {(status !== 'authorized') && (
                <Routes>
                    <Route
                        path={paths.root}
                        key="root:public"
                        element={(
                            <RouteErrorBoundary>
                                <LoginPage />
                            </RouteErrorBoundary>
                        )} />
                    <Route
                        path={paths.signup}
                        element={(
                            <RouteErrorBoundary>
                                {(platform === 'web') && <SignupPage />}
                                {(platform !== 'web') && <LoginPage />}
                            </RouteErrorBoundary>
                        )} />
                    <Route
                        path={paths.relinquish}
                        element={(
                            <RouteErrorBoundary>
                                <RelinquishPage />
                            </RouteErrorBoundary>
                        )} />
                    <Route
                        path="*"
                        element={<PathRedirect path={paths.root} />} />
                </Routes>
            )}
            {(status === 'authorized') && (
                <PrivateLayout>
                    <Routes>
                        <Route
                            path={paths.root}
                            key="root:private"
                            element={(
                                <RouteAuthorization module="dashboard">
                                    <DashboardPage />
                                </RouteAuthorization>
                            )} />
                        <Route
                            path={paths.dashboard}
                            element={<PathRedirect path={paths.root} />} />

                        <Route
                            path={`${paths.corporation.base}/*`}
                            element={(
                                <RouteAuthorization
                                    module="corporation"
                                    restricted={corporationRestricted}>
                                    <CorporationPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.people.base}/*`}
                            element={(
                                <RouteAuthorization module="people">
                                    <PeoplePage />
                                </RouteAuthorization>
                            )} />
                        <Route
                            path={`${paths.salary.base}/*`}
                            element={(
                                <RouteAuthorization module="salary">
                                    <SalaryPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.handbook.base}/*`}
                            element={(
                                <RouteAuthorization module="handbook">
                                    <HandbookPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.absence.base}/*`}
                            element={(
                                <RouteAuthorization module="absence">
                                    <AbsencePage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.meetings.base}/*`}
                            element={(
                                <RouteAuthorization module="performance">
                                    <MeetingsPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.news.base}/*`}
                            element={(
                                <RouteAuthorization module="news">
                                    <NewsPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.onboarding.base}/*`}
                            element={(
                                <RouteAuthorization
                                    module="onboarding"
                                    permissions={[
                                        'users:manage', // Includes access to manage employment lifecycles
                                        'unit:employment-lifecycles:manage',
                                        'employment-lifecycles:templates:manage'
                                    ]}>
                                    <ProcessesPage type="onboarding" />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.offboarding.base}/*`}
                            element={(
                                <RouteAuthorization
                                    module="offboarding"
                                    permissions={[
                                        'users:manage', // Includes access to manage employment lifecycles
                                        'unit:employment-lifecycles:manage',
                                        'employment-lifecycles:templates:manage'
                                    ]}>
                                    <ProcessesPage type="offboarding" />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.processes.base}/*`}
                            element={(
                                <RouteAuthorization
                                    module="processes"
                                    permissions={[
                                        'users:manage', // Includes access to manage processes
                                        'unit:processes:manage',
                                        'processes:templates:manage'
                                    ]}>
                                    <ProcessesPage type="process" />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.documents.base}/*`}
                            element={(
                                <RouteAuthorization module="documents">
                                    <DocumentsPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.competence.base}/*`}
                            element={(
                                <RouteAuthorization module="competence">
                                    <CompetencePage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.equipment.base}/*`}
                            element={(
                                <RouteAuthorization module="equipment">
                                    <EquipmentPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.tasks.base}/*`}
                            element={(
                                <RouteAuthorization module="tasks">
                                    <TasksPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.deviation.base}/*`}
                            element={(
                                <RouteAuthorization module="deviation">
                                    <DeviationPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={paths.insights}
                            element={(
                                <RouteAuthorization
                                    module="insights"
                                    permissions={['insights:manage', 'unit:insights:manage']}>
                                    <InsightsPage />
                                </RouteAuthorization>
                            )} />

                        {/* <Route
                            path={`${paths.employeeSatisfaction.base}/*`}
                            element={(
                                <RouteAuthorization module="surveys">
                                    <EmployeeSatisfactionPage />
                                </RouteAuthorization>
                            )} /> */}

                        <Route
                            path={`${paths.surveys.base}/*`}
                            element={(
                                <RouteAuthorization
                                    module="surveys"
                                    permissions={['surveys:read', 'surveys:write']}>
                                    <SurveysPage />
                                </RouteAuthorization>
                            )} />
                        <Route
                            path={`${paths.settings.__MIGRATED__employeeSatisfaction}/*`}
                            element={<PathRedirect path={paths.surveys.base} />} />

                        <Route
                            path={`${paths.whistleblowing.base}/*`}
                            element={(
                                <RouteAuthorization
                                    module="whistleblowing"
                                    permissions={['organization:manage', 'whistleblowing:manage']}>
                                    <WhistleblowingPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={paths.activityLog}
                            element={(
                                <RouteAuthorization>
                                    <ActivityLogPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.integrations.base}/*`}
                            element={(
                                <RouteAuthorization module="integrations">
                                    <IntegrationsPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={`${paths.settings.base}/*`}
                            element={(
                                <RouteAuthorization>
                                    <SettingsPage />
                                </RouteAuthorization>
                            )} />

                        <Route
                            path={paths.settings.__MIGRATED__userroles.base}
                            element={<PathRedirect path={paths.settings.roles.user.base} />} />
                        <Route
                            path={paths.settings.__MIGRATED__userroles.role.base}
                            element={<PathRedirect path={paths.settings.roles.user.role.base} />} />
                        <Route
                            path={paths.settings.__MIGRATED__userroles.role.grants}
                            element={<PathRedirect path={paths.settings.roles.user.role.grants} />} />
                        <Route
                            path={paths.settings.__MIGRATED__userroles.standard.base}
                            element={<PathRedirect path={paths.settings.roles.user.standard.base} />} />
                        <Route
                            path={paths.settings.__MIGRATED__userroles.standard.role}
                            element={<PathRedirect path={paths.settings.roles.user.standard.role} />} />
                        <Route
                            path={paths.settings.__MIGRATED__systemroles.base}
                            element={<PathRedirect path={paths.settings.roles.system.base} />} />
                        <Route
                            path={paths.settings.__MIGRATED__systemroles.role.base}
                            element={<PathRedirect path={paths.settings.roles.system.role.base} />} />
                        <Route
                            path={paths.settings.__MIGRATED__systemroles.role.grants}
                            element={<PathRedirect path={paths.settings.roles.system.role.grants} />} />

                        <Route
                            path={paths.login}
                            element={<PathRedirect path={paths.root} />} />
                        <Route
                            path={paths.signup}
                            element={<PathRedirect path={paths.root} />} />

                        <Route
                            path="*"
                            element={(
                                <RouteAuthorization>
                                    <NotFoundPage />
                                </RouteAuthorization>
                            )} />
                    </Routes>
                </PrivateLayout>
            )}
        </>
    )
}

export default App