import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'

const ViewIcon = ({ label, className, ...props }) => {
    const { field = {} } = props
    let { value } = field

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                {!!value && (
                    <ValueDisplay>
                        {value}
                    </ValueDisplay>
                )}
                {!value && (
                    <ValueDisplay className="empty">
                        <FormattedMessage
                            id="empty_none_set"
                            defaultMessage="None set" />
                    </ValueDisplay>
                )}
            </Control>
        </Field>
    )
}

export default ViewIcon