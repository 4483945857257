import React, { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { iOSSafari, belowTablet, darkMode } from 'utilities/styled'
import ModalProvider, { useModal } from 'contexts/modal'
import { useTouch } from 'hooks/viewport'
import { flexRowsCss } from 'components/flex'
import { FocusOn } from 'react-focus-on'
import { hideChat, showChat } from 'hooks/voiceflow'

const ModalBase = styled.div.attrs({
    role: 'modal',
    'aria-labelledby': 'Modal title', // TODO
    'aria-describedby': 'Modal description' // TODO
})`
    display: grid;
    grid-template: 1fr / 1fr;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    overscroll-behavior: contain;
    transform: translateZ(0);

    ${iOSSafari`
        position: absolute;
        min-height: initial;
        height: var(--viewport-height);

        transform: translateY(calc(var(--window-height) - var(--viewport-height) + var(--offset-y))) scale(var(--viewport-scale));
        transition: all 0.2s ease-out;
    `}

    ${belowTablet`
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    `}

    &.fixed {
        align-items: start;

        ${iOSSafari`
            transform: translateY(0);
        `}
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
    }
`

const FocusModal = ({
    onOutsideClick,
    overlayVirtualKeyboard = false,
    focusOnEnabled = true,
    autoFocus = true,
    returnFocus = true,
    children = null,
    ...props
}) => {
    const {
        dismiss,
        dirty
    } = useModal()

    const touch = useTouch()

    useLayoutEffect(() => {
        if(focusOnEnabled) {
            document.body.classList.add('focus-on', 'blocking')
            document.documentElement.style.setProperty('--offset-y', '0px')

            global.requestAnimationFrame(() => {
                // Delay it so that it triggers after context menus
                hideChat()

                const scrollLayer = document.querySelector('#modals [data-focus-lock-disabled="false"] > div')
                scrollLayer?.scroll?.({ top: 0 })
            })

            return () => {
                if(document.querySelectorAll([
                    '#modals [data-focus-lock-disabled]',
                    '#prompts [data-focus-lock-disabled]'
                ].join(',')).length === 1) {
                    document.body.classList.remove('focus-on', 'blocking')
                    showChat()
                }
            }
        }
    }, [focusOnEnabled])

    useLayoutEffect(() => {
        if('virtualKeyboard' in navigator && !!overlayVirtualKeyboard) {
            navigator.virtualKeyboard.overlaysContent = true
        }

        return () => {
            if('virtualKeyboard' in navigator) {
                navigator.virtualKeyboard.overlaysContent = false
            }
        }
    }, [overlayVirtualKeyboard])

    return (
        <FocusOn
            enabled={focusOnEnabled}
            autoFocus={touch ? false : autoFocus}
            preventScrollOnFocus={true}
            returnFocus={returnFocus}
            onEscapeKey={dirty ?
                () => {} :
                dismiss
            }>
            <ModalBase
                {...props}
                onClick={e => {
                    e.stopPropagation()

                    if(e.target.getAttribute('role') === 'modal') {
                        onOutsideClick?.()
                    }
                }}>
                {children}
            </ModalBase>
        </FocusOn>
    )
}

export const Modal = ({ dismiss, ...props }) => (
    <ModalProvider dismiss={dismiss}>
        <FocusModal {...props} />
    </ModalProvider>
)

export const modalify = modal => createPortal(
    modal,
    document.getElementById('modals')
)

export const veilCss = css`
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    background-color: rgb(from var(--huma-palette-neutral-10) r g b / .2);

    ${darkMode`
        background-color: rgb(from var(--huma-palette-neutral-10) r g b / .4);
    `}
`

export const commonContainerCss = css`
    --modal-container-padding: 32px;
    --border-radius: 8px;

    position: relative;

    margin: 10vh auto;
    border-radius: var(--border-radius);
    width: 400px;
    max-width: 90vw;
    padding: var(--modal-container-padding);
    background-color: var(--huma-color-surface-default);
    box-shadow:
        0 -4px 16px 0 var(--huma-color-shadow),
        var(--huma-shadow-high);

    color: var(--huma-color-foreground-default);

    ${darkMode`
        box-shadow: inset 0 0 0 1px var(--huma-color-border-default);
    `}

    ${belowTablet`
        --modal-container-padding: 24px;

        margin-block: 0;
        max-width: calc(100vw - 16px);
    `}

    &.fixed {
        margin-block: 80px 0;

        ${belowTablet`
            margin-block: 8px 0;
        `}
    }

    &.medium {
        max-width: calc(100vw - 16px);
        width: 808px;
    }

    &.large {
        margin-inline: auto;
        max-width: calc(100vw - 16px);
        width: 1048px;

        ${belowTablet`
            max-width: 100vw;
        `}
    }
`

export const Wrapper = AnimatePresence

export const Veil = styled(motion.div).attrs(attrs => ({
    ...attrs,

    variants: {
        out: {
            opacity: 0,
            transition: {
                duration: .1
            }
        },
        in: {
            opacity: 1,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'out',
    exit: 'out'
}))`
    ${veilCss}
`

const ContainerBase = styled(motion.div).attrs(({ afterOpen, afterClose, ...attrs }) => ({
    ...attrs,

    variants: {
        out: {
            transform: 'translateY(33.33vh)',
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                type: 'spring',
                mass: 0.25,
                stiffness: 200,
                velocity: 4,
                damping: 10
            }
        },
        in: {
            transform: 'translateY(0vh)',
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                type: 'spring',
                mass: 0.5,
                stiffness: 100,
                velocity: 2
            }
        }
    },
    initial: 'out',
    exit: 'out',

    onAnimationComplete: variant => {
        if(variant === 'in') {
            afterOpen?.()
        } else {
            afterClose?.()
        }
    },

    onChange: e => {
        e.stopPropagation()
    }
}))``

const veil = css`
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        border-radius: 8px;
        transition: background .1s ease;
        pointer-events: none;
    }

    [data-focus-lock-disabled="false"].doelja &::after {
        background-color: rgb(from var(--huma-palette-neutral-10) r g b / .1);

        transition: background .3s ease;
    }
`

export const Container = styled(ContainerBase)`
    ${flexRowsCss}
    ${commonContainerCss}
    max-height: 80svh;
    ${veil}

    ${belowTablet`
        max-height: calc(100svh - 32px);
        margin-block: 0;
    `}
`

export const ScrollableContainer = styled(ContainerBase)`
    ${flexRowsCss}
    ${commonContainerCss}
    ${veil}

    ${belowTablet`
        margin-block: 16px;
    `}
`