import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { CloseButton as CloseButtonBase } from 'components/button'

export const Wrapper = styled.div`
    position: sticky;
    top: 0;
    grid-row: hero;
    grid-column: 1 / -1;

    padding-inline: 56px;

    ${belowTablet`
        padding-inline: 32px;
    `}
`

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-block: 12px;
`

export const CloseButton = styled(CloseButtonBase)`
    position: relative;
    top: 0;
    left: -8px;
`