import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Naked } from 'components/button'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { InlineMessage as InlineMessageBase } from 'components/message'
import { ArrowRight } from 'styled-icons/feather'

export const Item = styled(Naked)`
    display: grid;
    grid-template-columns:
        [symbol] 40px
        [meta] minmax(0, 1fr)
        [arrow] 24px;
    align-items: center;
    column-gap: 16px;

    outline: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    max-width: 360px;
    padding: 12px;

    text-align: start;

    &:only-child {
        max-width: unset;
    }

    &.warning {
        outline-color: var(--huma-color-border-warning);
        outline-width: 2px;
    }

    &.error {
        outline-color: var(--huma-color-border-error);
        outline-width: 2px;
    }

    &:focus-visible {
        outline-color: var(--huma-color-border-constructive-focus);
        outline-width: 2px;
        outline-offset: 2px;
    }
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(Item)}
`

export const Title = styled(Paragraph)`
    ${Item}:is(:hover, :focus-visible) & {
        text-decoration: underline;
    }
`

export const Meta = styled.div``

export const InlineMessage = styled(InlineMessageBase)`
    display: inline-flex;
    vertical-align: middle;
`

export const Arrow = styled(ArrowRight)`
    ${svgStroke()}

    transition: transform .1s ease-in-out;

    ${Item}:is(:hover, :focus-visible) & {
        transform: translateX(4px);
    }
`