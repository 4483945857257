import React from 'react'
import loadable from '@loadable/component'
import { useIntl } from 'react-intl'
import { Routes, Route } from 'react-router-dom'
import { compact } from 'utilities/array'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import paths from 'app/paths'
import Hero from './hero'
import { TabsAndPanes, getPathWithoutBase } from 'components/tabs'
import RouteAuthorization from 'app/route-authorization'
import DelayedRedirect from 'components/delayed-redirect'

import EventsPane from './panes/events'

const RoundsPane = loadable(() => import(/* webpackPrefetch: true */ './panes/rounds'))
const TemplatesPane = loadable(() => import(/* webpackPrefetch: true */ './panes/templates'))

const MeetingPage = loadable(() => import(/* webpackPrefetch: true */ './pages/meeting'))
const RoundPage = loadable(() => import(/* webpackPrefetch: true */ './pages/round'))
const TemplatePage = loadable(() => import(/* webpackPrefetch: true */ './pages/template'))

const salt = 'meetings'

const MeetingsRouting = ({ upgradable }) => {
    const { formatMessage } = useIntl()

    const { base } = paths.meetings
    const getRelativePath = getPathWithoutBase(base)

    const {
        check,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const manageAccess = check('performance:manage')

    const meetingRoundsAvailable = checkFeature('meeting-rounds')
    const meetingRoundsUpgradable = checkUpgradable({ feature: 'meeting-rounds' })

    const meetingTemplatesAvailable = checkFeature('meeting-templates')
    const meetingTemplatesUpgradable = checkUpgradable({ feature: 'meeting-templates' })

    const panes = compact([
        {
            path: base,
            label: formatMessage({
                id: 'meetings_section_your_meetings',
                defaultMessage: 'Your meetings'
            }),
            pane: EventsPane
        },
        ((meetingRoundsAvailable && manageAccess) || meetingRoundsUpgradable) && {
            path: paths.meetings.rounds.base,
            label: formatMessage({
                id: 'noun_meeting_rounds',
                defaultMessage: 'Meeting rounds'
            }),
            pane: RoundsPane,
            upgradable: meetingRoundsUpgradable
        },
        ((meetingTemplatesAvailable && manageAccess) || meetingTemplatesUpgradable) && {
            path: paths.meetings.templates.base,
            label: formatMessage({
                id: 'noun_templates',
                defaultMessage: 'Templates'
            }),
            pane: TemplatesPane,
            upgradable: meetingTemplatesUpgradable,
            tabUpgradable: false
        }
    ]).map(pane => ({
        ...pane,
        upgradable: pane.upgradable ?? upgradable
    }))

    return (
        <Routes>
            <Route element={(
                <>
                    <Hero />
                    <TabsAndPanes
                        panes={panes}
                        salt={salt} />
                </>
            )}>
                {panes.map(({ path, pane: Pane, label, upgradable, itemSalt, ...props }) => {
                    const key = `${salt}:pane:${itemSalt ?? label.toLowerCase()}`

                    return (
                        <Route
                            path={getRelativePath(path)}
                            element={(
                                <RouteAuthorization
                                    module="performance"
                                    upgradable={upgradable}>
                                    <Pane
                                        {...props}
                                        title={label}
                                        salt={salt}
                                        key={key} />
                                </RouteAuthorization>
                            )}
                            key={`${key}:route`} />
                    )
                })}
            </Route>
            <Route
                path={getRelativePath(paths.meetings.meeting)}
                element={(
                    <RouteAuthorization module="performance">
                        <MeetingPage />
                    </RouteAuthorization>
                )} />
            <Route
                path={getRelativePath(paths.meetings.rounds.new)}
                element={(
                    <RouteAuthorization module="performance">
                        <RoundPage />
                    </RouteAuthorization>
                )} />
            <Route
                path={getRelativePath(paths.meetings.rounds.round)}
                element={(
                    <RouteAuthorization module="performance">
                        <RoundPage />
                    </RouteAuthorization>
                )} />
            <Route
                path={getRelativePath(paths.meetings.templates.template)}
                element={(
                    <RouteAuthorization module="performance">
                        <TemplatePage />
                    </RouteAuthorization>
                )} />
            <Route
                path="*"
                element={<DelayedRedirect to={base} />} />
        </Routes>
    )
}

export default MeetingsRouting