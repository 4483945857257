import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocationQueryIntent, useLocationStateIntent } from 'hooks/location-intent'
import { Ghost } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import Content from './content'

const salt = 'meeting'

const AddMeeting = props => {
    const [adding, setAdding] = useState(false)
    const [concerns, setConcerns] = useState(null)

    useLocationQueryIntent({
        intent: 'add',
        action: () => setAdding(true)
    })

    useLocationStateIntent({
        intent: 'create-event',
        action: ({ concerns }) => {
            setAdding(true)
            setConcerns(concerns)
        }
    })

    return (
        <>
            <Ghost
                onClick={() => setAdding(true)}
                className="constructive"
                disabled={!!adding}>
                <FormattedMessage
                    id="meeting_action_add"
                    defaultMessage="New meeting" />
            </Ghost>
            <Modal
                show={!!adding}
                dismiss={() => setAdding(false)}
                salt={`${salt}:add`}>
                <Content
                    {...props}
                    concerns={concerns}
                    onDone={() => setAdding(false)}
                    salt={`${salt}:add`} />
            </Modal>
        </>
    )
}

export default AddMeeting