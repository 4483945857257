import styled, { css } from 'styled-components'
import {
    svgStroke,
    bp, belowPhone, Safari } from 'utilities/styled'
import { fontSerif } from 's'

export const headingMixins = css`
    &.compact {
        margin-block-end: 0 !important;
    }

    &.preserve-line-breaks {
        white-space: pre-line;
    }

    &.center {
        text-align: center;
    }

    &.serif {
        ${fontSerif}
    }

    &.strike {
        text-decoration: line-through;
    }

    &.balance-text-rows {
        text-wrap: balance;
    }
`

export const headingCss = css`
    ${headingMixins}

    ${Safari`
        word-break: keep-all; /* Has a bug with quotes that breaks */
    `}

    > svg {
        display: flex;
    }
`

const Heading = styled.span`
    ${headingCss}
`

export const articleHeadingCss = css`
    margin: 0 0 16px;
    font-weight: 500;
    line-height: 1.25;

    @supports not (font-size: clamp(1rem, 2vw, 3ch)) {
        ${bp.between('phone', 'tablet')`
            font-size: 5vw;
        `}

        ${belowPhone`
            font-size: 7.5vw;
        `}
    }

    @supports (font-size: clamp(1rem, 2vw, 3ch)) {
        font-size: clamp(20px, 7.5vw, 32px);
    }
`

export const ArticleHeading = styled(Heading).attrs({
    as: 'h1',
    itemProp: 'title'
})`
    ${articleHeadingCss}
    ${headingMixins}
`

export const h1Css = css`
    ${fontSerif}
    font-size: 32px;
    line-height: 1;
    font-weight: 500;
    margin: 0 0 16px;

    > svg {
        width: 32px;
        height: 32px;

        ${svgStroke()}

        &:first-child {
            margin-right: 8px;
        }
    }
`

export const titleCss = css`
    ${h1Css}
    line-height: 40px;
    ${headingMixins}
`

export const Title = styled(Heading).attrs(() => ({
    as: 'h1',
    itemProp: 'title'
}))`
    ${titleCss}
`

export const H1 = styled(Heading).attrs({ as: 'h1' })`
    ${h1Css}
    ${headingMixins}
`

export const sheriffCss = css`
    font-size: 24px;
    line-height: 32px;
    ${fontSerif}
    font-weight: 500;
`

export const Sheriff = styled(Heading).attrs({ as: 'h1' })`
    ${sheriffCss}
    ${headingMixins}
`

export const h2Css = css`
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
    margin: 0 0 12px;

    > svg {
        width: 28px;
        height: 28px;

        ${svgStroke()}

        &:first-child {
            margin-right: 8px;
        }
    }
`

export const H2 = styled(Heading).attrs({ as: 'h2' })`
    ${h2Css}
    ${headingMixins}
`

export const ModalHeading = styled(H2)`
    margin-bottom: 16px;
    line-height: 1.25;

    ${headingMixins}

    button + & {
        max-width: calc(100% - 40px);
    }
`

export const h3Css = css`
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    margin: 0 0 8px;

    > svg {
        width: 24px;
        height: 24px;

        &:first-child {
            margin-right: 8px;
        }
    }
`

export const H3 = styled(Heading).attrs({ as: 'h3' })`
    ${h3Css}
    ${headingMixins}
`

export const h4Css = css`
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;

    &.spacious {
        margin-block-end: 8px;
    }

    > svg {
        width: 20px;
        height: 20px;

        &:first-child {
            margin-right: 8px;
        }
    }
`

export const H4 = styled(Heading).attrs({ as: 'h4' })`
    ${h4Css}
    ${headingMixins}
`
