import styled from 'styled-components'
import { H3 } from 'components/typography/heading'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'
import { eventItemGrid } from 'lists/meeting/events/event/s'

export const SkeletonListRow = styled(SkeletonListRowBase)`
    ${eventItemGrid}
    align-items: center;
`

export const Heading = styled(H3)`
    margin-block-end: 16px;

    font-size: 16px;
    font-weight: 400;
    color: var(--huma-color-foreground-subtle);
`

export const Wrapper = styled.div`
    &:not(:empty) {
        margin-block-end: 40px;
    }
`

export const LoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`