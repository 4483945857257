import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { usePerson } from 'contexts/person'
import RolesByUsersProvider from 'contexts/roles-by-users'
import RolesByUserProvider from 'contexts/roles-by-user'
import { getRoleUrl } from 'utilities/url'
import { pick } from 'utilities/object'
import { useGetRoleName } from 'pages/settings/pages/roles/utilities'
import { Container, HeadingColumns, Heading, Spacer } from '../s'
import { RolesWrapper } from './s'
import RolesList from 'pages/settings/pages/roles/lists/users/user/roles'
import PermissionsSummaryModal from 'pages/settings/pages/roles/pages/role/permissions-summary-modal'

const Roles = ({ salt }) => {
    const { formatMessage } = useIntl()

    const { person } = usePerson()

    const [viewingSubordinates, setViewingSubordinates] = useState(null)
    const [previewingPermissions, setPreviewingPermissions] = useState(null)

    const getRoleName = useGetRoleName()

    const openAction = ({ role }) => {
        if(role.name === 'supervisor') {
            return null
        }

        return {
            salt: `${salt}:open`,
            label: formatMessage({
                id: 'action_open',
                defaultMessage: 'Open'
            }),
            effect: 'neutral',
            element: 'link',
            link: {
                to: getRoleUrl(role)
            }
        }
    }

    const manageSubordinatesAction = ({ role, user }) => {
        if(role.name !== 'supervisor') {
            return null
        }

        return {
            salt: `${salt}:manage-subordinates`,
            label: formatMessage({
                id: 'subordinates_action_manage',
                defaultMessage: 'Manage subordinates'
            }),
            effect: 'neutral',
            onClick: () => setViewingSubordinates({
                userId: user.id,
                roleId: role.id
            })
        }
    }

    const seePermissionsAction = ({ role }) => ({
        salt: `${salt}:see-permissions`,
        label: formatMessage({
            id: 'role_permissions_action_see',
            defaultMessage: 'See permissions'
        }),
        effect: 'neutral',
        onClick: () => setPreviewingPermissions(role)
    })

    const actions = {
        open: openAction,
        manageSubordinates: manageSubordinatesAction,
        seePermissions: seePermissionsAction
    }

    return (
        <Container id="roles">
            <HeadingColumns>
                <Heading>
                    <FormattedMessage
                        id="person_pane_about_section_roles"
                        defaultMessage="Roles and access" />
                </Heading>
                <Spacer />
            </HeadingColumns>
            <RolesWrapper>
                <RolesList
                    user={person}
                    actions={actions}
                    viewingSubordinates={viewingSubordinates}
                    setViewingSubordinates={setViewingSubordinates}
                    setPreviewingPermissions={setPreviewingPermissions}
                    context="profile"
                    className="compact"
                    salt={salt} />
            </RolesWrapper>
            <PermissionsSummaryModal
                show={!!previewingPermissions}
                {...pick(previewingPermissions, 'domain', 'id')}
                name={getRoleName(previewingPermissions)}
                onDone={() => setPreviewingPermissions(null)}
                dismiss={() => setPreviewingPermissions(null)}
                salt={salt} />
        </Container>
    )
}

export default ({ access, ...props }) => {
    const { id } = useParams()
    const { person } = usePerson()

    if(access?.available === false || person.status?.active === false) {
        return null
    }

    return (
        <RolesByUsersProvider fetchOnMount={false}>
            <RolesByUserProvider id={id}>
                <Roles {...props} />
            </RolesByUserProvider>
        </RolesByUsersProvider>
    )
}