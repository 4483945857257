import styled from 'styled-components'
import {
    svgStroke,
    fromTablet, belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { CloseButton as CloseButtonBase } from 'components/button'
import ModuleBase from 'pages/settings/pages/modules/content/module'
import FeatureBase from 'pages/settings/pages/modules/content/module/feature'
import { QuotaCell } from 'pages/settings/pages/modules/content/module/s'
import TutorialBase from 'components/tutorial'
import FeedbackQuestionsBase from '../help/feedback-questions'

export const Header = styled(Grid)`
    position: relative;
    grid-template-columns: auto 40px 40px;

    margin-block-end: 16px;
    padding-block-end: 32px;

    ${belowTablet`
        padding-block-end: 16px;
    `}

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -32px;

        width: calc(100% + 64px);
        height: 1px;
        background-color: var(--huma-color-surface-default);
    }
`

export const HeadingColumns = styled(Grid)`
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    column-gap: 16px;

    > svg {
        ${svgStroke()}
    }
`

export const Module = styled(ModuleBase)`
    padding: 0;

    ${fromTablet`
        margin-inline-end: 24px;
    `}

    & > ${QuotaCell} {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        flex-direction: column;
    }
`

export const Feature = styled(FeatureBase)`
    grid-template-columns: 40px minmax(0, 1fr) minmax(0, auto) !important;

    padding: 0;

    & > ${QuotaCell} {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        flex-direction: column;
    }
`

export const CloseButton = styled(CloseButtonBase)`
    position: relative;
    inset: unset;

    grid-row: 1;
    grid-column: 3;

    margin-block-start: 2px;
`

export const Tutorial = styled(TutorialBase)`
    padding: 0;
`

export const FeedbackQuestions = styled(FeedbackQuestionsBase)`
    align-self: center;

    margin-block-start: 40px;
    max-width: var(--huma-global-content-tight-width);
    width: 100%;
`