import React, { Component, createContext, useContext } from 'react'
import { get, patch, remove } from 'api'
import { pick, reduce, size } from 'utilities/object'
import { throttle } from 'utilities/function'
import isEqual from 'react-fast-compare'

export const MeetingTemplateContext = createContext()

export default class MeetingTemplateProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)
        this.setChanges = throttle(this.setChanges, 250)

        this.state = {
            template: null,
            remoteTemplate: null,
            errors: null,
            changed: false,
            saving: false,
            deleted: false,

            fetchTemplate: this.fetch,
            updateTemplate: this.update,
            saveTemplate: this.save,
            removeTemplate: this.remove,

            addAgendaPoint: this.addAgendaPoint,
            updateAgendaPoint: this.updateAgendaPoint,
            removeAgendaPoint: this.removeAgendaPoint,
            addAgendaPointsFromTemplate: this.addAgendaPointsFromTemplate,

            setErrors: this.setErrors
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        !!fetchOnMount && this.fetch()
    }

    componentDidUpdate({ id }) {
        if(id !== this.props.id) {
            this.replace(pick(this.props, 'id'))
        }
    }

    setId = id => this.id = id

    fetch = async () => {
        if(!this.id) {
            return
        }

        const { response: template, ok } = await get({
            path: `/performance/templates/${this.id}`
        })

        if(ok) {
            this.setId(template.id)

            this.setState({
                template,
                remoteTemplate: template
            })
        }
    }

    // Local update only
    update = (body, options = {}) => void this.setState(({ template }) => ({
        template: {
            ...template,
            ...body
        }
    }), () => {
        const {
            after,
            silent = false
        } = options

        if(!silent) {
            this.setChanged()
        }

        if(typeof after === 'function') {
            after()
        }
    })

    save = async (options = {}) => {
        const { silent = false } = options

        !silent && this.setState({ saving: true })

        const { changes: body } = this.getChanges()

        const { ok, response: template } = await patch({
            path: `/performance/templates/${this.id}`,
            body
        })

        if(ok) {
            this.setState({
                template,
                remoteTemplate: template,
                changed: false,
                ...(!silent ? { saving: false } : null)
            })
        }

        return { ok }
    }

    remove = async id => {
        const { ok } = await remove({
            path: `/performance/templates/${id ?? this.id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    setErrors = errors => void this.setState({ errors })

    // Local update only
    addAgendaPoint = ({ body }) => {
        if(body.title) {
            this.update({
                agenda: [
                    ...(this.state.template.agenda ?? []),
                    body
                ]
            })
        }
    }

    // Local update only
    updateAgendaPoint = ({ body, index }) => {
        if(body.title) {
            this.update({
                agenda: [
                    ...this.state.template.agenda.slice(0, index),
                    body,
                    ...this.state.template.agenda.slice(index + 1, this.state.template.agenda.length)
                ]
            })
        }
    }

    // Local update only
    removeAgendaPoint = index => {
        const agenda = [...(this.state.template.agenda ?? [])]
        agenda.splice(index, 1)

        this.update({ agenda })
    }

    // Local update only
    addAgendaPointsFromTemplate = async selectedTemplate => {
        if(!!selectedTemplate?.agenda?.length) {
            this.update({
                agenda: [
                    ...this.state.template.agenda,
                    ...selectedTemplate.agenda
                ]
            })
        }
    }

    getChanges = () => {
        const {
            template,
            remoteTemplate
        } = this.state

        const changes = reduce(template, (accumulator, value, key) => {
            const different = (key === 'description') ?
                areDifferentAndNotBothFalsy(value, remoteTemplate[key]) :
                !isEqual(value, remoteTemplate[key])

            if(!different) {
                return accumulator
            }

            return {
                ...accumulator,
                [key]: value
            }
        })

        return {
            changes: size(changes) ? changes : null,
            changed: !!size(changes)
        }
    }

    setChanged = () => void this.setState(pick(this.getChanges(), 'changed'))

    replace = id => {
        this.setId(id)

        this.setState({
            template: null,
            remoteTemplate: null,
            changed: false
        }, this.fetch)
    }

    clearFlash = () => void this.setState(({ template }) => {
        this.flash.current = null

        return {
            template: {
                ...template,
                agenda: [...template.agenda]
            }
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <MeetingTemplateContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </MeetingTemplateContext.Provider>
        )
    }
}

const areDifferentAndNotBothFalsy = (one, two) => one !== two && !(!one && !two)

export const useMeetingTemplate = () => useContext(MeetingTemplateContext)