import styled from 'styled-components'
import { MeetingTemplateList } from 'pages/meetings/components/template-provider/s'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import SymbolBase from 'components/symbol'
import { siblingHover } from 'components/symbol/s'
import { paragraphCss } from 'components/typography/paragraph'
import { captionSmallCss } from 'components/typography/caption'

export const Wrapper = styled(Grid)`
    grid-template-rows: 24px 20px;
    grid-template-columns: 40px minmax(0, 1fr) min-content;
    column-gap: 16px;
    align-items: center;

    ${MeetingTemplateList} > & {
        padding: 14px 0;

        &:not(:last-of-type) {
            box-shadow: 0 1px 0 var(--huma-color-border-default);
        }
    }

    *:not(${MeetingTemplateList}):not(.flat) > & {
        border: 1px solid var(--huma-color-border-default);
        border-radius: 8px;
        padding: 10px 16px;
    }
`

export const ActionButton = styled(Plain).attrs({ className: 'constructive' })`
    grid-row: 1 / 3;
    grid-column: 3 / 4;
`

export const Name = styled.h3`
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    ${paragraphCss}
`

export const PreviewButton = styled(Plain).attrs({ className: 'constructive small' })`
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    justify-self: start;

    border: 0;
`

export const Subheading = styled.span`
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    ${captionSmallCss}
`

export const Symbol = styled(SymbolBase)`
    ${siblingHover(ActionButton)}
    ${siblingHover(PreviewButton)}

    grid-row: 1 / 3;
    grid-column: 1 / 2;
`