import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { Options, Option, OptionLabel, PickButton } from './s'
import { Field, Label, Control } from 'components/form/field/s'

const empty = null

class EditContractableSelect extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()
    }

    componentDidMount() {
        this.props.form.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name, field }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = getFieldFromProps(this.props)

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,
            unset: this.unset,

            validator: value => required ?
                (!!value || value === 0) :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => {
        const value = props.field?.value ?? empty

        return {
            value,
            picking: !value
        }
    }

    unset = () => void this.setState({
        value: empty,
        picking: true
    }, () => {
        this.props.form.triggerChange(this.props.name)
        this.props.onChange?.({ [this.props.name]: empty })
    })

    onChange = value => {
        this.setState({ value })

        this.props.form.triggerChange(this.props.name)
        this.props.onChange?.({ [this.props.name]: value })
    }

    render() {
        const {
            value,
            picking
        } = this.state

        const {
            className,
            salt,
            label,
            name,
            enabled = true,
            loading = false,
            options = [],
            tallOptions = false
        } = this.props

        let {
            required,
            optional,
            optionRenderer,
            unsettable,
            unsetOnPick
        } = getFieldFromProps(this.props)

        if(!optionRenderer) {
            optionRenderer = ({ label }) => label
        }

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const optionHeight = tallOptions ?
            72 :
            56

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        name={name}
                        type="hidden"
                        value={value || ''}
                        key={`${name}:input:${value}`} />
                    <Options
                        animate={picking ? 'open' : 'closed'}
                        style={{ '--__huma-component-option-height': `${optionHeight}px` }}
                        {...(!enabled ? { className: 'disabled' } : null)}>
                        {options.map((option, index) => {
                            const {
                                value: optionValue,
                                label
                            } = option

                            const isUnsetValue = (!!picking && value === optionValue && unsettable)

                            const pickButtonClassName = cls([
                                'compact',
                                isUnsetValue && 'destructive',
                                !isUnsetValue && 'constructive'
                            ])

                            return (
                                <Option
                                    animate={(!!picking || value === optionValue) ? 'open' : 'closed'}
                                    initial={value === optionValue ? 'open' : 'closed'}
                                    picking={picking}
                                    index={index}
                                    key={`${salt}:${optionValue}`}>
                                    <OptionLabel {...((!enabled || (!picking && value !== optionValue)) ? { inert: 'true' } : null)}>
                                        {optionRenderer({
                                            ...this.props.field,
                                            value: optionValue,
                                            label,
                                            enabled
                                        })}
                                        <PickButton
                                            className={pickButtonClassName}
                                            disabled={!enabled}
                                            onClick={() => {
                                                if(!picking) {
                                                    this.setState({ picking: true })

                                                    if(!unsettable && unsetOnPick) {
                                                        this.unset()
                                                    }
                                                } else {
                                                    this.setState({ picking: false })

                                                    if(value === optionValue && unsettable) {
                                                        this.unset()
                                                    } else {
                                                        this.onChange(optionValue)
                                                    }
                                                }
                                            }}>
                                            <FormattedMessage
                                                id={!picking
                                                    ? 'action_change'
                                                    : (value === optionValue && unsettable)
                                                        ? 'action_reset'
                                                        : 'action_pick'
                                                }
                                                defaultMessage={!picking
                                                    ? 'Change'
                                                    : (value === optionValue && unsettable)
                                                        ? 'Reset'
                                                        : 'Pick'
                                                } />
                                        </PickButton>
                                    </OptionLabel>
                                </Option>
                            )
                        })}
                    </Options>
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {} }) => {
    let {
        value,
        required = false,
        include = 'touched',
        optional = false,
        unsettable = true,
        unsetOnPick = true,
        ...rest
    } = field

    if(!value && value !== 0) {
        value = empty
    }

    return {
        ...rest,
        value,
        required,
        include,
        optional,
        unsettable,
        unsetOnPick
    }
}

export default props => {
    const form = useForm()

    return (
        <EditContractableSelect
            {...props}
            form={form} />
    )
}