import React, { Component, createContext, useContext } from 'react'
import { get, post, patch, remove } from 'api'
import { useAccess } from 'contexts/access'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import PubSub from 'pubsub-js'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'
import { enrichEntry } from 'pages/absence/utilities'
import { getDate } from 'utilities/date-time'

export const AbsenceEntriesContext = createContext()

class AbsenceEntriesProvider extends Component {
    constructor(props) {
        super(props)

        const {
            eternal = true,
            autoFetch = false,
            exhaustiveFetch = false
        } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })
        this.intersectionObserver = intersectionObserver(this.onIntersect)

        this.sortingDefaults = sortingDefaults(props?.sorting)
        this.pagingDefaults = pagingDefaults(props?.paging)

        this.uuid = uuid()

        this.state = {
            entries: props.entries ?? [],
            appliedFilters: {},
            total: 0,
            fixed: !!props.entries,
            filter: props.filter ?? {},
            sorting: this.sortingDefaults(),
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            fetch: this.fetchDebounced,
            exhaust: this.exhaust,
            fetchEntry: this.fetchEntry,
            addEntry: this.add,
            updateEntry: this.update,
            updateEntryReview: this.updateReview,
            updateEntryLocally: this.updateLocally,
            setTotal: this.setTotal,
            endEntry: this.end,
            removeEntry: this.remove,
            exportEntries: this.export,
            setEntriesFilter: this.setFilter,
            prioritizePropsFilter: props.prioritizePropsFilter ?? false,

            error: null,
            resetError: () => this.setError(null),

            hasFetched: false,
            autoFetch,
            exhaustiveFetch,
            fetching: false,
            loading: false
        }

        this.subscription = PubSub.subscribe('absenceEntries.refresh', (_, uuid) => {
            if(this.uuid !== uuid) {
                this.setState({
                    entries: [],
                    sorting: this.sortingDefaults(),
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch,
                    exhaustiveFetch
                }, this.fetchDebounced)
            }
        })
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ paging, entries }, { filter, sorting }) {
        const pagingChanged = !isEqual(paging, this.props?.paging)
        const filterChanged = !isEqual(filter, this.state.filter)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props?.paging)
            state.paging = this.pagingDefaults()
        }

        if(sortingChanged) {
            this.sortingDefaults = sortingDefaults(this.props?.sorting)
            state.sorting = this.sortingDefaults()
        }

        if(entries !== this.props.entries) {
            state.entries = this.props.entries
            state.fixed = !!this.props.entries
        }

        if(filterChanged || sortingChanged) {
            this.setState(size(state) ? state : null, () => {
                this.fetchDebounced(true)
            })
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        PubSub.unsubscribe(this.subscription)
    }

    fetch = async (force = false) => {
        const {
            fixed,
            fetching,
            filter,
            sorting,
            paging,
            eternal,
            autoFetch,
            hasFetched
        } = this.state

        if((fixed || fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? paging.offset + paging.limit : 0,
            limit: paging.limit
        }

        const { ok, response } = await get({
            path: '/absence/entries',
            params: {
                ...getFetchFilter(filter),
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ entries: previousEntries }) => {
                const entries = [
                    ...previousEntries,
                    ...response.items.map(enrichEntry)
                ]

                return {
                    entries,
                    appliedFilters: response.filters,
                    total: response.total,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        hasNextPage: response.items.length && entries.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: this.props.autoFetch || (!!previousEntries.length && hasFetched),
                    fetching: false,
                    loading: false
                }
            }, this.fetchExhaustive)
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    exhaust = () => void this.setState({ exhaustiveFetch: true }, this.fetchExhaustive)

    fetchExhaustive = () => {
        if(this.state.exhaustiveFetch && this.state.paging.hasNextPage) {
            this.fetch()
        }
    }

    fetchEntry = async id => await get({ path: `/absence/entries/${id}` })

    add = async ({ body }) => {
        const { ok, response } = await post({
            path: '/absence/entries',
            body
        })

        if(response) {
            if(ok) {
                this.setState(({ entries }) => ({
                    entries: [
                        ...entries,
                        enrichEntry(response)
                    ]
                }))
            } else {
                this.setError({
                    ...response,
                    entry: enrichEntry(body)
                })
            }
        }

        return { ok, response }
    }

    update = async ({ body, entryId, entry }) => {
        const { ok, response } = await patch({
            path: `/absence/entries/${entryId}`,
            body
        })

        if(response) {
            if(ok) {
                this.setState(({ entries }) => {
                    const index = entries.findIndex(({ id }) => id === entryId)

                    return {
                        entries: [
                            ...entries.slice(0, index),
                            enrichEntry(response),
                            ...entries.slice(index + 1, entries.length)
                        ]
                    }
                })

                PubSub.publish('absenceEntries.refresh', this.uuid)
            } else {
                const absenceAdmin = this.props.check('absence:manage')

                if(!(response.errorCode?.includes('overlapping') && absenceAdmin)) {
                    this.setError({
                        ...response,
                        entry
                    })
                }
            }
        }

        return { ok, response }
    }

    updateReview = async (body, entryId) => {
        const { ok, response } = await post({
            path: `/absence/entries/${entryId}/status`,
            body
        })

        if(response) {
            if(ok) {
                this.setState(({ entries }) => {
                    const index = entries.findIndex(({ id }) => id === entryId)

                    return {
                        entries: [
                            ...entries.slice(0, index),
                            enrichEntry({
                                ...body,
                                ...response
                            }),
                            ...entries.slice(index + 1, entries.length)
                        ]
                    }
                })

                PubSub.publish('absenceEntries.refresh', this.uuid)
            }
        }

        return { ok, response }
    }

    updateLocally = (body, entryId) => void this.setState(({ entries }) => {
        const index = entries.findIndex(({ id }) => id === entryId)

        const entry = {
            ...entries[index],
            ...body
        }

        return {
            entries: [
                ...entries.slice(0, index),
                entry,
                ...entries.slice(index + 1, entries.length)
            ]
        }
    })

    setTotal = total => this.setState({ total })

    end = entry => {
        const { id: entryId } = entry
        const endDate = getDate()

        entry.endDate = endDate

        return this.update({
            body: { endDate },
            entryId,
            entry: enrichEntry(entry)
        })
    }

    remove = async entryId => {
        const { ok } = await remove({
            path: `/absence/entries/${entryId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ entries, paging }) => ({
                entries: entries.filter(entry => entry.id !== entryId),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))

            PubSub.publish('absenceEntries.refresh', this.uuid)
        }

        return { ok }
    }

    export = body => post({
        path: '/absence/export',
        body,
        returnsData: false
    })

    setFilter = (filter = {}, sorting = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.state.prioritizePropsFilter ? filter : null),
            ...(this.props.filter ?? null),
            ...(!this.state.prioritizePropsFilter ? filter : null)
        }

        sorting = {
            ...sortingDefaults(),
            ...sorting
        }

        this.setState(({ filter: previousFilter, sorting: previousSorting }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            const sortingChanged = !isEqual(sorting, previousSorting)

            if(!filterChanged && !sortingChanged) {
                return {
                    prioritizePropsFilter: false
                }
            }

            const state = {
                entries: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false,
                prioritizePropsFilter: false
            }

            if(filterChanged) {
                state.filter = nextFilter
            }

            if(sortingChanged) {
                state.sorting = sorting
            }

            return state
        })
    }

    setError = data => {
        let error = null

        if(data) {
            const {
                entry: currentEntry,
                values,
                errorCode,
                errorMessage
            } = data

            error = {
                errorType: errorCode.replace('field:', '').replaceAll('-', '_'),
                errorMessage,
                currentEntry,
                overlappingEntries: values?.map(value => ({
                    ...value,
                    user: currentEntry.user
                }))
            }
        }

        this.setState({ error })
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal || loading || !autoFetch || !paging?.hasNextPage) {
            return
        }

        this.fetchDebounced()
    }

    render() {
        const { children = null } = this.props

        return (
            <AbsenceEntriesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AbsenceEntriesContext.Provider>
        )
    }
}

const sortingOptions = {
    startDate: 'asc',
    endDate: 'desc'
}

const sortingDefaults = (overrides = {}) => () => ({
    by: Object.keys(sortingOptions)[0],
    direction: Object.values(sortingOptions)[0],
    ...overrides
})

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

const getFetchFilter = filter => {
    filter = { ...filter }

    if(filter?.date) {
        filter.fromDate = filter.date[0],
        filter.toDate = filter.date?.[1] ?? null
    }

    return filter
}

export const useAbsenceEntries = () => useContext(AbsenceEntriesContext)

export default props => {
    const { check } = useAccess()

    return (
        <AbsenceEntriesProvider
            {...props}
            check={check} />
    )
}