import React, { useEffect, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import MeetingEventsProvider, { useMeetingEvents } from 'contexts/meeting-events'
import { useMe } from 'contexts/me'
import { compact } from 'utilities/array'
import AddMeeting from 'pages/meetings/modals/event/add'
import { ButtonArrow } from 'components/button'
import { HeroActions, Tutorial } from './s'
import Filter, { areGroupedFiltersApplied } from 'pages/meetings/components/events-filter'
import Empty from 'components/empty'
import Caption from 'components/typography/caption'
import GroupedEvents from 'pages/meetings/components/grouped-events'

const UncompletedEvents = ({
    profile, isDeactivated,
    hasFetchedCompleted, setHasFetchedUncompleted,
    total, setTotal, totalUncompleted,
    filtersApplied, setFiltersApplied,
    salt
}) => {
    const {
        events,
        filter,
        hasFetched
    } = useMeetingEvents()

    useEffect(() => {
        if (!!hasFetched) {
            setHasFetchedUncompleted(true)
            setTotal(events?.length)
        }
    }, [hasFetched, events?.length])

    useEffect(() => {
        setFiltersApplied(areGroupedFiltersApplied(filter))
        setHasFetchedUncompleted(false)
    }, [filter])

    salt = `${salt}:uncompleted`

    return (
        <>
            <HeroActions className="push-only-child">
                {!profile && (
                    <Filter
                        showerride={!!total}
                        salt={salt} />
                )}
                {!isDeactivated && (
                    <Fragment>
                        <AddMeeting profile={profile} />
                        {(!total && !!hasFetched && !!hasFetchedCompleted && !filtersApplied) && <ButtonArrow />}
                    </Fragment>
                )}
            </HeroActions>
            {(!totalUncompleted && !!hasFetched && !!hasFetchedCompleted) && (
                <>
                    {(!filtersApplied && !total) && <Tutorial which="meetings" />}
                    {filtersApplied && (
                        <Empty which="meetings">
                            <Caption className="compact">
                                <FormattedMessage
                                    id="meetings_empty_filter_message"
                                    defaultMessage="🤷 Sorry, no meetings found." />
                            </Caption>
                        </Empty>
                    )}
                </>
            )}
            <GroupedEvents
                types={compact([
                    'uncompleted',
                    filtersApplied && 'completed'
                ])}
                filtersApplied={filtersApplied}
                salt={salt} />
        </>
    )
}

export default ({ filter = null, ...props }) => {
    const { id } = useParams()
    const { me } = useMe()

    const user = id ? props.profile : me
    const isDeactivated = user?.status?.active === false

    return (
        <MeetingEventsProvider
            filter={{
                attendee: props.profile?.id ?? 'me',
                ...filter,
                status: ['draft', 'open']
            }}
            sortCacheKey="list:meetings:events:uncompleted">
            <UncompletedEvents
                {...props}
                isDeactivated={isDeactivated} />
        </MeetingEventsProvider>
    )
}
