import styled, { keyframes } from 'styled-components'
import { darkMode } from 'utilities/styled'

export const ProgressBar = styled.div`
    position: absolute;

    border-radius: 24px;
    width: ${p => p.$value ?? 0}%;
    height: 100%;

    transform-origin: top left;
    transition: width .25s cubic-bezier(.4, 0, .6, 1) 0s;
`

const buffering = keyframes`
    100% {
        background-position: 17px 0;
    }
`

export const Buffer = styled.div`
    --huma-component-progress-indicator-progress-surface: var(--huma-palette-purple-50);

    position: relative;
    overflow: hidden;

    border-radius: 24px;
    width: 100%;
    height: 8px;

    background-color: rgb(from var(--huma-color-surface-inverted) r g b / .1);

    &.constructive ${ProgressBar} {
        --huma-component-progress-indicator-progress-surface: var(--huma-color-surface-constructive-bold);

        ${darkMode`
            --huma-component-progress-indicator-progress-surface: var(--huma-color-surface-constructive-bold);
        `}

        &:nth-last-child(1) {
            background-color: var(--huma-color-surface-constructive-bold);
        }
    }

    & ${ProgressBar}:nth-last-child(1) {
        background-color: var(--huma-color-surface-brand-subtle);
    }

    & ${ProgressBar}:nth-last-child(2) {
        background-color: var(--huma-component-progress-indicator-progress-surface);
    }

    & ${ProgressBar}:nth-last-child(3) {
        animation: ${buffering} 1s infinite linear;

        background: repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 2px,
            var(--huma-component-progress-indicator-progress-surface) 2px,
            var(--huma-component-progress-indicator-progress-surface) 4px
        );
    }

    ${darkMode`
        --huma-component-progress-indicator-progress-surface: var(--huma-palette-purple-70);
    `}
`