import React, { useRef, useEffect, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import {
    svgStroke,
    darkMode
} from 'utilities/styled'
import { cls } from 'utilities/dom'
import { mergeRefs } from 'react-merge-refs'
import { Check, Lock, Minus as Indeterminate } from 'styled-icons/feather'

export const CheckboxInput = styled.input.attrs(props => ({
    tabIndex: props.tabIndex ?? 0,
    type: 'checkbox'
}))`
    position: absolute;
    opacity: 0;
    pointer-events: none;
`

export const checkboxCss = css`
    @layer checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        position: relative;
        color: transparent;
        border: 2px solid var(--huma-color-border-subtle);
        border-radius: 4px;
        cursor: pointer;
        transition: all .1s ease-in-out;

        &.locked {
            color: var(--huma-color-foreground-default);
            background: none;
            border: none;
            cursor: default;
        }

        &:after {
            content: "";
            display: block;
            opacity: 0;
            transition: all .1s ease-in-out;
        }

        ${CheckboxInput}:not(:disabled, :checked, :indeterminate) + &:not(.locked):hover {
            border-color: var(--huma-color-border-subtle-hover);
        }

        ${CheckboxInput}:not(:disabled):focus + &:not(.locked) {
            &:not(.destructive) {
                box-shadow:
                    0 0 0 1px var(--huma-color-surface-default),
                    0 0 0 3px var(--huma-color-border-constructive-focus);
            }

            &.destructive {
                box-shadow:
                    0 0 0 1px var(--huma-color-surface-default),
                    0 0 0 3px var(--huma-color-border-destructive-focus);
            }
        }

        ${CheckboxInput}:not(:disabled):is(:checked, :indeterminate) + &:not(.locked) {
            &:not(.destructive) {
                border-color: var(--huma-color-surface-constructive-bold);
                background-color: var(--huma-color-surface-constructive-bold);

                color: var(--huma-color-foreground-inverted);

                &:hover {
                    border-color: var(--huma-color-surface-constructive-bold-hover);
                    background-color: var(--huma-color-surface-constructive-bold-hover);
                }
            }

            &.destructive {
                border-color: var(--huma-color-surface-destructive-bold);
                background-color: var(--huma-color-surface-destructive-bold);
                color: var(--huma-color-foreground-inverted);

                &:hover {
                    border-color: var(--huma-color-surface-destructive-bold-hover);
                    background-color: var(--huma-color-surface-destructive-bold-hover);
                }
            }
        }

        ${CheckboxInput}:disabled:is(:checked, :indeterminate) + &:not(.locked) {
            border-color: var(--huma-color-surface-selected-disabled);
            background-color: var(--huma-color-surface-selected-disabled);

            color: var(--huma-color-foreground-on-selected-disabled);
        }

        ${CheckboxInput}:disabled + &:not(.locked) {
            border-color: var(--huma-color-surface-subtle);
            cursor: not-allowed;
        }

        svg {
            ${svgStroke('small')}
        }
    }
`

export const CheckboxLabel = styled.label`
    ${checkboxCss}
`

export const ChecklistboxLabel = styled.label`
    ${checkboxCss}

    border-radius: 100%;
    width: 20px;
    height: 20px;

    ${CheckboxInput}:not(:disabled):not(:checked) + &:hover,
    ${CheckboxInput}:not(:disabled):not(:checked):focus + & {
        border: none !important;

        color: var(--huma-color-foreground-inverted);

        &:not(.destructive) {
            background-color: var(--huma-color-surface-constructive-bold-hover);
            box-shadow: 0 0 0 6px var(--huma-color-surface-constructive-bold-hover);
        }

        &.destructive {
            background-color: var(--huma-color-surface-destructive-bold-hover);
            box-shadow: 0 0 0 6px var(--huma-color-surface-destructive-bold-hover);
        }
    }

    ${CheckboxInput}:not(:disabled):checked + &:hover,
    ${CheckboxInput}:not(:disabled):checked:focus + & {
        --checklistbox-color: var(--huma-color-border-default);

        border-color: var(--checklistbox-color) !important;
        background-color: var(--checklistbox-color) !important;
        box-shadow: 0 0 0 6px var(--checklistbox-color);

        ${darkMode`
            --checklistbox-color: var(--huma-color-surface-minimal);
        `}

        &::after {
            width: 14px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;

            background-color: var(--huma-color-foreground-minimal);

            transform: translate(-50%, -50%);
            opacity: 1;
        }

        svg {
            color: transparent;
        }
    }
`

const Checkbox = forwardRef(({ id, className, indeterminate = false, locked = false, controlProps = {}, ...props }, forwardedRef) => {
    const ref = mergeRefs([
        useRef(),
        forwardedRef
    ])

    useEffect(() => {
        if(ref.current) {
            ref.current.checked = props.checked || indeterminate
            ref.current.indeterminate = indeterminate
        }
    }, [ref.current, indeterminate])

    const {
        className: controlClassName,
        autoFocus,
        ...attributes
    } = controlProps

    className = cls([
        className,
        controlClassName,
        locked && 'locked'
    ])

    return (
        <>
            <CheckboxInput
                {...props}
                id={id}
                autoFocus={autoFocus}
                ref={ref} />
            <CheckboxLabel
                {...attributes}
                {...(className ? { className } : null)}
                htmlFor={id}>
                {indeterminate && <Indeterminate size={16} />}
                {(!indeterminate && locked) && <Lock size={24} />}
                {(!indeterminate && !locked) && <Check size={16} />}
            </CheckboxLabel>
        </>
    )
})

export const Checklistbox = ({ id, className, locked = false, controlProps = {}, ...props }) => {
    const input = useRef()

    const {
        autoFocus,
        ...attributes
    } = controlProps

    className = cls([
        className,
        locked && 'locked'
    ])

    return (
        <>
            <CheckboxInput
                {...props}
                id={id}
                autoFocus={autoFocus}
                ref={input} />
            <ChecklistboxLabel
                {...attributes}
                {...(className ? { className } : null)}
                htmlFor={id}
                onClick={() => requestAnimationFrame(() => input.current.blur())}>
                {locked && <Lock size={24} />}
                {!locked && <Check size={16} />}
            </ChecklistboxLabel>
        </>
    )
}

export default Checkbox