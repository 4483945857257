import React, { Component, Fragment, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { useForm } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Field, Label, Control } from 'components/form/field/s'
import { Columns, FlexChild } from 'components/flex'
import Textarea, { InputSizer } from 'components/form/input/textarea'
import CharacterCount from './character-count'
import ContentAssistant from 'components/content-assistant'

const empty = ''

class EditText extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()

        this.control = createRef()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => {
                if(typeof value === 'string') {
                    value = value.trim()
                }

                return required ?
                    !!value :
                    true
            }
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    unset = () => this.setState({ value: empty })

    onChange = ({ target }) => {
        const { value = empty } = target

        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })
    }

    render() {
        const { value } = this.state

        const {
            className,
            controlProps = {},
            salt,
            label,
            name,
            type = 'text',
            enabled = true,
            loading = false,
            contentAssistant = null,
            forwardedRef,
            ...props
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const {
            required = false,
            softRequired = false,
            optional = false,
            showCharacterCount = false,
            growable = false
        } = this.props.field ?? {}

        const { maxLength = Infinity } = this.props.controlProps ?? {}

        const fieldClassName = cls([
            className,
            touched && 'touched',
            growable && 'growable',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const [TextareaWrapper, textareaWrapperProps] = growable ?
            [InputSizer, { 'data-value': value }] :
            [Fragment, null]

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <Columns>
                        <FlexChild>
                            <TextareaWrapper {...textareaWrapperProps}>
                                <Textarea
                                    {...controlProps}
                                    {...omit(props, 'form', 'field', 'whistle')}
                                    {...{ type, name, value, id: salt }}
                                    ref={mergeRefs([
                                        this.control,
                                        forwardedRef
                                    ])}
                                    onChange={this.onChange}
                                    disabled={!enabled} />
                            </TextareaWrapper>
                            {!!showCharacterCount && (
                                <CharacterCount
                                    value={value}
                                    maxLength={maxLength} />
                            )}
                            {!!contentAssistant && (
                                <ContentAssistant
                                    {...omit(contentAssistant, 'onInsert')}
                                    callback={(value, insertMethod = 'replace') => {
                                        value = insertMethod === 'append' ?
                                            `${this.state.value}\n${value}` :
                                            value

                                        this.onChange({ target: { value } })
                                        this.props.form.triggerChange(name, { touched: true })
                                        this.control.current.focus()
                                    }}
                                    value={value} />
                            )}
                        </FlexChild>
                    </Columns>
                </Control>
            </Field>
        )
    }
}

export default props => {
    const form = useForm()

    return (
        <EditText
            {...props}
            form={form} />
    )
}