import React, { useState, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useRolesByUsers } from 'contexts/roles-by-users'
import { useRolesByUser } from 'contexts/roles-by-user'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import { usePossum } from 'hooks/possum'
import { map } from 'utilities/object'
import { getPeopleProfileUrl } from 'utilities/url'
import { getShortName } from 'utilities/person'
import {
    RolesWrapper,
    containerProps, CardList, CardListRow, CardListCell
} from './s'
import Paragraph from 'components/typography/paragraph'
import Role from './role'
import { Plain } from 'components/button'
import ContextMenu from 'widgets/context-menu'
import { Plus } from 'styled-icons/feather'
import { Scrollable as Modal } from 'modals/generic'
import AssignGrant from 'pages/settings/pages/roles/modals/grants/assign'
import EditHierarchical from 'pages/people/pages/profile/panes/about/affiliation/hierarchical/edit'
import Link from 'components/link'
import Loader from 'components/loader'

const Roles = ({
    user,
    actions = {}, setPreviewingPermissions,
    viewingSubordinates, setViewingSubordinates,
    context = 'settings',
    className, salt
}) => {
    const { updateRolesCount } = useRolesByUsers()

    const {
        roles = [],

        updateUnitsLocally,

        hasFetched = false,
        fetching = false
    } = useRolesByUser()

    const { check } = useAccess()
    const subordinatesEditable = check('users:manage')

    const { isItMyOwnId } = useMe()
    const possesify = usePossum()

    const [assigningGrant, setAssigningGrant] = useState(null)

    const roleGroups = {
        system: {
            domain: 'organization',
            roles: roles.filter(({ role }) => role.domain === 'organization'),
            heading: {
                id: 'roles_organization_heading',
                defaultMessage: 'System roles'
            },
            emptyText: {
                id: 'roles_system_empty',
                defaultMessage: 'No system roles assigned'
            }
        },
        user: {
            domain: 'user',
            roles: roles.filter(({ role }) => role.domain === 'user'),
            heading: {
                id: 'roles_user_heading',
                defaultMessage: 'User roles'
            },
            emptyText: {
                id: 'roles_user_empty',
                defaultMessage: 'No user roles assigned'
            }
        }
    }

    return (
        <RolesWrapper {...(className ? { className } : null)}>
            {!!hasFetched && map(roleGroups, ({ domain, roles: groupRoles, heading, emptyText }) => (
                <Fragment key={`role-group:${domain}`}>
                    {!!groupRoles.length && (
                        <CardList
                            {...containerProps}
                            key={heading.id}>
                            <CardList.Header className="always-visible header">
                                <CardListCell>
                                    <FormattedMessage {...heading} />
                                </CardListCell>
                            </CardList.Header>
                            {groupRoles.map(groupRole => (
                                <CardListRow key={groupRole.role.id}>
                                    <CardListCell $cell="role">
                                        <Role
                                            {...groupRole}
                                            user={user}
                                            setViewingSubordinates={setViewingSubordinates} />
                                    </CardListCell>
                                    <CardListCell $cell="permissions">
                                        <Plain
                                            onClick={() => setPreviewingPermissions(groupRole.role)}
                                            className="constructive small">
                                            <FormattedMessage
                                                id="role_permissions_action_see"
                                                defaultMessage="See permissions" />
                                        </Plain>
                                    </CardListCell>
                                    <CardListCell $cell="actions">
                                        <ContextMenu
                                            context={{
                                                ...groupRole,
                                                user
                                            }}
                                            actions={actions}
                                            salt={salt} />
                                    </CardListCell>
                                </CardListRow>
                            ))}
                        </CardList>
                    )}
                    {(!!hasFetched && !groupRoles.length) && (
                        <Paragraph className="caption compact">
                            <FormattedMessage {...emptyText} />
                        </Paragraph>
                    )}
                    <Plain
                        icon={<Plus size={24} />}
                        className="constructive"
                        onClick={() => setAssigningGrant({
                            user,
                            domain
                        })}>
                        <FormattedMessage
                            id={`role_action_${domain}_add`}
                            defaultMessage={`Assign ${domain} role`} />
                    </Plain>
                </Fragment>
            ))}
            {(!!hasFetched && context === 'settings') && (
                <Link
                    to={getPeopleProfileUrl(user)}
                    className="constructive">
                    <FormattedMessage
                        id={isItMyOwnId(user.id) ?
                            'action_navigate_user_me'
                            : 'action_navigate_user'
                        }
                        defaultMessage={isItMyOwnId(user.id) ?
                            'Go to your profile'
                            : 'Go to {pname} profile'
                        }
                        values={{ pname: possesify(getShortName(user)) }} />
                </Link>
            )}
            {!!fetching && <Loader />}
            <Modal
                show={!!assigningGrant}
                dismiss={() => setAssigningGrant(null)}
                salt={salt}>
                <AssignGrant
                    {...assigningGrant}
                    onDone={({ user }) => {
                        updateRolesCount(user.id, roles.length + 1)
                        setAssigningGrant(null)
                    }}
                    dismiss={() => setAssigningGrant(null)}
                    salt={salt} />
            </Modal>
            <Modal
                show={!!viewingSubordinates}
                dismiss={() => setViewingSubordinates(null)}
                salt={`${salt}:subordinates`}>
                <EditHierarchical
                    what="subordinates"
                    {...(!subordinatesEditable ? { mode: 'view' } : null)}
                    outside={true}
                    id={viewingSubordinates?.userId}
                    dismiss={() => setViewingSubordinates(null)}
                    onChange={subordinates => updateUnitsLocally({
                        roleId: viewingSubordinates.roleId,
                        units: subordinates
                    })}
                    salt={`${salt}:subordinates`} />
            </Modal>
        </RolesWrapper>
    )
}

export default Roles