import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Field, Control as ControlBase } from 'components/form/field/s'
import { Ghost } from 'components/button'

export const Control = styled(ControlBase)`
    display: grid;
    grid-template-columns: 1fr 40px 40px;
    gap: 16px;
    align-items: center;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    padding: 16px;

    ${Field}.tight & {
        padding: 8px;
        padding-inline-start: 16px;
    }

    ${Field}.naked & {
        border: none;
        border-radius: 0;
    }
`

export const Value = styled.div`
    display: flex;
    gap: 4px;

    ${Field}.disabled & {
        color: var(--huma-color-foreground-disabled);
    }
`

const quantityControl = css`
    display: grid;
    place-items: center;

    width: 40px;
    padding: 0;

    svg {
        ${svgStroke()}
    }
`

export const DecreaseButton = styled(Ghost)`
    ${quantityControl}
`

export const IncreaseButton = styled(Ghost)`
    ${quantityControl}
`
