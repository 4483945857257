import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { MenuItemLink } from '../'

const moduleIdentifier = 'accessroles'

const SystemSettingsRoles = props => {
    const {
        initialized,
        check
    } = useAccess()

    const access = initialized && check('roles:manage')

    return (
        <MenuItemLink
            {...props}
            to="settings.roles.base"
            module={moduleIdentifier}
            access={access}
            upgradable={false}
            newlyAdded={true}
            nested>
            <FormattedMessage
                id="settings_pane_roles"
                defaultMessage="Roles" />
        </MenuItemLink>
    )
}

export default SystemSettingsRoles