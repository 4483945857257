import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import MeetingEventsProvider, { useMeetingEvents } from 'contexts/meeting-events'
import DrawablePanel from 'components/drawable-panel'
import GroupedEvents from 'pages/meetings/components/grouped-events'

const CompletedEvents = ({
    hasFetchedUncompleted,
    setHasFetchedCompleted,
    total, setTotal,
    filtersApplied,
    salt
}) => {
    const { formatMessage } = useIntl()

    const {
        events,
        hasFetched,
        fetch
    } = useMeetingEvents()

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if(!!hasFetchedUncompleted && !hasFetched) {
            setOpen(false)
            fetch()
        }

        if(!!hasFetched) {
            setHasFetchedCompleted(true)

            if(!!events?.length && !total) {
                setOpen(true)
            }

            setTotal(events?.length)
        }
    }, [hasFetchedUncompleted, hasFetched, events?.length])

    salt = `${salt}:uncompleted`

    if(!hasFetchedUncompleted || !events?.length || !!filtersApplied) {
        return null
    }

    return (
        <DrawablePanel
            heading={formatMessage({
                id: 'meetings_heading_completed',
                defaultMessage: 'Completed'
            })}
            open={open}
            salt={`${salt}:drawable`}>
            <GroupedEvents
                types={['completed']}
                salt={salt} />
        </DrawablePanel>
    )
}

export default ({ filter = null, ...props }) => (
    <MeetingEventsProvider
        fetchOnMount={false}
        filter={{
            attendee: props.profile?.id ?? 'me',
            ...filter,
            status: ['closed']
        }}
        sorting={{
            by: 'date',
            direction: 'desc'
        }}>
        <CompletedEvents {...props} />
    </MeetingEventsProvider>
)
