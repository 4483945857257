import React, { useState, useEffect, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { getYear } from 'date-fns'
import { useAccess } from 'contexts/access'
import { useMeetingEvents } from 'contexts/meeting-events'
import { useMe } from 'contexts/me'
import { getActionsArray } from 'widgets/context-menu'
import { getMeetingEventUrl, getMeetingRoundUrl } from 'utilities/url'
import { compact } from 'utilities/array'
import {
    SkeletonListRow,
    Heading, Wrapper, LoadingContainer
} from './s'
import { SkeletonCell, SkeletonSymbolAndMeta, SkeletonStatus, SkeletonStrings } from 'components/skeleton'
import { clamp } from 'utilities/math'
import Loader from 'components/loader'
import Event from 'lists/meeting/events/event'
import { Ghost } from 'components/button'
import { ArrowRight, Trash2 as Delete } from 'styled-icons/feather'
import { MeetingRound } from 'components/feather'

const GroupedEvents = ({ types = [], filtersApplied, salt }) => {
    const { formatMessage } = useIntl()

    const { isItMyOwnId } = useMe()

    const {
        events,
        intersecter,
        hasFetched,
        fetch,
        fetching,
        loading,
        paging = {},
        autoFetch,
        removeEvent
    } = useMeetingEvents()

    const {
        check,
        checkFeature
    } = useAccess()

    const manageAccess = check('performance:manage')
    const meetingRoundsAccess = checkFeature('meeting-rounds')

    const [skeletonLength, setSkeletonLength] = useState(3)

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(events.length, 1, 10))
        }
    }, [hasFetched, events?.length])

    const actions = compact([
        ({ id, host }) => {
            if(!isItMyOwnId(host.id)) {
                return null
            }

            return {
                salt: `${salt}:navigate:event`,
                icon: <ArrowRight size={24} />,
                label: formatMessage({
                    id: 'meeting_action_navigate',
                    defaultMessage: 'Go to meeting'
                }),
                effect: 'neutral',
                element: 'link',
                link: {
                    to: getMeetingEventUrl({ id })
                }
            }
        },
        ({ round }) => {
            if(!round || !manageAccess || !meetingRoundsAccess) {
                return null
            }

            return {
                salt: `${salt}:navigate:round`,
                icon: <MeetingRound size={24} />,
                label: formatMessage({
                    id: 'meeting_round_action_navigate',
                    defaultMessage: 'Open meeting round'
                }),
                effect: 'neutral',
                element: 'link',
                link: {
                    to: getMeetingRoundUrl({ id: round?.id })
                }
            }
        },
        ({ id, host }) => {
            if(!isItMyOwnId(host.id)) {
                return null
            }

            return {
                salt: `${salt}:delete`,
                icon: <Delete size={24} />,
                label: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                }),
                effect: 'destructive',
                onClick: () => removeEvent(id),
                prompt: {
                    question: formatMessage({
                        id: 'meeting_event_delete_prompt_question',
                        defaultMessage: 'Confirm removing this meeting'
                    }),
                    message: formatMessage({
                        id: 'meeting_event_delete_prompt_message',
                        defaultMessage: 'The meeting and all its notes will be deleted. This can not be undone.'
                    }),
                    confirmText: formatMessage({
                        id: 'action_delete',
                        defaultMessage: 'Delete'
                    })
                }
            }
        }
    ])

    const hasActions = !!getActionsArray(actions).length

    const eventsWithoutDate = types.includes('uncompleted') ? getEventsWithoutDate(events) : []
    const eventsWithDate = types.includes('uncompleted') ? getEventsWithDate(events) : []
    const eventsGrouped = types.includes('completed') ? getEventsGrouped(events, !!filtersApplied) : []

    return (
        <>
            {(!hasFetched && fetching) && (
                <>
                    <Heading>
                        {types.includes('uncompleted') && (
                            <FormattedMessage
                                id="noun_meetings"
                                defaultMessage="Meetings" />
                        )}
                    </Heading>
                    {[...Array(skeletonLength).keys()].map(index => (
                        <SkeletonListRow
                            columns={3}
                            hasActions={!!hasActions}
                            key={`${salt}:skeleton:${index}`}>
                            <SkeletonCell>
                                <SkeletonSymbolAndMeta
                                    size={40}
                                    showSecondLine={true} />
                            </SkeletonCell>
                            <SkeletonCell>
                                <SkeletonStatus />
                            </SkeletonCell>
                            <SkeletonCell>
                                <SkeletonStrings
                                    size={16}
                                    length={index % 2 === 0 ? 12 : 16} />
                            </SkeletonCell>
                        </SkeletonListRow>
                    ))}
                </>
            )}
            {hasFetched && (
                <>
                    {!!eventsWithoutDate?.length && (
                        <>
                            <Heading>
                                <FormattedMessage
                                    id="meetings_heading_no_date"
                                    defaultMessage="No meeting date" />
                            </Heading>
                            <Wrapper>
                                {eventsWithoutDate.map((event, index) => {
                                    const last = index + 1 === eventsWithoutDate.length && !eventsWithDate?.length

                                    return (
                                        <Event
                                            event={event}
                                            actions={actions}
                                            {...(last ? { ref: intersecter } : null)}
                                            key={`${salt}:meeting-event:${event.id}`} />
                                    )
                                })}
                            </Wrapper>
                        </>
                    )}
                    {!!eventsWithDate?.length && (
                        <>
                            <Heading>
                                <FormattedMessage
                                    id="meetings_heading_with_date"
                                    defaultMessage="Planned / Uncompleted" />
                            </Heading>
                            <Wrapper>
                                {eventsWithDate.map((event, index) => {
                                    const last = index + 1 === eventsWithDate.length

                                    return (
                                        <Event
                                            event={event}
                                            actions={actions}
                                            {...(last ? { ref: intersecter } : null)}
                                            key={`${salt}:meeting-event:${event.id}`} />
                                    )
                                })}
                            </Wrapper>
                        </>
                    )}
                    {eventsGrouped?.map((group, groupIndex) => (
                        <Fragment key={`${salt}:group:${group.heading ?? 'no-date'}`}>
                            <Heading>
                                {(group.heading ?? formatMessage({
                                    id: 'meetings_heading_no_date',
                                    defaultMessage: 'No meeting date'
                                }))}
                            </Heading>
                            <Wrapper>
                                {group.events.map((event, index) => {
                                    const last = groupIndex + 1 === eventsGrouped.length && index + 1 === group.events.length

                                    return (
                                        <Event
                                            event={event}
                                            actions={actions}
                                            {...(last ? { ref: intersecter } : null)}
                                            key={`${salt}:meeting-event:${event.id}`} />
                                    )
                                })}
                            </Wrapper>
                        </Fragment>
                    ))}
                </>
            )}
            {!!paging.hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </>
    )
}

export default GroupedEvents

const getEventsWithoutDate = events => events.filter(({ date }) => !date && status !== 'closed')

const getEventsWithDate = events => events.filter(({ date, status }) => !!date && status !== 'closed')

const getEventsGrouped = (events, filtersApplied) => events
    .filter(({ status }) => (!filtersApplied || (!!filtersApplied && status === 'closed')))
    // .sort(({ date: one }, { date: two }) => two.getTime() - one.getTime())
    .reduce((accumulator, event) => {
        const heading = event?.date ? getYear(event?.date) : null
        const existingGroup = accumulator.find(group => heading === group.heading)

        if(existingGroup) {
            existingGroup.events.push(event)
            return accumulator
        }

        return [
            ...accumulator,
            {
                heading,
                events: [event]
            }
        ]
    }, [])
    .sort(({ heading: one }, { heading: two }) => two - one)