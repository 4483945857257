import React, { useState, forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import LinesEllipsis from 'react-lines-ellipsis'
import { cls } from 'utilities/dom'
import { Wrapper, AnimatedParagraph, Paragraph } from './s'
import { Plain } from 'components/button'
import { v4 } from 'uuid'

const Clamp = ({ children, lines = 2, ellipsis = '…', buttonProps, paragraphComponent: ParagraphComponent = Paragraph, paragraphClassName, ...props }) => {
    const [uuid] = useState(v4())
    const [clamped, setClamped] = useState(true)
    const [shouldAnimate, setShouldAnimate] = useState(true)

    const newLineToLineBreak = string => {
        return string.split('\n').map((item, index) => {
            return (index === 0) ? item : [<br key={`clamper:${uuid}:break:${index}`} />, item]
        })
    }

    const handleReflow = state => {
        const { clamped: isClamped } = state

        setClamped(isClamped)
        setShouldAnimate(isClamped)
    }

    if(shouldAnimate) {
        return (
            <Wrapper
                {...props}
                className={cls([props.className, paragraphClassName?.includes('compact') && 'spaced'])}>
                {clamped && (
                    <LinesEllipsis
                        text={children}
                        maxLine={lines}
                        ellipsis={ellipsis}
                        basedOn="words"
                        component={forwardRef((props, forwardedRef) => (
                            <ParagraphComponent
                                {...props}
                                {...(paragraphClassName ? { className: paragraphClassName } : null)}
                                ref={forwardedRef} />
                        ))}
                        onReflow={handleReflow} />
                )}
                {!clamped && (
                    <AnimatedParagraph
                        animate={!clamped ? 'expanded' : 'contracted'}
                        {...(paragraphClassName ? { className: paragraphClassName } : null)}>
                        {newLineToLineBreak(children)}
                    </AnimatedParagraph>
                )}
                <Plain
                    {...buttonProps}
                    className="constructive compact"
                    onClick={() => setClamped(!clamped)}>
                    <FormattedMessage
                        id={clamped ? 'action_show_more' : 'action_show_less'}
                        defaultMessage={clamped ? 'Show more' : 'Show less'} />
                </Plain>
            </Wrapper>
        )
    }

    return (
        <Wrapper {...props}>
            <Paragraph className={cls(['compact', paragraphClassName])}>
                {newLineToLineBreak(children)}
            </Paragraph>
        </Wrapper>
    )
}

export default Clamp