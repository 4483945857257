/* eslint-disable no-prototype-builtins */

export const map = (object = {}, mapper) => {
    const keys = Object.keys(object)
    return keys.map(key => mapper(object[key], key, keys.indexOf(key)))
}

export const reduce = (object = {}, reducer, accumulator = {}) => {
    const keys = Object.keys(object)
    keys.forEach(key => accumulator = reducer(accumulator, object[key], key, keys.indexOf(key)))
    return accumulator
}

export const has = (object = {}, key) => object?.hasOwnProperty(key)

export const pick = (object = {}, ...keys) => keys
    .reduce((accumulator, key) => {
        if(has(object, key)) {
            accumulator[key] = object[key]
        }

        return accumulator
    }, {})

export const omit = (object = {}, ...keys) => Object.keys(object)
    .reduce((accumulator, key) => {
        if(keys.includes(key)) {
            return accumulator
        }

        return {
            ...accumulator,
            [key]: object[key]
        }
    }, {})

export const filter = (object = {}, test) => reduce(object, (accumulator, value, key) => {
    if(!test(value, key)) {
        return accumulator
    }

    return {
        ...accumulator,
        [key]: value
    }
}, {})

export const withoutEmptyArrays = (object = {}) => filter(object, value => !(Array.isArray(value) && !value.length))

export const reject = (object = {}, test) => reduce(object, (accumulator, value, key) => {
    if(test(value, key)) {
        return accumulator
    }

    return {
        ...accumulator,
        [key]: value
    }
}, {})

export const compact = (object = {}) => filter(object, value => (!!value || value === 0))

export const each = (object = {}, looper) => {
    let keys = Object.keys(object)
    keys.forEach(key => looper(object[key], key))
}

export const size = (object = {}) => Object.keys(object).length

export const keysOrdered = (object = {}) =>
    Object.getOwnPropertyNames(object)

export const keysNested = (object = {}) => {
    const paths = (object, head = '') => Object.entries(object)
        .reduce((accumulator, [key, value]) => {
            const path = head ?
                `${head}.${key}` :
                key

            return (!!object && typeof value === 'object' && !Array.isArray(value)) ?
                accumulator.concat(paths(value, path)) :
                accumulator.concat(path)
        }, [])

    return paths(object)
}

export const valuesOrdered = (object = {}) => {
    const keysInOrder = keysOrdered(object)
    return keysInOrder.map(key => object[key])
}

export const valuesNested = (object = {}) => {
    const paths = object => Object.entries(object)
        .reduce((accumulator, [, value]) => {
            return (!!object && typeof value === 'object' && !Array.isArray(value)) ?
                accumulator.concat(paths(value)) :
                accumulator.concat(value)
        }, [])

    return paths(object)
}

export const keysOfDifferingValues = (one = {}, two = {}) =>
    reduce(one, (accumulator, value, key) => [
        ...accumulator,
        (two[key] !== value) && key
    ], []).filter(Boolean)

export const entriesOrdered = (object = {}) => {
    const keysInOrder = keysOrdered(object)
    const valuesInOrder = valuesOrdered(object)

    return map(object, () => [
        keysInOrder.shift(),
        valuesInOrder.shift()
    ])
}

export const slice = (object = {}, ...args) => {
    const keys = keysOrdered(object).slice(...args)
    const values = valuesOrdered(object).slice(...args)

    return keys.reduce((accumulator, key, index) => ({
        ...accumulator,
        [key]: values[index]
    }), {})
}

export const get = (object, path) => path
    .split('.')
    .reduce((level, key) => level?.[key] ?? null, object ?? {})

export const set = (object = {}, path, value) => {
    if(Object(object) !== object) {
        return object
    }

    if(!Array.isArray(path)) {
        path = path.toString().match(/[^.[\]]+/g) || []
    }

    path
        .slice(0, -1)
        .reduce((accumulator, value, index) => {
            return (Object(accumulator[value]) === accumulator[value]) ?
                accumulator[value] :
                accumulator[value] = (Math.abs(path[index + 1]) >> 0 === +path[index + 1]) ?
                    [] :
                    {}
        }, object)[path[path.length - 1]] = value

    return object
}

export const isObject = object => object !== null && typeof object === 'object' && !Array.isArray(object)

export const prune = (object = {}) => filter(object, value => {
    if(Array.isArray(value) && (!value.length || !value.some(Boolean))) {
        return false
    }

    if(isObject(value) && !size(value)) {
        return false
    }

    if([true, false].includes(value)) {
        return true
    }

    return !!value || value === 0
})