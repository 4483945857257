import React, { Fragment } from 'react'
import { cls } from 'utilities/dom'
import { Options, Option, OptionLabel, Number, NumberLabel, OptionInput } from './s'
import Tooltip, { useSingleton } from 'components/tooltip'
import { empty } from './'
import {
    Star, StarFill,
    Heart, HeartFill,
    Dice1, Dice1Fill,
    Dice2, Dice2Fill,
    Dice3, Dice3Fill,
    Dice4, Dice4Fill,
    Dice5, Dice5Fill,
    Dice6, Dice6Fill
} from 'styled-icons/bootstrap'
import {
    EmojiUnhappy, EmojiUnhappyFilled,
    EmojiSad, EmojiSadFilled,
    EmojiSmile, EmojiSmileFilled,
    EmojiHappy, EmojiHappyFilled
} from 'components/feather'

const RatingOptions = ({ name, value, min = 1, max = 5, variant, enabled = true, tooltips = [], labels = {}, onChange, optionsClassName, salt }) => {
    const [source, target] = useSingleton()

    if(value !== empty) {
        value = parseInt(value, 10)
    }

    if(variant === 'emoji' && max > 4) {
        max = 4
    }

    if(variant === 'die' && max > 6) {
        max = 6
    }

    const total = max - min + 1

    return (
        <Options {...(optionsClassName ? { className: optionsClassName } : null)}>
            <Tooltip
                singleton={source}
                delay={[0, 250]} />
            {Array.from({ length: total }).map((_, index) => {
                const id = `${salt}:option:${index}`
                const isChecked = value === index + min

                const optionClassName = cls([
                    isChecked && 'checked',
                    (['star', 'heart'].includes(variant) && (index < value) && !isChecked) && 'filled'
                ])

                const [LabelWrapper, labelWrapperProps] = !!tooltips?.length ?
                    [Tooltip, {
                        singleton: target,
                        content: tooltips[index]
                    }] :
                    [Fragment, null]

                return (
                    <Option
                        {...(optionClassName ? { className: optionClassName } : null)}
                        key={id}>
                        <LabelWrapper {...labelWrapperProps}>
                            <OptionLabel htmlFor={id}>
                                {optionRenderer({ variant, min, total, index, isChecked, isCheckedIndex: value })}
                                {labels?.[index + min] && (
                                    <NumberLabel>
                                        {labels[index + min]}
                                    </NumberLabel>
                                )}
                            </OptionLabel>
                        </LabelWrapper>
                        <OptionInput
                            id={id}
                            name={name}
                            type="radio"
                            defaultValue={index + min}
                            checked={isChecked}
                            disabled={!enabled}
                            onChange={onChange}
                            onClick={event => {
                                // Only trigger onClick for non-keyboard events
                                if(event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
                                    onChange?.(event)
                                }
                            }} />
                    </Option>
                )
            })}
        </Options>
    )
}

const optionRenderer = ({ variant, min, total, index, isCheckedIndex, isChecked }) => {
    let size = 24

    if(total <= 4) {
        size = 56
    } else if(4 <= total && total <= 6) {
        size = 40
    }

    const props = {
        className: 'icon',
        size
    }

    if(variant === 'star') {
        return (isChecked || index < isCheckedIndex) ? <StarFill {...props} /> : <Star {...props} />
    }

    if(variant === 'heart') {
        return (isChecked || index < isCheckedIndex) ? <HeartFill {...props} /> : <Heart {...props} />
    }

    if(variant === 'emoji') {
        if(index > 3) {
            return null
        }

        const Emoji = [EmojiUnhappy, EmojiSad, EmojiSmile, EmojiHappy][index]
        const EmojiFill = [EmojiUnhappyFilled, EmojiSadFilled, EmojiSmileFilled, EmojiHappyFilled][index]

        if(isChecked) {
            props.className = cls([
                props.className,
                'emoji',
                emojiClassNames[index + 1]
            ])
        }

        return isChecked ? <EmojiFill {...props} /> : <Emoji {...props} />
    }

    if(variant === 'die') {
        if(index > 5) {
            return null
        }

        const Die = [Dice1, Dice2, Dice3, Dice4, Dice5, Dice6][index]
        const DieFill = [Dice1Fill, Dice2Fill, Dice3Fill, Dice4Fill, Dice5Fill, Dice6Fill][index]

        return isChecked ? <DieFill {...props} /> : <Die {...props} />
    }

    return <Number>{index + min}</Number>
}

const emojiClassNames = {
    1: 'unhappy',
    2: 'sad',
    3: 'smile',
    4: 'happy'
}

export default RatingOptions
