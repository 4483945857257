import React, { Component, createContext, useContext } from 'react'
import { intersectionObserver } from 'utilities/dom'
import { get, post, patch, remove, getUrl, outpost, outpatch } from 'api'
import { local } from 'utilities/storage'
import debounce from 'lodash.debounce'
import { throttle } from 'utilities/function'
import isEqual from 'react-fast-compare'
import { pick, omit, reduce, size, prune } from 'utilities/object'
import { clamp } from 'utilities/math'
import { pruneBy } from 'utilities/array'

const commentsSortCacheKey = 'survey:comments:sort'

const SurveyContext = createContext()

export default class SurveyProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchCommentsController = new AbortController()
        this.fetchCommentsDebounced = debounce(this.fetchComments, 100, { maxWait: 500, leading: false, trailing: true })
        this.commentsIntersectionObserver = intersectionObserver(this.onCommentIntersect)

        this.setRunIdDebounced = debounce(this.setRunId, 1000, { maxWait: 5000, leading: false, trailing: true })

        this.setSurveyChanged = throttle(this.setSurveyChanged, 250)

        const respondRun = props.respondRun ? {
            ...props.respondRun,
            questions: instateQuestions(props.respondRun.questions)
        } : null

        this.state = {
            // Survey / configuration
            survey: null,
            remoteSurvey: null,

            surveyChanged: false,
            surveySaving: false,
            surveyUpdatedAt: 'mint',
            surveyDeleted: false,

            addSurveyQuestion: this.addSurveyQuestion,
            updateSurveyQuestion: this.updateSurveyQuestion,
            updateSurveyQuestions: this.updateSurveyQuestions,
            removeSurveyQuestion: this.removeSurveyQuestion,

            setSurveyChanged: this.setSurveyChanged,
            getSurveyChanges: this.getSurveyChanges,
            resetSurveyChanges: this.resetSurveyChanges,
            saveSurvey: this.saveSurvey,

            updateSurveyDetails: this.updateSurveyDetails,

            // Runs
            runs: {},
            runSummaries: [],
            hasMoreRunSummaries: true, // We’re doing an initial fetch
            runId: props?.runId ?? null,
            explicitRunId: false,
            currentRun: null,
            previousRun: null,
            respondRun,
            totalRuns: 0,

            setRunId: this.setRunIdDebounced,
            setRunIdImmediate: this.setRunId,
            fetchCurrentRun: this.fetchCurrentRun,
            fetchPreviousRun: this.fetchPreviousRun,
            getResponseRun: this.getResponseRun,

            // Run survey
            configureSurvey: this.configure,
            pauseSurvey: this.pause,
            resumeSurvey: this.resume,
            removeSurvey: this.remove,

            // Inquisitor
            fetchComments: this.fetchComments,
            toggleCommentSorting: this.toggleCommentSorting,

            // Respondent
            respond: this.respond,
            updateResponse: this.updateResponse,

            // Responses
            responses: [],
            fetchResponses: this.fetchResponses,

            hasFetched: false
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    componentDidUpdate({ id, runId, explicitRunId }) {
        const idChanged = id !== this.props.id
        const runIdChanged = runId !== this.props.runId && !explicitRunId

        const needsReplacing = idChanged
        if(needsReplacing) {
            this.replace()
        } else {
            const state = {}

            if(runIdChanged) {
                state.runId = runId ?? null
            }

            this.setState(size(state) ? state : null, () => {
                if(runIdChanged) {
                    this.fetchCurrentRun()
                }
            })
        }
    }

    componentWillUnmount() {
        this.fetchCommentsController.abort()
        this.commentsIntersectionObserver.destroy()
    }

    fetch = async () => {
        let state = { hasFetched: true }

        if(this.props.id) {
            const [
                { ok: surveyOk, response: survey },
                { response: runSummaries }
            ] = await Promise.all([
                get({ path: `/surveys/${this.props.id}` }),
                this.fetchRunSummaries()
            ])

            if(surveyOk) {
                state.survey = survey
                state.remoteSurvey = survey
            }

            state.runId = this.state.runId ?? runSummaries?.items?.[0]?.id

            this.setState(state, () => {
                this.fetchCurrentRun()
                this.fetchCommentsDebounced()
            })
        }
    }

    fetchRunSummaries = async (options = {}) => {
        const { lastCreated = false } = options

        const { ok, response } = await get({
            path: `/surveys/${this.props.id}/runs/results`,
            params: {
                offset: lastCreated ?
                    0 :
                    this.state.runSummaries.length,
                limit: lastCreated ?
                    1 :
                    25
            }
        })

        if(ok) {
            this.setState(({ runSummaries: previousRunSummaries }) => {
                const runSummaries = pruneBy([
                    ...(lastCreated ? response.items : []),
                    ...previousRunSummaries,
                    ...(!lastCreated ? response.items : [])
                ])

                return {
                    runSummaries,
                    hasMoreRunSummaries: !!response.items.length && runSummaries.length < response.total,
                    ...((runSummaries.length === 1) ? { runId: runSummaries[0].id } : null),
                    totalRuns: response.total
                }
            }, () => {
                if(this.state.runSummaries.length === 1) {
                    this.fetchCurrentRun()
                }
            })
        }

        return { ok, response }
    }

    getCachedRun = (runId = this.state.runId) => {
        const cachedRun = this.state.runs[runId]

        return ((cachedRun?.expiresAt ?? 0) > Date.now()) ?
            cachedRun :
            null
    }

    setRunId = runId => void this.setState({
        runId,
        explicitRunId: true
    }, this.fetchCurrentRun)

    fetchCurrentRun = async (force = false) => {
        if(!this.state.runId) {
            return
        }

        if(!force) {
            const cachedRun = this.getCachedRun()
            if(cachedRun) {
                return this.setState({ currentRun: cachedRun }, () => {
                    if(!cachedRun.comments.hasFetched) {
                        this.fetchCommentsDebounced()
                    }
                })
            }
        }

        const { ok, response } = await get({ path: `/surveys/runs/${this.state.runId}/results` })

        if(ok) {
            const currentRun = {
                ...response,
                comments: {
                    ...getRunCommentsState(),
                    intersecter: this.commentsIntersectionObserver.ref
                },
                expiresAt: Date.now() + surveyRunTtl
            }

            this.setState(({ runs }) => ({
                runs: {
                    ...runs,
                    [currentRun.id]: currentRun
                },
                currentRun
            }), () => {
                this.fetchPreviousRun()
                this.fetchCommentsDebounced()

                const rundex = this.state.runSummaries.findIndex(({ id }) => id === currentRun.id)
                if(this.state.runSummaries.length - rundex < 4) {
                    this.fetchRunSummaries()
                }
            })
        }
    }

    fetchPreviousRun = async (force = false) => {
        if(!this.state.runSummaries?.length || !this.state.currentRun) {
            return
        }

        const currentRunIndex = this.state.runSummaries.findIndex(({ id }) => id === this.state.currentRun.id)
        const previousRunSummary = this.state.runSummaries[currentRunIndex + 1]

        if(!previousRunSummary) {
            return
        }

        if(!force) {
            const cachedRun = this.getCachedRun(previousRunSummary.id)
            if(cachedRun) {
                return this.setState({ previousRun: cachedRun })
            }
        }

        const { ok, response } = await get({ path: `/surveys/runs/${previousRunSummary.id}/results` })

        if(ok) {
            const previousRun = {
                ...response,
                comments: {
                    ...getRunCommentsState(),
                    intersecter: this.commentsIntersectionObserver.ref
                },
                expiresAt: Date.now() + surveyRunTtl
            }

            this.setState(({ runs }) => ({
                runs: {
                    ...runs,
                    [previousRun.id]: previousRun
                },
                previousRun
            }))
        }
    }

    // Questions should not be updated through this method
    configure = async body => {
        const { ok, response: survey } = await patch({
            path: `/surveys/${this.props.id}`,
            body: {
                ...body,
                type: this.state.survey.type
            }
        })

        if(ok) {
            // The active status indicates that a new run was created.
            // Fetch it, but don’t automatically switch the runId cursor to it.
            if(survey.status === 'active') {
                this.fetchRunSummaries({ lastCreated: true })
            }

            this.setState(({ survey: previousSurvey }) => {
                const updatedSurvey = {
                    ...survey,
                    questions: previousSurvey.questions
                }

                return {
                    survey: updatedSurvey,
                    remoteSurvey: survey
                }
            })
        }

        return { ok, response: survey }
    }

    pause = async () => {
        const { ok, response: survey } = await post({ path: `/surveys/${this.props.id}/stop` })

        if(ok) {
            this.setState(({ survey: previousSurvey }) => ({
                survey: {
                    ...survey,
                    questions: previousSurvey.questions
                },
                remoteSurvey: survey
            }))
        }

        return { ok }
    }

    resume = async () => {
        const { ok, response: survey } = await post({ path: `/surveys/${this.props.id}/start` })

        if(ok) {
            this.setState(({ survey: previousSurvey }) => ({
                survey: {
                    ...survey,
                    questions: previousSurvey.questions
                },
                remoteSurvey: survey
            }))
        }

        return { ok }
    }

    remove = async params => {
        const { ok } = await remove({
            path: `/surveys/${this.props.id}`,
            params,
            returnsData: false
        })

        ok && this.setState({ surveyDeleted: true })

        return { ok }
    }

    // Local question mutation methods
    addSurveyQuestion = question => void this.setState(({ survey }) => ({
        survey: {
            ...survey,
            questions: [
                ...survey.questions,
                question
            ]
        }
    }), this.setSurveyChanged)

    updateSurveyQuestion = (update, errors) => void this.setState(({ survey }) => {
        if((!update?.id && !~update?.index) || !update?.meta?.type) {
            return null
        }

        const index = !!update.id ?
            survey.questions.findIndex(({ id }) => update.id === id) :
            update.index

        if(!~index || index >= survey.questions.length) {
            return null
        }

        const current = survey.questions[index]

        // If the question is new and doesn’t have an id, but the question found by index has, it’s a mismatch
        if(!update?.id && !!current?.id) {
            return null
        }

        const next = {
            ...mutateQuestion({ current, update }),
            ...(size(errors) ? { errors } : null)
        }

        if(isEqual(current, next)) {
            return null
        }

        return {
            survey: {
                ...survey,
                questions: survey.questions.with(index, next)
            }
        }
    }, this.setSurveyChanged)

    updateSurveyQuestions = questions => void this.setState(({ survey }) => {
        const update = [...questions].map(update => mutateQuestion({ update }))
        if(isEqual(update, survey.questions)) {
            return null
        }

        return {
            survey: {
                ...survey,
                questions: update
            }
        }
    }, this.setSurveyChanged)

    removeSurveyQuestion = id => void this.setState(({ survey }) => ({
        survey: {
            ...survey,
            questions: survey.questions.filter(question => question.id !== id)
        }
    }), this.setSurveyChanged)

    updateSurveyDetails = async body => {
        const { ok, response } = await patch({
            path: `/surveys/${this.props.id}`,
            body: {
                ...body,
                type: this.state.survey.type
            }
        })

        if(ok) {
            this.setState(({ survey }) => ({
                survey: {
                    ...response,
                    questions: survey.questions
                },
                remoteSurvey: response
            }))
        }

        return { ok, response }
    }

    saveSurvey = async () => {
        this.setState({ surveySaving: true })

        const { body } = this.getSurveyChanges()

        body.questions = body.questions.map(({ id, ...current }) => {
            if(!id) {
                return current
            }

            const previous = this.state.remoteSurvey.questions.find(({ id: quid }) => quid === id)
            if(!previous) {
                return current
            }

            const typeChanged = previous.type !== current.type
            if(!typeChanged) {
                current.id = id
            }

            return current
        })

        const { ok, response: survey } = await patch({
            path: `/surveys/${this.props.id}`,
            body: {
                ...body,
                type: this.state.survey.type
            }
        })

        let state = { surveySaving: false }

        if(ok) {
            state = {
                ...state,
                survey,
                remoteSurvey: survey,

                surveyChanged: false,
                surveyUpdatedAt: Date.now()
            }
        }

        this.setState(state)
        return { ok }
    }

    fetchComments = async () => {
        if(!this.state.currentRun) {
            return
        }

        const {
            fetching,
            sorting,
            paging,
            eternal,
            autoFetch,
            hasFetched
        } = this.state.currentRun.comments

        if(this.state.survey.type !== 'enps' || fetching || (hasFetched && !eternal)) {
            return
        }

        if(fetching) {
            this.fetchCommentsController.abort()
            this.fetchCommentsController = new AbortController()
        }

        this.setState(({ runs, currentRun }) => {
            const updatedCurrentRun = {
                ...currentRun,
                comments: {
                    ...currentRun.comments,
                    fetching: true,
                    ...(autoFetch ? { loading: true } : null)
                }
            }

            return {
                runs: {
                    ...runs,
                    [currentRun.id]: updatedCurrentRun
                },
                currentRun: updatedCurrentRun
            }
        })

        const nextPaging = {
            offset: hasFetched ? paging.offset + paging.limit : 0,
            limit: paging.limit
        }

        const { ok, response } = await get({
            path: `/surveys/runs/${this.state.currentRun.id}/responses`,
            params: {
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchCommentsController.signal
        })

        if(ok && response) {
            this.setState(({ runs, currentRun }) => {
                const previousComments = currentRun.comments?.items ?? []
                const comments = [
                    ...previousComments,
                    ...response.items
                ]

                const updatedCurrentRun = {
                    ...currentRun,
                    comments: {
                        ...currentRun.comments,
                        items: comments,
                        paging: {
                            ...paging,
                            ...nextPaging,
                            hasNextPage: response.items.length && comments.length < response.total,
                            total: response.total
                        },
                        hasFetched: true,
                        autoFetch: !!previousComments.length && hasFetched,
                        fetching: false,
                        loading: false
                    }
                }

                return {
                    runs: {
                        ...runs,
                        [currentRun.id]: updatedCurrentRun
                    },
                    currentRun: updatedCurrentRun
                }
            })
        } else {
            this.setState(({ runs, currentRun }) => {
                const updatedCurrentRun = {
                    ...currentRun,
                    comments: {
                        ...currentRun.comments,
                        paging: {
                            ...currentRun.comments.paging,
                            total: 0
                        },
                        hasFetched: true,
                        autoFetch: false,
                        fetching: false,
                        loading: false
                    }
                }

                return {
                    runs: {
                        ...runs,
                        [currentRun.id]: updatedCurrentRun
                    },
                    currentRun: updatedCurrentRun
                }
            })
        }
    }

    getResponseRun = () => {
        const source = this.state.respondRun ?? this.state.survey
        if(!source) {
            return null
        }

        return {
            ...source,
            questions: source.questions.filter(question => !!question?.meta?.type),
            ...(!this.state.respondRun ? { preview: true } : null)
        }
    }

    // `type` comes from the body, not the survey that might or might not be in state
    respond = (body, ...args) => {
        if(body.type === 'custom') {
            const [runId] = args

            body.answers = body.answers.filter(({ type, value }) => {
                if(type === 'text') {
                    return typeof value === 'string' && !!value.trim()
                }

                if(type === 'multiple_select') {
                    return Array.isArray(value) && !!value.length
                }

                if(type === 'scale') {
                    return Number.isInteger(value) && value >= 0 && value <= 10
                }

                if(type === 'boolean') {
                    return typeof value === 'boolean'
                }

                return value !== null
            })

            return post({
                path: `/surveys/runs/${runId}/responses`,
                body
            })
        }

        const [jwt] = args

        return outpost(getUrl('/surveys/runs/responses'), {
            body,
            headers: { 'huma-limited-auth': jwt }
        })
    }

    updateResponse = async (body, responseId, jwt) => await outpatch(
        getUrl(`/surveys/runs/responses/${responseId}`),
        {
            body: {
                ...body,
                type: this.state.survey.type
            },
            headers: { 'huma-limited-auth': jwt },
            returnsData: false
        }
    )

    toggleCommentSorting = () => void this.setState(({ runs, currentRun }) => {
        const toggled = {
            by: 'score',
            direction: currentRun.comments.sorting.direction === 'asc' ?
                'desc' :
                'asc'
        }

        local.set(commentsSortCacheKey, toggled)

        const updatedCurrentRun = {
            ...currentRun,
            comments: {
                ...getRunCommentsState(),
                intersecter: this.commentsIntersectionObserver.ref,
                sorting: toggled
            }
        }

        return {
            runs: {
                ...runs,
                [currentRun.id]: updatedCurrentRun
            },
            currentRun: updatedCurrentRun
        }
    }, this.fetchCommentsDebounced)

    onCommentIntersect = () => {
        const {
            eternal,
            fetching,
            paging,
            autoFetch
        } = this.state.currentRun.comments

        if(!eternal || fetching || !autoFetch || !paging?.hasNextPage) {
            return
        }

        this.fetchCommentsDebounced()
    }

    getSurveyChanges = () => {
        const {
            survey,
            remoteSurvey
        } = this.state

        const body = pick(survey, 'questions')
        const remoteBody = pick(remoteSurvey, 'questions')

        const changedBody = reduce(body, (accumulator, value, key) => {
            // const different = ['title', 'description'].includes(key) ?
            //     areDifferentAndNotBothFalsy(value, remoteBody[key]) :
            //     !isEqual(value, remoteBody[key])

            const different = !isEqual(value, remoteBody[key])

            if(!different) {
                return accumulator
            }

            return {
                ...accumulator,
                [key]: value
            }
        })

        const changed = !!size(changedBody)

        return {
            body: changed ? changedBody : null,
            changed
        }
    }

    setSurveyChanged = () => void this.setState({
        surveyChanged: this.getSurveyChanges().changed
    })

    resetSurveyChanges = () => void this.setState(({ remoteSurvey }) => ({
        survey: remoteSurvey,
        surveyChanged: false,
        surveyUpdatedAt: Date.now()
    }))

    replace = () => void this.setState({
        survey: null,
        remoteSurvey: null,
        surveyChanged: false,
        surveySaving: false,
        surveyUpdatedAt: 'mint',
        surveyDeleted: false,

        runs: {},
        runSummaries: [],
        runId: null,
        currentRun: null,
        previousRun: null,
        respondRun: null,
        totalRuns: 0
    }, this.fetch)

    render() {
        const { children = null } = this.props

        return (
            <SurveyContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </SurveyContext.Provider>
        )
    }
}

const surveyRunTtl = 30 * 60 * 1000

const getCommentPagingDefaults = () => ({
    offset: 0,
    limit: 25,
    hasNextPage: false,
    total: 0
})

const getCommentSorting = () => {
    const cachedSorting = local.get(commentsSortCacheKey)

    return cachedSorting ?? {
        by: 'score',
        direction: 'asc'
    }
}

const getRunCommentsState = () => ({
    items: [],
    paging: getCommentPagingDefaults(),
    sorting: getCommentSorting(),
    eternal: true,
    hasFetched: false,
    autoFetch: false,
    fetching: false
})

// const areDifferentAndNotBothFalsy = (one, two) => one !== two && !(!one && !two)

const instateQuestions = questions => questions.map(question => mutateQuestion({
    // Mimic the structure of a question form output
    update: {
        ...question,
        ...omit(question.meta, 'type'),
        meta: pick(question.meta, 'type')
    }
}))

const mutateQuestion = ({ current = {}, update = {} }) => {
    const commonBaseProperties = ['id', 'index', 'title', 'description', 'helperText', 'required']

    let next = {
        ...pick(current, ...commonBaseProperties),
        ...pick(update, ...commonBaseProperties),
        meta: pick(update.meta, 'type')
    }

    if(next.id) {
        delete next.index
    }

    let specificBaseProperties = []
    let specificMetaProperties = []

    const adapters = [
        next => {
            if(typeof next?.min === 'string') {
                next.min = parseInt(next.min, 10)
            }

            if(typeof next?.max === 'string') {
                next.max = parseInt(next.max, 10)
            }

            return next
        }
    ]

    if(['text', 'textarea'].includes(update.meta.type)) {
        next = {
            type: 'text',
            multiline: update.meta.type === 'textarea',
            ...next
        }

        specificBaseProperties = ['placeholder']
    }

    if(update.meta.type === 'radiobuttons') {
        next = {
            type: 'single_select',
            ...next,
            options: null
        }

        specificBaseProperties = ['options']
    }

    if(update.meta.type === 'checkboxes') {
        next = {
            type: 'multiple_select',
            ...next,
            options: null,
            min: 0,
            max: null
        }

        specificBaseProperties = ['options', 'min', 'max']

        adapters.push(
            next => {
                if(!!next.required && !next.min) {
                    next.min = 1
                }

                return next
            },
            next => {
                // If the question is new or its type is unchanged, don’t override max
                if(!current?.meta?.type || current.meta.type === 'checkboxes') {
                    return next
                }

                return {
                    ...next,
                    max: null
                }
            }
        )
    }

    if(update.meta.type === 'rating') {
        next = {
            type: 'scale',
            ...next,
            min: 1,
            max: 5,
            meta: {
                ...next.meta,
                variant: 'star'
            }
        }

        specificBaseProperties = ['max']
        specificMetaProperties = ['variant']

        adapters.push(
            next => ({
                ...next,
                min: 1
            }),
            next => {
                if('max' in next) {
                    const max = {
                        emoji: 4,
                        die: 6
                    }[next.meta.variant] ?? 10

                    return {
                        ...next,
                        max: clamp(next.max, 1, max)
                    }
                }

                return {
                    ...next,
                    max: 5
                }
            }
        )
    }

    if(update.meta.type === 'scale') {
        next = {
            type: 'scale',
            ...next,
            min: 1,
            max: 5,
            step: null
        }

        specificBaseProperties = ['min', 'max']

        adapters.push(
            next => {
                if('min' in next) {
                    return {
                        ...next,
                        min: clamp(next.min, 0, 1)
                    }
                }

                return {
                    ...next,
                    min: 1
                }
            },
            next => {
                if('max' in next) {
                    return {
                        ...next,
                        max: clamp(next.max, 1, 10)
                    }
                }

                return {
                    ...next,
                    max: 5
                }
            }
        )
    }

    if(update.meta.type === 'boolean') {
        next = {
            type: 'boolean',
            ...next
        }
    }

    next = {
        ...next,
        ...pick(current, ...specificBaseProperties),
        ...pick(update, ...specificBaseProperties),
        meta: {
            ...next.meta,
            ...pick(current, ...specificMetaProperties),
            ...pick(update, ...specificMetaProperties)
        }
    }

    next = adapters.reduce((accumulator, adapter) => ({
        ...accumulator,
        ...adapter(accumulator)
    }), next)

    return prune(next)
}

export const useSurvey = () => useContext(SurveyContext)