import React from 'react'
import { FormattedMessage } from 'react-intl'
import { sendAppSignal } from 'hooks/app-signal'
import { categoriesInOrder } from 'utilities/categories'
import { Wrapper, Heading, GuideList } from './s'
import KnowledgeBase from './knowledge-base'
import Value from './items/value'
import FeatureRoadmap from './items/feature-roadmap'
import Announcements from './items/announcements'
import AccessRoles from './items/access-roles'
import ContentAssistant from './items/content-assistant'
import People from './items/people'
import Teams from './items/teams'
import Locations from './items/locations'
import SalaryRevision from './items/salary-revision'
import News from './items/news'
import Handbook from './items/handbook'
import Absence from './items/absence'
import Onboarding from './items/onboarding'
import Offboarding from './items/offboarding'
import Meetings from './items/meetings'
import Documents from './items/documents'
import Competence from './items/competence'
import CompetenceGap from './items/competence-gap'
import Equipment from './items/equipment'
import Tasks from './items/tasks'
import Deviation from './items/deviation'
import Insights from './items/insights'
import Surveys from './items/surveys'
import EmployeeSatisfaction from './items/employee-satisfaction'
import Whistleblowing from './items/whistleblowing'

const items = {
    people: People,
    teams: Teams,
    locations: Locations,
    'salary-revision': SalaryRevision,
    handbook: Handbook,
    absence: Absence,
    meetings: Meetings,
    news: News,
    onboarding: Onboarding,
    offboarding: Offboarding,
    documents: Documents,
    competence: Competence,
    competenceGap: CompetenceGap,
    equipment: Equipment,
    tasks: Tasks,
    deviation: Deviation,
    insights: Insights,
    surveys: Surveys,
    employeeSatisfaction: EmployeeSatisfaction,
    whistleblowing: Whistleblowing
}

const sort = items => Object.entries(items).sort(([one], [two]) => {
    const oneIndex = categoriesInOrder.indexOf(one)
    const twoIndex = categoriesInOrder.indexOf(two)

    if(!!~oneIndex && !~twoIndex) {
        return -1
    } else if(!~oneIndex && !!~twoIndex) {
        return 1
    } else if(!~oneIndex && !~twoIndex) {
        return 0
    }

    return oneIndex - twoIndex
})

const QuickGuides = ({ onClick }) => (
    <Wrapper>
        <Heading className="caption compact small">
            <FormattedMessage
                id="noun_resources"
                defaultMessage="Resources" />
        </Heading>
        <GuideList onClickCapture={onClick}>
            <KnowledgeBase />
            <Value />
            <FeatureRoadmap />
            <Announcements />
        </GuideList>
        <Heading className="compact">
            <FormattedMessage
                id="tutorial_heading"
                defaultMessage="Quick guides" />
        </Heading>
        <GuideList onClickCapture={onClick}>
            <AccessRoles />
            <ContentAssistant />
            {sort(items).map(([key, Guide]) => (
                <Guide key={`quick-guide:${key}`} />
            ))}
        </GuideList>
    </Wrapper>
)

export const showAnnouncements = () => global.setTimeout(() => sendAppSignal('announcements.show'), 500)
export const showFeatureRoadmap = () => global.setTimeout(() => sendAppSignal('feature-roadmap.show'), 500)
export const showValue = (options = {}) => () => global.setTimeout(() => sendAppSignal('value.show', options), 500)
export const showQuickGuide = which => () => global.setTimeout(() => sendAppSignal('quick-guide.show', which), 500)

export default QuickGuides