import { useLocation } from 'react-router-dom'
import { useEnvironment } from 'contexts/environment'
import { uuid4Pattern } from 'utilities/regex'
import { get } from 'utilities/object'
import paths from 'app/paths'

export const useGetDomainWithoutOrganization = () => {
    const { environment } = useEnvironment()
    const { hostname } = global.location

    return (prefix = '') => {
        const domain = (hostname === 'localhost' || hostname.endsWith('.herokuapp.com')) ?
            `dev.${environment.tld}` :
            hostname.split('.').slice(1).join('.')

        return `${prefix}${domain}`
    }
}

export const useGetCompanyDomain = () => {
    const domainWithoutOrganization = useGetDomainWithoutOrganization()('.')

    return key => `${key}${domainWithoutOrganization}`
}

export const useGetCompanyUrl = () => {
    const getCompanyDomain = useGetCompanyDomain()
    const { protocol } = global.location

    return (key, path = paths.root, forceSecure = false) =>
        `${forceSecure ? 'https:' : protocol}//${getCompanyDomain(key)}${path}`
}

export const anonymizePath = (path = '') => path
    .split('/')
    .map(fragment => {
        return uuid4Pattern.test(fragment) ?
            'uuid' :
            fragment
    })
    .join('/')

export const isExternal = (url = '') => ['http://', 'https://', '//']
    .some(out => url.startsWith(out))

const getUrlFromPathAndParams = ({ path, params }) => path
    .split('/')
    .map(fragment => {
        const key = fragment
            .replace(':', '')
            .replace('?', '')

        if(fragment.startsWith(':') && params?.[key]) {
            return params[key]
        }

        if(fragment.endsWith('?')) {
            return null
        }

        return fragment
    })
    .filter((fragment, index) => index === 0 || !!fragment)
    .join('/')

export const getUrlFromPath = (path = '') => (params = {}) => {
    if(path?.startsWith('path:')) {
        path = get(paths, path.replace('path:', ''))
    }

    return getUrlFromPathAndParams({ path, params })
}

export const getPeopleProfileUrl = getUrlFromPath(paths.people.profile.base)
export const getPeopleProfileMeUrl = () => getUrlFromPath(paths.people.profile.base)({ id: 'me' })
export const getPeopleProfileSalaryUrl = getUrlFromPath(paths.people.profile.salary)
export const getPeopleProfileAbsenceUrl = getUrlFromPath(paths.people.profile.absence)
export const getPeopleProfileMeetingsUrl = getUrlFromPath(paths.people.profile.meetings)
export const getPeopleProfileDocumentsUrl = getUrlFromPath(paths.people.profile.documents)
export const getPeopleProfileCompetenceUrl = getUrlFromPath(paths.people.profile.competence)
export const getPeopleProfileEquipmentUrl = getUrlFromPath(paths.people.profile.equipment)
export const getPeopleProfileTasksUrl = getUrlFromPath(paths.people.profile.tasks)

export const getPeopleTeamUrl = getUrlFromPath(paths.people.team.base)
export const getPeopleTeamNewsUrl = getUrlFromPath(paths.people.team.news)
export const getPeopleTeamAbsenceUrl = getUrlFromPath(paths.people.team.absence)
export const getPeopleTeamCompetenceUrl = getUrlFromPath(paths.people.team.competence)
export const getPeopleTeamEquipmentUrl = getUrlFromPath(paths.people.team.equipment)
export const getPeopleTeamTasksUrl = getUrlFromPath(paths.people.team.tasks)

export const getPeopleLocationUrl = getUrlFromPath(paths.people.location.base)
export const getPeopleLocationNewsUrl = getUrlFromPath(paths.people.location.news)
export const getPeopleLocationAbsenceUrl = getUrlFromPath(paths.people.location.absence)
export const getPeopleLocationCompetenceUrl = getUrlFromPath(paths.people.location.competence)
export const getPeopleLocationEquipmentUrl = getUrlFromPath(paths.people.location.equipment)
export const getPeopleLocationTasksUrl = getUrlFromPath(paths.people.location.tasks)

export const getGroupUrl = ({ type, pane = 'base', ...group }) => ({
    team: {
        base: getPeopleTeamUrl,
        news: getPeopleTeamNewsUrl,
        absence: getPeopleTeamAbsenceUrl,
        competence: getPeopleTeamCompetenceUrl,
        equipment: getPeopleTeamEquipmentUrl,
        tasks: getPeopleTeamTasksUrl
    },
    location: {
        base: getPeopleLocationUrl,
        news: getPeopleLocationNewsUrl,
        absence: getPeopleLocationAbsenceUrl,
        competence: getPeopleLocationCompetenceUrl,
        equipment: getPeopleLocationEquipmentUrl,
        tasks: getPeopleLocationTasksUrl
    }
})[type][pane](group)

export const getSalaryRevisionUrl = getUrlFromPath(paths.salary.revision.base)
export const getSalaryRevisionSetupUrl = getUrlFromPath(paths.salary.revision.setup)
export const getSalaryRevisionGroupUrl = getUrlFromPath(paths.salary.revision.group)

export const getHandbookCategoryUrl = getUrlFromPath(paths.handbook.category)

export const getAbsenceTypeUrl = getUrlFromPath(paths.absence.type)
export const getAbsencePolicyUrl = getUrlFromPath(paths.absence.policy.base)
export const getAbsencePolicyNewUrl = getUrlFromPath(paths.absence.policy.new)
export const getAbsencePolicyUsersUrl = getUrlFromPath(paths.absence.policy.users)

export const getMeetingEventUrl = getUrlFromPath(paths.meetings.meeting)
export const getMeetingRoundUrl = getUrlFromPath(paths.meetings.rounds.round)
export const getMeetingTemplateUrl = getUrlFromPath(paths.meetings.templates.template)

export const getNewsArticleUrl = (options = {}) => {
    const {
        status = 'published',
        mode = 'view',
        ...params
    } = options

    const path = {
        published: {
            view: paths.news.article,
            edit: `${paths.news.article}/edit`
        },
        draft: {
            view: paths.news.drafts.draft,
            edit: `${paths.news.drafts.draft}/edit`
        }
    }[status][mode]

    return getUrlFromPathAndParams({ path, params })
}

export const getOnboardingNewUrl = getUrlFromPath(paths.onboarding.new)
export const getOnboardingOnboardingUrl = getUrlFromPath(paths.onboarding.onboarding)
export const getOnboardingTemplateUrl = getUrlFromPath(paths.onboarding.template)

export const getOffboardingNewUrl = getUrlFromPath(paths.offboarding.new)
export const getOffboardingOffboardingUrl = getUrlFromPath(paths.offboarding.offboarding)
export const getOffboardingTemplateUrl = getUrlFromPath(paths.offboarding.template)

export const getProcessesNewUrl = getUrlFromPath(paths.processes.new)
export const getProcessesProcessUrl = getUrlFromPath(paths.processes.process)
export const getProcessesTemplateUrl = getUrlFromPath(paths.processes.template)

export const getSmartDocumentUrl = getUrlFromPath(paths.documents.document)
export const getSmartTemplateUrl = getUrlFromPath(paths.documents.template)

export const getCompetenceTypeUrl = getUrlFromPath(paths.competence.type)

export const getEquipmentTypeUrl = getUrlFromPath(paths.equipment.type)

export const getSurveyUrl = getUrlFromPath(paths.surveys.survey.base)
export const getSurveyRunUrl = getUrlFromPath(paths.surveys.survey.run)

export const getJobTitleUrl = getUrlFromPath(paths.settings.jobTitle.base)
export const getJobTitleNewUrl = getUrlFromPath(paths.settings.jobTitle.new)

export const getDeviationCaseUrl = getUrlFromPath(paths.deviation.case.base)
export const getDeviationCaseNewUrl = getUrlFromPath(paths.deviation.case.new)

export const getWhistleblowingCaseUrl = getUrlFromPath(paths.whistleblowing.case)

export const getRoleUrl = (options = {}) => {
    const {
        domain,
        pane = 'base',
        ...params
    } = options

    const path = {
        user: {
            base: paths.settings.roles.user.role.base,
            grants: paths.settings.roles.user.role.grants,
        },
        organization: {
            base: paths.settings.roles.system.role.base,
            grants: paths.settings.roles.system.role.grants
        }
    }[domain][pane]

    return getUrlFromPathAndParams({ path, params })
}

export const getIntegrationUrl = getUrlFromPath(paths.integrations.integration.base)

export const getBackUrl = ({ from, fallback = paths.root }) => {
    if(!from?.type) {
        return fallback
    }

    const {
        type,
        id
    } = from

    const getUrl = {
        meetingRound: () => getMeetingRoundUrl({ id })
    }[type] || (() => fallback)

    return getUrl()
}

export const getGroupBackUrl = ({ level, fallback = paths.root }) => {
    const { state } = useLocation()
    const { fromGroup = null } = state ?? {}

    if(!fromGroup || !level) {
        return fallback
    }

    const typeToGroupUrlMap = {
        teams: getUrlFromPath(paths.people.team[level])(fromGroup.group),
        locations: getUrlFromPath(paths.people.location[level])(fromGroup.group)
    }

    const getGroupUrl = typeToGroupUrlMap[fromGroup.type] || fallback

    return getGroupUrl
}