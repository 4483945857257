import React from 'react'
import { Symbol, Heading } from './s'
import { CloseButton } from 'components/button'
import { fallbackSymbol } from 'pages/meetings'

const PreviewTemplateHero = ({ name, symbol, dismiss }) => (
    <>
        <CloseButton onClick={dismiss} />
        <Symbol symbol={symbol ?? fallbackSymbol} />
        <Heading>{name}</Heading>
    </>
)

export default PreviewTemplateHero
