import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { getHandbookCategoryUrl } from 'utilities/url'
import { compact } from 'utilities/array'
import paths from 'app/paths'
import {
    Menu, MenuColumns, MenuColumn, CloseButton, Explanation, Content
} from '../s'
import {
    Symbol,
    EditorContent,
    Label,
    MetaWrapper, Meta, Emoji, MetaLink, Access
} from './s'
import { H1 as Title } from 'components/typography/heading'
import Status from 'pages/handbook/components/status'
import Tooltip from 'components/tooltip'
import TiptapOutput from 'components/tiptap/output'
import { Plain } from 'components/button'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'
import UsefulLinks from 'components/useful-links'
import { Tags } from 'components/tags'
import Divider from 'components/divider'
import RelativeTime from 'components/time-relative'
import { X, EyeOff as Unpublish, Clock } from 'styled-icons/feather'
import { blankSymbol } from 'pages/handbook'

const ViewTopic = ({ category = null, topic = {}, actions = {}, loading, edit, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const {
        handbook,
        filter
    } = useHandbook()

    const {
        check,
        checkFeature
    } = useAccess()

    const manageAccess = check('handbook:manage')
    const groupsAvailable = checkFeature('handbook-groups')

    const {
        symbol = blankSymbol,
        title = '',
        content = [],
        links = [],
        tags = [],
        shares,
        updatedDate
    } = topic ?? {}

    const dateFormat = formatMessage({
        id: 'date_fns_format_full_friendly',
        defaultMessage: `PPP 'at' p`
    })

    const boundActions = getBoundActions(actions, topic)

    const status = (!groupsAvailable && shares.some(({ type }) => ['team', 'location'].includes(type))) ?
        'inaccessible' :
        (topic?.status ?? 'draft')

    return (
        <>
            <Menu>
                <CloseButton onClick={dismiss}>
                    <X size={24} />
                </CloseButton>
                <MenuColumns>
                    {(manageAccess && !filter?.viewAs) && (
                        <>
                            <MenuColumn>
                                <Status
                                    status={compact([handbook?.status, status]).join('_')}
                                    tooltip={status === 'inaccessible'} />
                                {(handbook.status === 'draft' && status === 'published') && (
                                    <Tooltip
                                        content={formatMessage({
                                            id: 'handbooks_topic_tooltip_handbook_draft',
                                            defaultMessage: 'This topic will become available once the handbook is published.'
                                        })}
                                        placement="bottom">
                                        <Explanation>
                                            <Unpublish size={14} />
                                        </Explanation>
                                    </Tooltip>
                                )}
                            </MenuColumn>
                            <MenuColumn className="push">
                                <Plain
                                    className="constructive"
                                    onClick={edit}>
                                    <FormattedMessage
                                        id="action_edit"
                                        defaultMessage="Edit" />
                                </Plain>
                            </MenuColumn>
                        </>
                    )}
                    {!!boundActions?.length && (
                        <MenuColumn {...((!manageAccess || filter?.viewAs) ? { className: 'push' } : null)}>
                            <ContextMenu
                                prebound actions={boundActions}
                                triggerProps={{ disabled: loading }}
                                salt={salt} />
                        </MenuColumn>
                    )}
                </MenuColumns>
            </Menu>
            <Content>
                <Symbol
                    symbol={symbol}
                    size={56} />
                <Title>{title}</Title>
                <TiptapOutput
                    content={content}
                    element={EditorContent}
                    dependencies={[updatedDate]} />
                <UsefulLinks links={links} />
                {!!tags?.length && (
                    <>
                        <Label>
                            <FormattedMessage
                                id="noun_tags"
                                defaultMessage="Tags" />
                        </Label>
                        <Tags
                            tags={tags.map(tag => ({
                                id: tag,
                                name: tag
                            }))}
                            link={tag => ({
                                pathname: paths.handbook.search,
                                search: `?q=${encodeURIComponent(tag)}`,
                                state: { origin: 'tag' }
                            })} />
                    </>
                )}
                <Divider $size={24} />
                <MetaWrapper>
                    {!!category && (
                        <MetaLink
                            to={getHandbookCategoryUrl(category)}
                            className="neutral">
                            <Emoji
                                emoji={category.symbol?.emoji ?? blankSymbol.emoji}
                                size={16} />
                            <span>{category.title}</span>
                        </MetaLink>
                    )}
                    {updatedDate && (
                        <Meta className="compact">
                            <Clock size={16} />
                            <RelativeTime
                                date={updatedDate}
                                format={dateFormat}
                                short={false} />
                        </Meta>
                    )}
                    <Access
                        units={shares}
                        publicWhenEmpty={true}
                        inline={true}
                        max={1} />
                </MetaWrapper>
            </Content>
        </>
    )
}

export default ViewTopic
