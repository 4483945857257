import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { useForm } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { isInRange } from 'utilities/math'
import { Field, Label } from 'components/form/field/s'
import { Control, Value, DecreaseButton, IncreaseButton } from './s'
import { Minus, Plus } from 'styled-icons/feather'

const empty = 0

class EditQuantity extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()

        this.inputRef = createRef()
        this.combinedInputRef = mergeRefs([
            this.inputRef,
            props.forwardedRef
        ])
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ forwardedRef, name, field, whistle }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(forwardedRefChanged) {
            this.combinedInputRef = mergeRefs([
                this.inputRef,
                this.props.forwardedRef
            ])
        }

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required,
            include,
            min,
            max,
            step
        } = getFieldFromProps(this.props)

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => {
                if(!value && value !== 0) {
                    return !required
                }

                // Support for built-in browser input type validation
                const browserValidationInvalid = this.inputRef?.current?.validity?.valid === false

                if(this.inputRef?.current?.inputMode === 'decimal' && browserValidationInvalid) {
                    return false
                }

                const number = Number(value)

                if((min || max === 0) && max) {
                    return isInRange({ min, max, value: number })
                }

                if(step) {
                    return value % Number(step) === 0
                }

                if(browserValidationInvalid) {
                    return false
                }

                if(required) {
                    return !!value || value === 0
                }

                return true
            }
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: Number(props.field?.value || empty)
    })

    onChange = ({ target }) => {
        const value = Number(target.value || empty)

        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })
    }

    increase = () => {
        const {
            step = 1,
            max,
            onChange
        } = this.props

        const newValue = this.state.value + Number(step)

        if(max && newValue > max) {
            return
        }

        this.setState({ value: newValue }, () => {
            onChange?.({ [this.props.name]: newValue })
            this.props.form.triggerChange(this.props.name)
        })
    }

    decrease = () => {
        const {
            step = 1,
            min,
            onChange
        } = this.props

        const newValue = this.state.value - Number(step)

        if((min || min === 0) && newValue < min) {
            return
        }

        this.setState({ value: newValue }, () => {
            onChange?.({ [this.props.name]: newValue })
            this.props.form.triggerChange(this.props.name)
        })
    }

    unset = () => void this.setState({ value: empty }, () => {
        this.props.onChange?.({ [this.props.name]: empty })
        this.props.onUnset?.()

        this.props.form.triggerChange(this.props.name)
    })

    render() {
        const { value } = this.state

        const {
            className,
            controlProps = {},
            salt,
            label,
            name,
            enabled = true
        } = this.props

        const touched = this.props.form.touched.includes(name)

        const {
            suffix,
            required,
            softRequired,
            optional,
            min,
            max
        } = getFieldFromProps(this.props)

        const fieldClassName = cls([
            className,
            !enabled && 'disabled',
            touched && 'touched',
            suffix && 'suffixed'
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        type="hidden"
                        name={name}
                        value={value} />
                    <Value>
                        <span>{value}</span>
                        {!!suffix && <span>{suffix}</span>}
                    </Value>
                    <DecreaseButton
                        className="constructive"
                        onClick={this.decrease}
                        disabled={!enabled || ((min || min === 0) && value <= min)}
                        {...controlProps}>
                        <Minus size={24} />
                    </DecreaseButton>
                    <IncreaseButton
                        className="constructive"
                        onClick={this.increase}
                        disabled={!enabled || (max && value >= max)}
                        {...controlProps}>
                        <Plus size={24} />
                    </IncreaseButton>
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {}, controlProps = {} }) => {
    let {
        value,
        required = false,
        softRequired = false,
        include = 'touched',
        ...rest
    } = field

    let {
        min = -Infinity,
        max = Infinity,
        step = 1
    } = controlProps

    if(!value && value !== 0) {
        value = empty
    }

    return {
        ...rest,
        value,
        required,
        softRequired,
        include,
        min,
        max,
        step
    }
}

export default props => {
    const form = useForm()

    return (
        <EditQuantity
            {...props}
            form={form} />
    )
}