import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useAccess } from 'contexts/access'
import { useSubordinates } from 'contexts/subordinates'
import { clamp } from 'utilities/math'
import { getPeopleProfileUrl } from 'utilities/url'
import {
    PersonList,
    SkeletonListRow, NoSubordinates
} from './s'
import PeoplePicker from 'modals/people-picker'
import { Plain } from 'components/button'
import { SkeletonCell, SkeletonAvatarAndFullName } from 'components/skeleton'
import { Plus, ArrowRight } from 'styled-icons/feather'

const SubordinatesContent = ({ mode, onChange, salt }) => {
    const { formatMessage } = useIntl()

    const { person } = usePerson()
    const { check } = useAccess()

    const {
        subordinates: initialSubordinates,
        addSubordinates,
        removeSubordinate,
        hasFetched,
        fetching
    } = useSubordinates()

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    const [subordinates, setSubordinates] = useState(initialSubordinates)
    const [addingPeople, setAddingPeople] = useState(false)
    const [saving, setSaving] = useState(false)

    const [skeletonLength, setSkeletonLength] = useState(3)

    const manageAccess = check('users:manage')

    const add = async addedSubordinates => {
        setSaving(true)

        const { ok } = await addSubordinates(addedSubordinates.map(({ id }) => id))

        setSaving(false)

        if(ok) {
            const newSubordinates = [
                ...addedSubordinates,
                ...subordinates
            ]

            setSubordinates(newSubordinates)

            !!onboarder && updateOnboardingStatus({ assignSupervisor: 'completed' })

            onChange?.(newSubordinates)
        }
    }

    const remove = async subordinateId => {
        setSaving(true)

        const { ok } = await removeSubordinate(subordinateId)

        setSaving(false)

        if(ok) {
            const newSubordinates = subordinates.filter(({ id }) => id !== subordinateId)

            setSubordinates(newSubordinates)

            onChange?.(newSubordinates)
        }
    }

    useEffect(() => {
        if(mode === 'add') {
            setAddingPeople(true)
        }
    }, [mode])

    useEffect(() => {
        setSubordinates(initialSubordinates)
    }, [initialSubordinates])

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(subordinates.length, 1, 10))
        }
    }, [subordinates?.length])

    return (
        <>
            {(!!manageAccess && mode !== 'view') && (
                <>
                    <Plain
                        autoFocus={true}
                        disabled={saving}
                        onClick={() => setAddingPeople(true)}
                        className="constructive"
                        icon={<Plus size={24} />}>
                        <FormattedMessage
                            id="people_action_add"
                            defaultMessage="Add people" />
                    </Plain>
                    <PeoplePicker
                        show={addingPeople}
                        salt={salt}
                        dismiss={() => setAddingPeople(false)}
                        picked={subordinates}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: () => setAddingPeople(false)
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'action_add',
                                defaultMessage: 'Add'
                            }),
                            effect: 'constructive',
                            onClick: () => add(picked)
                        })}
                        params={{
                            notRecursivelySupervisorOf: person?.id
                        }} />
                </>
            )}
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow key={`list:subordinates:skeleton:${index}`} columns={1} hasActions={true}>
                    <SkeletonCell>
                        <SkeletonAvatarAndFullName size={40} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {(!!subordinates?.length && hasFetched) && (
                <PersonList
                    salt={salt}
                    people={subordinates}
                    actions={{
                        adminNavigate: ({ id }) => {
                            if(!manageAccess) {
                                return null
                            }

                            return {
                                salt: `${salt}:view`,
                                label: formatMessage({
                                    id: 'profile_action_navigate',
                                    defaultMessage: 'Go to profile'
                                }),
                                effect: 'neutral',
                                disabled: saving,
                                element: 'link',
                                link: {
                                    to: getPeopleProfileUrl({ id })
                                }
                            }
                        },
                        remove: ({ id }) => {
                            if(!manageAccess) {
                                return null
                            }

                            return {
                                salt: `${salt}:delete`,
                                label: formatMessage({
                                    id: 'action_remove',
                                    defaultMessage: 'Remove'
                                }),
                                effect: 'destructive',
                                disabled: saving,
                                onClick: () => remove(id)
                            }
                        },
                        navigate: ({ id }) => {
                            if(manageAccess) {
                                return null
                            }

                            return {
                                icon: <ArrowRight size={24} />,
                                disabled: saving,
                                element: 'link',
                                link: {
                                    to: getPeopleProfileUrl({ id })
                                }
                            }
                        }
                    }} />
            )}
            {(!subordinates?.length && hasFetched) && (
                <NoSubordinates className="compact">
                    <FormattedMessage
                        id="subordinates_list_empty"
                        defaultMessage="No subordinates added." />
                </NoSubordinates>
            )}
        </>
    )
}

export default SubordinatesContent