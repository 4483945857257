import { useI18n } from 'contexts/i18n'
import {
    format as dateFnsFormat, parseISO,
    eachDayOfInterval, eachWeekendOfInterval,
    isWithinInterval, isAfter, isBefore,
    startOfDay, endOfDay
} from 'date-fns'
import { toDate, formatInTimeZone } from 'date-fns-tz'

export const safeFormat = (date, format = 'PPP', options) => {
    if(!date) {
        return null
    }

    date = isofy(date)

    return dateFnsFormat(date, format, options)
}

export const safeTransform = (date, transform, ...args) => {
    if(!date) {
        return null
    }

    if(typeof date === 'string') {
        date = parseISO(date)
    }

    return transform(date, ...args)
}

export const countDays = (start, end) => {
    if(!start || !end) {
        return null
    }

    if(typeof start === 'string') {
        start = parseISO(start)
    }

    if(typeof end === 'string') {
        end = parseISO(end)
    }

    const daysCount = eachDayOfInterval({
        start,
        end
    }).length

    const weekendDaysCount = eachWeekendOfInterval({
        start,
        end
    }).length

    return daysCount - weekendDaysCount
}

export const getDate = (date = new Date(), options = {}) => {
    date = !!options?.zone ?
        toDate(date, { timeZone: options.zone }) :
        isofy(date)

    const year = date.getFullYear()
    const month = `${date.getMonth() + 1}`.padStart(2, '0')
    const day = `${date.getDate()}`.padStart(2, '0')

    return `${year}-${month}-${day}`
}

// Units: Which time units to include in the result.
// Precision: Whether to show the actual time or just zeroes.
export const getTime = (date = new Date(), options = {}) => {
    const {
        precision = 'minutes',
        units = 'milliseconds'
    } = options

    let time = `${date.getHours()}`.padStart(2, '0')
    if(units === 'hours') {
        return time
    }

    const additionalTimeUnits = ['minutes', 'seconds', 'milliseconds']
    time = `${time}:${(additionalTimeUnits.includes(precision) ?
        `${date.getMinutes()}`.padStart(2, '0') :
        '00')}`

    if(units === 'minutes') {
        return time
    }

    additionalTimeUnits.shift()
    time = `${time}:${(additionalTimeUnits.includes(precision) ?
        `${date.getSeconds()}`.padStart(2, '0') :
        '00')}`

    if(units === 'seconds') {
        return time
    }

    additionalTimeUnits.shift()
    return `${time}.${(additionalTimeUnits.includes(precision) ?
        `${date.getMilliseconds()}`.padStart(3, '0') :
        '000')}`
}

export const hoursAndMinutesToDate = (hhmm, date = new Date()) => {
    if(!hhmm) {
        return null
    }

    date = isofy(date)

    const [hours, minutes] = hhmm.split(':')
    return new Date(date.setHours(hours, minutes, 0, 0))
}

export const getNextYearlyOccurrenceOfDate = date => {
    if(!(date instanceof Date)) {
        return null
    }

    const nextOccurrenceDate = new Date(date)
    nextOccurrenceDate.setFullYear(new Date().getFullYear())

    const currentDate = new Date(getDate())

    if(nextOccurrenceDate <= currentDate) {
        nextOccurrenceDate.setFullYear(currentDate.getFullYear() + 1)
    }

    return nextOccurrenceDate
}

export const zonedTimeToDate = time => {
    if(!time?.date || !time?.time || !time?.zone) {
        return null
    }

    return toDate(`${time.date}T${time.time}`, { timeZone: time.zone })
}

export const prettifyTimeZone = timeZone => timeZone
    .replace(/_/g, ' ')
    .replace(/\//g, ' / ')

export const getTimeZoneAbbreviation = (timeZone, options = {}) => formatInTimeZone(
    new Date(),
    timeZone,
    'zzz',
    options
)

export const isofy = date => {
    if(date && typeof date === 'string') {
        return parseISO(date)
    }

    return date
}

const sanitizePeriod = period => {
    if(!!period?.start) {
        period.start = startOfDay(isofy(period.start))
    }

    if(!!period?.end) {
        period.end = endOfDay(isofy(period.end))
    }

    return period
}

export const isWithinIntervalPermissive = (period = {}) => {
    if(!period?.start && !period?.end) {
        return true
    }

    period = sanitizePeriod(period)
    const now = new Date()

    if(!!period?.start && !!period?.end) {
        return isWithinInterval(now, period)
    }

    if(!!period?.start && !period?.end) {
        return isAfter(now, period.start)
    }

    if(!period?.start && !!period?.end) {
        return isBefore(now, period.end)
    }

    return false
}

export const isWithinIntervalLoose = (date, end) => {
    if (!date || !end) {
        return false
    }

    date = isofy(date)
    end = isofy(end)
    const start = new Date()

    const interval = { start, end }

    return isWithinInterval(date, interval)
}

export const isAfterInterval = (period = {}) => {
    if(!period?.start && !period?.end) {
        return true
    }

    period = sanitizePeriod(period)
    const now = new Date()

    return Object.values(period).every(date => isAfter(now, date))
}

export const weekDaysToNumbersMap = {
    'MONDAY': 1,
    'TUESDAY': 2,
    'WEDNESDAY': 3,
    'THURSDAY': 4,
    'FRIDAY': 5,
    'SATURDAY': 6,
    'SUNDAY': 0
}

export const useDurationFormatter = () => {
    const { locale } = useI18n()

    return units => {
        const sortedUnits = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']

        const formattedUnits = Object.entries(units)
            .sort(([one], [two]) => sortedUnits.indexOf(one) - sortedUnits.indexOf(two))
            .map(([unit, value]) => {
                if(value === 0) {
                    return null
                }

                const text = new Intl.NumberFormat([locale, 'en'], {
                    style: 'unit',
                    unit: singularize(unit),
                }).format(value)
                    .replace(/[\d\s]+/, '')
                    .trim()

                return `${value}${text[0].toLowerCase()}`
            })
            .filter(Boolean)

        return new Intl.ListFormat([locale, 'en']).format(formattedUnits)
    }
}

const singularize = word => {
    if(word.endsWith('s')) {
        return word.slice(0, -1)
    }

    return word
}

export const weeksInHours = weeks => Number(weeks) * 24 * 7
export const hoursInWeeks = hours => Number(hours) / 24 / 7