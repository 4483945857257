import React from 'react'
import { useIntl } from 'react-intl'
import { useMeetingTemplates } from 'contexts/meeting-templates'
import { useMeetingTemplate } from 'contexts/meeting-template'
import { useMeetingEvent } from 'contexts/meeting-event'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { validate as uuidValidate } from 'uuid'
import { isofy } from 'utilities/date-time'
import { startOfDay, isAfter, isToday, endOfDay } from 'date-fns'
import PubSub from 'pubsub-js'
import { saveAs } from 'file-saver'
import { pick } from 'utilities/object'
import {
    DownloadCloud as Export,
    UploadCloud as Import,
    FolderPlus as AddToOurTemplates
} from 'styled-icons/feather'

export const isPast = ({ date }, now) => {
    date = isofy(date)

    const today = isToday(date)
    const upcoming = isFuture({ date }, now)

    return !today && !upcoming
}

export const isFuture = ({ date }, now) => {
    date = isofy(date)
    return isAfter(endOfDay(date), now)
}

export const enrichEvent = event => {
    let {
        date,
        tasks
    } = event

    date = date ?
        startOfDay(isofy(date)) :
        null

    tasks = tasks ?? []

    return {
        ...event,
        date,
        tasks
    }
}

export const refreshMeetingEvents = () => PubSub.publish('meetingEvents.refresh')

export const useGetMeetingTemplateActions = (options = {}) => {
    const { formatMessage } = useIntl()

    const meetingTemplatesContext = useMeetingTemplates()
    const meetingTemplateContext = useMeetingTemplate()
    const meetingEventContext = useMeetingEvent()

    const {
        check,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const templatesAvailable = checkFeature('meeting-templates')
    const templatesUpgradable = checkUpgradable({ feature: 'meeting-templates' })

    const importExportAvailable = checkFeature('meeting-templates-import-export')
    const importExportUpgradable = checkUpgradable({ feature: 'meeting-templates-import-export' })

    const resolve = async id => {
        let ok = false
        let response = null

        if(!!meetingEventContext) {
            ok = true
            response = meetingEventContext.event
        } else if(!!meetingTemplateContext) {
            ok = true
            response = meetingTemplateContext.template
        } else if(!!meetingTemplatesContext) {
            const humaTemplate = meetingTemplatesContext.humaTemplates?.find(template => template.id === id)

            if(humaTemplate) {
                ok = true
                response = humaTemplate
            } else {
                const result = await meetingTemplatesContext.fetchTemplate(id)

                ok = result.ok
                response = result.response
            }
        }

        if(ok) {
            const template = pick(response, 'symbol', 'title', 'name', 'description', 'agenda')

            if(!template?.name && !!template?.title) {
                template.name = template.title
                delete template.title
            }

            template.agenda = template?.agenda.map(point => pick(point, 'title')) ?? []
            return template
        }

        return response
    }

    const download = async (...args) => {
        const template = await resolve(...args)
        if(!template) {
            return
        }

        const templateBlob = new Blob(
            [JSON.stringify(template, null, 4)],
            { type: 'application/json' }
        )

        saveAs(templateBlob, `${template.name} (meeting template).json`)
    }

    const addTemplate = async (id, source) => {
        const template = await resolve(id)
        if(!template) {
            return
        }

        options.handleSave({ template, source })
    }

    return salt => {
        const exportAction = ({ id }) => {
            if(!importExportAvailable && !importExportUpgradable) {
                return null
            }

            if(!check('performance:manage')) {
                return null
            }

            const pepper = !!meetingEventContext ?
                'export-as-template' :
                'export'

            return {
                salt: `${salt}:${pepper}`,
                icon: !!meetingEventContext ?
                    null :
                    <Export size={24} />,
                label: formatMessage({
                    id: !!meetingEventContext ?
                        'action_export_as_template' :
                        'action_export',
                    defaultMessage: !!meetingEventContext ?
                        'Export as template' :
                        'Export'
                }),
                effect: 'neutral',
                onClick: () => download(id),
                ...(importExportUpgradable ? {
                    upgrade: {
                        feature: 'meeting-templates-import-export'
                    }
                } : null)
            }
        }

        const saveAction = ({ id }) => {
            if(!templatesAvailable && !templatesUpgradable) {
                return null
            }

            if(!options?.handleSave || !meetingTemplatesContext || !check('performance:manage')) {
                return null
            }

            const humaTemplate = !!id && !uuidValidate(id)
            if(!humaTemplate && !meetingEventContext) {
                return null
            }

            const pepper = !!meetingEventContext ?
                'save-as-template' :
                'add-to-templates'

            return {
                salt: `${salt}:${pepper}`,
                icon: !!meetingEventContext ?
                    null :
                    <AddToOurTemplates size={24} />,
                label: formatMessage({
                    id: !!meetingEventContext ?
                        'action_save_as_template' :
                        'template_action_add_to_templates',
                    defaultMessage: !!meetingEventContext ?
                        'Save as template' :
                        'Add to our templates'
                }),
                effect: 'neutral',
                onClick: () => addTemplate(id, humaTemplate ? 'template' : 'event'),
                ...(templatesUpgradable ? {
                    upgrade: {
                        feature: 'meeting-templates'
                    }
                } : null)
            }
        }

        return [
            exportAction,
            saveAction
        ]
    }
}

export const useGetImportMeetingTemplateAction = () => {
    const { formatMessage } = useIntl()
    const { checkFeature } = useAccess()
    const checkUpgradable = useUpgradable()

    const available = checkFeature('meeting-templates-import-export')
    const upgradable = checkUpgradable({ feature: 'meeting-templates-import-export' })

    return options => () => {
        if(!available && !upgradable) {
            return null
        }

        return {
            salt: `${options?.salt}:import`,
            icon: <Import size={24} />,
            label: formatMessage({
                id: 'action_import_from_file',
                defaultMessage: 'Import from file'
            }),
            effect: 'neutral',
            onClick: options?.onClick,
            ...(upgradable ? {
                upgrade: {
                    feature: 'meeting-templates-import-export'
                }
            } : null)
        }
    }
}