import React from 'react'
import { Hero } from './s'
import ModuleTitle from 'components/module-title'

const MeetingsHero = () => (
    <Hero>
        <ModuleTitle which="meetings" />
    </Hero>
)

export default MeetingsHero