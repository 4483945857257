import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Plain } from 'components/button'

export const Options = styled(motion.ol).attrs({
    variants: {
        open: {
            height: 'auto',
            transition: {
                type: 'spring',
                bounce: 0.2,
                duration: .3,
                staggerChildren: .075
            }
        },
        closed: {
            height: 'var(--__huma-component-option-height)',
            transition: {
                type: 'spring',
                bounce: 0.2,
                duration: .3
            }
        }
    },
    initial: 'closed'
})`
    position: relative;
    overflow: hidden;
    list-style: none;

    border-radius: 8px;
    padding: 0;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);

    &:is(:disabled, .disabled) {
        /* box-shadow: inset 0 0 0 1px var(--huma-color-border-default); */
        background-color: var(--huma-color-surface-disabled);
        color: var(--huma-color-foreground-disabled);
    }
`

export const Option = styled(motion.li).attrs(({ picking, index }) => ({
    variants: {
        open: {
            pointerEvents: 'auto',
            y: picking
                ? 0
                : `calc(${-index} * var(--__huma-component-option-height))`,
            opacity: 1
        },
        closed: {
            pointerEvents: 'none',
            y: 'var(--__huma-component-option-height)',
            opacity: 0
        }
    },
    transition: {
        type: 'spring',
        bounce: 0.2,
        duration: .2
    },
    exit: 'closed'
}))`
    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }
`

export const OptionLabel = styled.label`
    display: grid;
    grid-template-columns: minmax(0, 1fr) min-content;
    align-items: center;
    column-gap: 24px;

    height: var(--__huma-component-option-height);
    padding: 16px;

    cursor: pointer;
`

export const PickButton = styled(Plain)`
    justify-self: end;
`