import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { InlineButton } from 'components/button'
import { TriggerTexts as Entities } from 'components/entities'

const RoleDescription = ({ user, role, units, setViewingSubordinates, salt }) => {
    const { formatMessage } = useIntl()

    // System role
    if(role.domain === 'organization') {
        return formatMessage({
            id: 'role_label_system_level',
            defaultMessage: 'On system level'
        })
    }

    // Supervisor role
    if(role.name === 'supervisor') {
        const users = units?.filter(({ type }) => type === 'user')

        return formatMessage({
            id: 'meta_for',
            defaultMessage: 'For {user}'
        }, {
            user: (
                <InlineButton
                    onClick={() => setViewingSubordinates({
                        userId: user.id,
                        roleId: role.id
                    })}
                    className="constructive">
                    <FormattedMessage
                        id="people_count"
                        defaultMessage="{count, plural, =0 {No people} =1 {1 person} other {{count} people}}"
                        values={{ count: users.length }} />
                </InlineButton>
            )
        })
    }

    const groups = units
        ?.filter(({ type }) => ['organization', 'team', 'location'].includes(type))
        // Prioritize organization
        .sort(({ type }) => type === 'organization' ? -1 : 1)

    // Over groups
    if(!!groups.length) {
        return formatMessage({
            id: 'meta_for',
            defaultMessage: 'For {user}'
        }, {
            user: (
                <Entities
                    as="span"
                    entities={groups.map(group => ({ ...group, className: 'constructive' }))}
                    preview={true}
                    entityNameOptions={{ showName: true }}
                    className="small"
                    salt={`${salt}:groups`} />
            )
        })
    }

    return null
}

export default RoleDescription