import styled from 'styled-components'
import { motion } from 'framer-motion'
import { fromPhone } from 'utilities/styled'
import { Rows as RowsBase, FlexChildShrink } from 'components/flex'
import { HeroActions as HeroActionsBase } from 'components/hero-actions'
import { H3 } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import { Ghost as GhostBase } from 'components/button'

export const Rows = styled(RowsBase)`
    row-gap: 56px;

    > div:empty,
    > div:has(> div:empty) {
        display: none;
    }
`

export const Row = styled(motion(FlexChildShrink)).attrs({
    initial: {
        opacity: 0,
        y: 16
    },
    animate: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        y: 16
    }
})`
    position: relative;

    transition: min-height 0.2s ease-in-out;

    &::after {
        content: "";
        pointer-events: none;

        position: absolute;
        inset: -12px;

        border-radius: 8px;
        border: 2px dashed var(--huma-color-border-constructive-focus);
        opacity: 0;

        transition: all 0.2s ease-in-out;
    }

    &.configuring {
        min-height: 56px;

        &::after {
            inset: -16px;

            opacity: 1;
        }

        &:empty {
            display: block;
        }
    }
`

export const Ghost = styled(GhostBase)`
    align-self: center;
`

export const HeroActions = styled(HeroActionsBase)`
    row-gap: 16px;

    &:not(:only-child) {
        margin-block-end: 16px;
    }

    ${fromPhone`
        align-items: center;
    `}
`

export const Heading = styled(H3)`
    flex-basis: 100%;

    margin-block-end: 0;

    &.spacious {
        margin-block-end: 16px;
    }

    & + p {
        margin-block-start: 8px;
    }
`

const boxCss = fromPhone`
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 0 0 1px var(--huma-color-border-default);
`

export const Container = styled.div`
    ${boxCss}
`

export const Empty = styled(Paragraph)`
    ${boxCss}

    padding: 40px;

    text-align: center;
    color: var(--huma-color-foreground-minimal);
`