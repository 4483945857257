import React from 'react'
import Hero from './hero'
import Details from './details'
import { fallbackSymbol } from 'pages/meetings'

const PreviewTemplate = ({ name, description, symbol, agenda, dismiss, salt, ...props }) => (
    <>
        <Hero
            name={name}
            symbol={symbol ?? fallbackSymbol}
            dismiss={dismiss} />
        <Details
            {...props}
            description={description}
            agenda={agenda}
            salt={salt} />
    </>
)

export default PreviewTemplate
