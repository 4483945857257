import React, { Component, isValidElement } from 'react'
import { useForm } from 'components/form/controller'
import isEqual from 'react-fast-compare'
import { cls } from 'utilities/dom'
import { pick } from 'utilities/object'
import { isInRange } from 'utilities/math'
import { Legend, Control } from 'components/form/field/s'
import { Fieldset, OptionColumns, CheckboxColumn, TextColumn, UncapButton } from './s'
import Checkbox from 'components/form/input/checkbox'
import Ellipsify from 'components/ellipsify'

const empty = []

class EditCheckboxes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            capped: !!props.cap
        }

        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const limitationsChanged = !isEqual(pick(field, 'min', 'max'), pick(this.props.field, 'min', 'max'))
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged || limitationsChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        let {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => required ?
                isInRange({ ...this.getMinMax(), value: value?.length ?? 0 }) :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    toggle = option => this.setState(({ value }) => {
        if(!!value.find(({ id }) => id === option.id)) {
            return { value: value.filter(({ id }) => id !== option.id) }
        }

        return { value: [...value, option] }
    }, () => this.props.onChange?.({ [this.props.name]: this.state.value }))

    unset = () => this.setState({
        value: empty,
        capped: !!this.props.cap
    })

    getMinMax = () => {
        let {
            required = false,
            min = 0,
            max = Infinity
        } = this.props.field ?? {}

        if(required && min === 0) {
            min = 1
        }

        return { min, max }
    }

    render() {
        const {
            value,
            capped
        } = this.state

        const {
            className,
            controlProps = {},
            salt,
            label,
            name,
            options,
            direction = 'ltr',
            framed = false,
            ellipsify = false,
            loading = false,
            field = {},
            cap = {}
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const { reversed = false } = field

        const fieldsetClassName = cls([
            className,
            reversed && 'reversed',
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            controlProps?.className,
            framed && 'framed'
        ])

        const valueMapperContext = { name, salt }
        const mapValues = this.props.form.mode === 'json' ?
            jsonMapper(valueMapperContext) :
            multipartMapper(valueMapperContext)

        const {
            required,
            softRequired,
            optional
        } = field

        const capAt = cap?.at ?? Infinity
        const maxedOut = value?.length === this.getMinMax().max

        return (
            <Fieldset {...(fieldsetClassName ? { className: fieldsetClassName } : null)}>
                {!!label && (
                    <Legend
                        className="accent"
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Legend>
                )}
                <Control {...(controlClassName ? { className: controlClassName } : null)}>
                    {mapValues(value).map(({ key, ...option }) => (
                        <input
                            {...option}
                            key={key} />
                    ))}
                    {options
                        .filter((_, index) => {
                            if(!capped) {
                                return true
                            }

                            return index < capAt
                        })
                        .map(option => {
                            const checked = !!value.find(({ id }) => id === option.id)
                            const disabled = maxedOut && !checked

                            return (
                                <OptionColumns
                                    $direction={direction}
                                    {...(disabled ? { className: 'disabled' } : null)}
                                    key={`${salt}:option:${option.id}:checked:${checked}`}>
                                    <CheckboxColumn>
                                        <Checkbox
                                            defaultChecked={checked}
                                            onChange={() => this.toggle(option)}
                                            controlProps={{ as: 'span' }}
                                            {...(disabled ? { disabled } : null)} />
                                    </CheckboxColumn>
                                    <TextColumn>
                                        {(ellipsify && !isValidElement(option.name)) && (
                                            <Ellipsify text={option.name} />
                                        )}
                                        {(!ellipsify || isValidElement(option.name)) && option.name}
                                    </TextColumn>
                                </OptionColumns>
                            )
                        })
                    }
                    {(capped && capAt > 0 && options.length > cap.at) && (
                        <UncapButton
                            className="constructive"
                            onClick={() => this.setState({ capped: false })}>
                            {cap.text}
                        </UncapButton>
                    )}
                </Control>
            </Fieldset>
        )
    }
}

const jsonMapper = ({ name, salt }) => options => options.map(({ id }, index) => ({
    name: `${name}[${index}]`,
    value: id,
    type: 'hidden',
    key: `${salt}:value:${id}:${index}`
}))

const multipartMapper = ({ name, salt }) => options => [{
    name,
    value: options.map(({ id }) => id).join(','),
    type: 'hidden',
    key: `${salt}:value`
}]

export default props => {
    const form = useForm()

    return (
        <EditCheckboxes
            {...props}
            form={form} />
    )
}