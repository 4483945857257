import React from 'react'
import { useGetRoleName, getRoleIcon } from 'pages/settings/pages/roles/utilities'
import { RoleWrapper, IconWrapper, Name, Meta } from './s'
import RoleDescription from './description'

const Role = ({ role, ...props }) => {
    const getRoleName = useGetRoleName()

    const Icon = getRoleIcon(role) ?? null

    return (
        <RoleWrapper>
            <IconWrapper>
                <Icon size={24} />
            </IconWrapper>
            <Name>{getRoleName(role)}</Name>
            <Meta>
                <RoleDescription
                    {...props}
                    role={role} />
            </Meta>
        </RoleWrapper>
    )
}

export default Role