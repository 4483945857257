import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Container as ContainerBase } from 'utilities/modal'

export const Container = styled(ContainerBase)`
    margin-block: 15px; /* Sorry, it just works */
    height: min(1048px, calc(100svh - 32px));
    max-height: unset;
    padding: 0;

    &.preview {
        border: 4px dashed var(--huma-color-border-default);
    }
`

export const Wrapper = styled.div`
    timeline-scope: --survey-scroll;
    overflow: hidden;

    display: grid;
    grid-template-rows:
        [hero] max-content
        [header] max-content
        [content] minmax(0, 1fr);
    grid-template-columns: 1fr;

    height: 100%;

    ${belowTablet`
        grid-template-columns: minmax(0, 1fr);
    `}
`