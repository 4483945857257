import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useInterval } from 'hooks/interval'
import { cls } from 'utilities/dom'
import { isofy } from 'utilities/date-time'
import { useFormatSurveyRunTitle, useFormatSurveyRunDuration, getFallbackSymbol } from 'pages/surveys/utilities'
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'
import {
    Item,
    Symbol, Meta, Title, InlineMessage, Arrow
} from './s'
import Paragraph from 'components/typography/paragraph'

const PendingSurveyRun = ({ pendingSurveyRun, respond }) => {
    const { formatMessage } = useIntl()

    const formatSurveyRunTitle = useFormatSurveyRunTitle()
    const formatSurveyRunDuration = useFormatSurveyRunDuration()

    const [now, setNow] = useState(new Date)
    useInterval(() => setNow(new Date), 15000)
    const end = isofy(pendingSurveyRun.closedAt)

    const daysLeft = differenceInDays(end, now)
    const hoursLeft = differenceInHours(end, now)
    const minutesLeft = differenceInMinutes(end, now)

    const overdue = daysLeft <= 1
    const due = daysLeft < 5

    const getTimeLeftMessage = () => {
        if(daysLeft > 0) {
            return {
                id: 'expiration_status_expiring',
                defaultMessage: '{days, plural, =0 {0 days left} =1 {1 day left} other {{days} days left}}'
            }
        }

        if(hoursLeft > 0) {
            return {
                id: 'expiration_status_expiring_hours',
                defaultMessage: '{hours, plural, =0 {0 hours left} =1 {1 hour left} other {{hours} hours left}}'
            }
        }

        return {
            id: 'expiration_status_expiring_minutes',
            defaultMessage: '{minutes, plural, =0 {0 minutes left} =1 {1 minute left} other {{minutes} minutes left}}'
        }
    }

    const className = cls([
        overdue && 'error',
        (due && !overdue) && 'warning'
    ])

    return (
        <Item
            onClick={() => respond(pendingSurveyRun)}
            {...(className ? { className } : null)}>
            <Symbol
                symbol={pendingSurveyRun.symbol ?? getFallbackSymbol(pendingSurveyRun)}
                className="hover-effect" />
            <Meta>
                <Title className="ellipsify compact">{pendingSurveyRun.title}</Title>
                {(pendingSurveyRun.type === 'custom') && (
                    <Paragraph
                        as="div"
                        className="caption small compact interpoint-divider">
                        <span>
                            {formatSurveyRunDuration(pendingSurveyRun)}
                        </span>
                        {(!overdue && !due) && (
                            <span>
                                {formatSurveyRunTitle({ createdAt: end })}
                            </span>
                        )}
                        {(overdue || due) && (
                            <InlineMessage
                                type={overdue ? 'error' : 'warning'}
                                message={formatMessage(getTimeLeftMessage(), {
                                    days: daysLeft,
                                    hours: hoursLeft,
                                    minutes: minutesLeft
                                })}
                                className="tight compact" />
                        )}
                    </Paragraph>
                )}
            </Meta>
            <Arrow size={24} />
        </Item>
    )
}

export default PendingSurveyRun