import React from 'react'
import Page from 'contexts/page'
import Routing from './routing'

const MeetingsPage = ({ upgradable, ...props }) => (
    <Page
        view="meetings"
        title={{
            id: 'noun_meetings',
            defaultMessage: 'Meetings'
        }}
        upgrade={{
            enabled: upgradable,
            passthrough: true
        }}>
        <Routing
            {...props}
            upgradable={upgradable} />
    </Page>
)

export const fallbackSymbol = {
    emoji: 'star',
    colorToken: 'symbol-yellow-1'
}

export const blankSymbol = {
    emoji: 'memo',
    colorToken: 'symbol-green-1'
}

export default MeetingsPage