import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSurvey } from 'contexts/survey'
import { getFallbackSymbol, useFormatSurveyRunDuration } from 'pages/surveys/utilities'
import { Wrapper, Greeting, Button } from './s'
import Symbol from 'components/symbol'
import { Title } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'

const Intro = ({ started, setStarted }) => {
    const { getResponseRun } = useSurvey()
    const source = getResponseRun()

    const {
        title,
        questions = []
    } = source

    const formatSurveyRunDuration = useFormatSurveyRunDuration()

    return (
        <Wrapper {...(started ? {
            className: 'running',
            inert: 'true'
        } : null)}>
            <Greeting>
                <Symbol
                    symbol={source.symbol ?? getFallbackSymbol(source)}
                    size={56} />
                <Title className="compact">{title}</Title>
                {!!source.description && (
                    <Paragraph className="preamble compact">{source.description}</Paragraph>
                )}
                <Paragraph className="caption interpoint-divider compact">
                    <span>
                        {formatSurveyRunDuration(source)}
                    </span>
                    <span>
                        <FormattedMessage
                            id="questions_count"
                            defaultMessage="{count, plural, =0 {} =1 {1 question} other {{count} questions}}"
                            values={{ count: questions.length }} />
                    </span>
                </Paragraph>
                <Button
                    onClick={() => setStarted(true)}
                    className="constructive">
                    <FormattedMessage
                        id="action_start"
                        defaultMessage="Start" />
                </Button>
            </Greeting>
        </Wrapper>
    )
}

export default Intro