import styled from 'styled-components'
import { fromTablet, belowTablet } from 'utilities/styled'
import { Button as ButtonBase } from 'components/button'

export const Wrapper = styled.div`
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-row: header / content-end;
    grid-column: 1 / -1;

    margin-inline: auto;
    max-width: var(--huma-global-content-tight-width);
    width: 100%;
    padding-block-end: 16px;
    padding-inline: 32px;

    transition: .2s ease;
    transition-property: opacity, transform, display;
    transition-behavior: allow-discrete;

    &.running {
        opacity: 0;

        ${fromTablet`
            transform: translateY(-32px);
        `}

        ${belowTablet`
            transform: translateX(-32px);
        `}

        @supports (transition-behavior: allow-discrete) {
            display: none;
        }
    }
`

export const Greeting = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    flex-grow: 1;
`

export const Button = styled(ButtonBase)`
    align-self: start;
`