import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { motion } from 'framer-motion'
import { Field as FieldBase, Label as LabelBase, Control as ControlBase } from 'components/form/field/s'

export const Field = styled(FieldBase)`
    &.spacious {
        padding: 8px 0;
    }
`

export const Label = LabelBase

export const Control = styled(ControlBase)`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;

    ${Field}.naked & {
        border: none;
        border-radius: 0;
    }
`

export const Option = styled.div`
    position: relative;

    &:not(.last):after {
        content: "";
        position: absolute;
        left: 16px;
        bottom: 0;

        width: calc(100% - 32px);
        height: 1px;
        background-color: var(--huma-color-border-default);
    }
`

export const OptionHeaderColumns = styled.label`
    display: grid;
    grid-template-columns: 1fr minmax(0, min-content);
    grid-auto-columns: auto;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
    min-height: 40px;
    padding: 16px;
    cursor: pointer;

    ${Field}.spacious & {
        padding-left: 24px;
        padding-right: 24px;
    }

    &.disabled {
        cursor: default;
    }
`

export const OptionHeaderLabelColumn = styled.span`
    display: grid;
    grid-auto-columns: minmax(min-content, auto);
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 8px;
    line-height: 20px;
    color: var(--huma-color-foreground-default);

    &:has(.badge) {
        grid-auto-columns: unset;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        grid-auto-flow: row;
        justify-items: start;
        gap: 4px;
    }

    svg.upgrade {
        ${svgStroke()}

        color: var(--huma-color-foreground-subtle);
    }
`

export const OptionHeaderRadioColumn = styled.span`
    padding: 2px 0;
`

export const OptionContent = styled(motion.div).attrs(() => ({
    variants: {
        contracted: {
            height: 0,
            overflow: 'hidden'
        },
        expanded: {
            height: 'auto',

            transitionEnd: {
                overflow: 'visible'
            }
        }
    },
    transition: {
        duration: .15
    },
    initial: 'contracted'
}))`
    position: relative;

    padding-inline: 16px;

    ${Field}.spacious & {
        padding-inline: 24px;
    }
`

export const InnerPadding = styled.div`
    padding-block-end: 16px;

    > :last-child {
        margin-block-end: 0;
    }
`