import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useUpgradable } from 'hooks/upgradable'
import { hashify, usePinnedTopics } from 'pages/handbook/utilities'
import { blankSymbol } from 'pages/handbook'
import { getHandbookCategoryUrl } from 'utilities/url'
import { onClink } from 'utilities/event'
import UpgradeCTA from 'components/upgrade-cta'
import { Heading } from './s'
import { Wrapper as Topics, Topic, Emoji, Label } from 'pages/handbook/components/category/topics/s'
import Ellipsify from 'components/ellipsify'
import { TopicModal } from 'pages/handbook/pages/s'
import ActingHandbookTopic from 'pages/handbook/modals/topic'
import { Scrollable as Modal } from 'modals/generic'
import Successful from 'pages/handbook/modals/successful'

const PinnedTopics = ({ salt }) => {
    const { pinnedTopics = [] } = usePinnedTopics()

    const [actingTopic, setActingTopic] = useState(null)
    const [successfulMessage, setSuccessfulMessage] = useState(null)

    if(!pinnedTopics.length) {
        return null
    }

    const click = topic => onClink(() => setActingTopic({
        topic,
        mode: 'view'
    }))

    return (
        <div>
            <Heading>
                <FormattedMessage
                    id="handbooks_heading_pinned_topics"
                    defaultMessage="Featured topics" />
            </Heading>
            <Topics className="highlighted">
                {pinnedTopics.map(topic => (
                    <Topic
                        {...((topic.status === 'draft') ? { className: 'pinned-draft' } : null)}
                        to={`${getHandbookCategoryUrl({ id: topic.categoryId })}#${hashify(topic.id, 'topic')}`}
                        onClick={click(topic)}
                        key={topic.id}>
                        <Emoji
                            emoji={topic.symbol?.emoji ?? blankSymbol.emoji}
                            size={14} />
                        <Label>
                            <Ellipsify
                                text={topic.title}
                                className="no-underline" />
                        </Label>
                    </Topic>
                ))}
            </Topics>
            <TopicModal
                className="large"
                show={!!actingTopic}
                dismiss={() => setActingTopic(null)}
                salt={`${salt}:acting`}>
                <ActingHandbookTopic
                    {...actingTopic}
                    view={() => setActingTopic(topic => ({
                        ...topic,
                        mode: 'view'
                    }))}
                    edit={() => setActingTopic(topic => ({
                        ...topic,
                        mode: 'edit'
                    }))}
                    dismiss={() => setActingTopic(null)}
                    onDone={response => {
                        if(!!response?.notify) {
                            setSuccessfulMessage({ what: 'topic' })
                        }
                    }}
                    salt={`${salt}:acting`} />
            </TopicModal>
            <Modal
                show={successfulMessage}
                dismiss={() => setSuccessfulMessage(null)}
                salt={`${salt}:successful`}>
                <Successful
                    {...successfulMessage}
                    dismiss={() => setSuccessfulMessage(null)}
                    salt={`${salt}:successful`} />
            </Modal>
        </div>
    )
}

export default props => {
    const upgradable = useUpgradable()({ feature: 'handbook-featured' })
    if(upgradable) {
        return (
            <UpgradeCTA
                feature="handbook-featured"
                useUpgradeIcon
                useFeatureOrModuleIcon
                useFeatureTitle
                useFeatureDescription
                className="compact"
                salt="handbook-featured" />
        )
    }

    return <PinnedTopics {...props} />
}
