import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import PendingSurveyRunsProvider, { usePendingSurveyRuns } from 'contexts/survey-pending-runs'
import { useLocationQueryIntent } from 'hooks/location-intent'
import SurveyProvider from 'contexts/survey'
import { Heading } from '../s'
import PendingSurveyRuns from 'pages/surveys/lists/pending-survey-runs'
import RespondToSurveyRun from 'pages/surveys/modals/survey-run/respond'

const salt = 'survey:respond'

const SurveyRespond = () => {
    const {
        pendingSurveyRuns = [],
        completeSurveyRun
    } = usePendingSurveyRuns()

    const [runId, setRunId] = useState(null)
    const [responding, setResponding] = useState(null)

    // If we make a change to not fetch the survey runs up front, or
    // the list of runs grows too long or is limited to only one run etc.,
    // this needs to be changed to useQueryResolveItem instead
    useLocationQueryIntent({
        intent: 'survey-respond',
        action: ({ runId }) => setRunId(runId)
    })

    useEffect(() => {
        const run = pendingSurveyRuns?.find(({ id }) => id === runId)

        if(run) {
            setRunId(null)
            setResponding(run)
        }
    }, [runId, pendingSurveyRuns?.map(({ id }) => id).join('+')])

    return (
        <>
            {(!!pendingSurveyRuns?.length) && (
                <>
                    <Heading className="spacious">
                        <FormattedMessage
                            id="dashboard_surveys_heading"
                            defaultMessage="Surveys awaiting your response" />
                    </Heading>
                    <PendingSurveyRuns respond={setResponding} />
                </>
            )}
            <SurveyProvider
                fetchOnMount={false}
                respondRun={responding}
                key={responding?.id ?? 'empty'}>
                <RespondToSurveyRun
                    show={!!responding}
                    setShow={setResponding}
                    answerable={true}
                    onDone={completeSurveyRun}
                    salt={`${salt}:preview`} />
            </SurveyProvider>
        </>
    )
}

export default props => {
    const { checkModule } = useAccess()

    if(!checkModule('surveys')) {
        return null
    }

    return (
        <PendingSurveyRunsProvider types={['custom']}>
            <SurveyRespond {...props} />
        </PendingSurveyRunsProvider>
    )
}