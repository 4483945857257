import React, { Component } from 'react'
import { useForm } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { CheckboxInput } from 'components/form/input/checkbox'
import { Field, Label, Control, Checkbox, Switch, State, Loader } from './s'
import { Check, Lock } from 'styled-icons/feather'

const empty = false

class EditCheckbox extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        let {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            type: 'boolean',
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => required ?
                value === true :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => {
        let value = props.field?.value
        if(typeof value !== 'boolean') {
            value = empty
        }

        return { value }
    }

    onCheckboxChange = ({ target }) => {
        const value = !!target.checked

        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })
    }

    unset = () => this.setState({ value: empty })

    render() {
        const { value } = this.state

        const {
            className,
            controlProps = {},
            salt,
            label,
            helper,
            name,
            enabled = true,
            loading = false,
            field = {},
            direction = 'ltr',
            interaction = 'checkbox',
            icon,
            iconClassName,
            forwardedRef,
            ...props
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            !enabled && 'disabled',
            'horizontal',
            touched && 'touched',
            (!error && enabled && loading) && 'loading',
            error && 'error'
        ])

        const {
            required,
            softRequired,
            optional
        } = field

        const valueToOnOff = value => value ? 'on' : 'off'

        const animate = (!enabled && !loading) ?
            `disabled-${valueToOnOff(value)}` :
            valueToOnOff(value)

        const Icon = icon ?? Check

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                $direction={direction}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        className={cls([
                            'accent',
                            interaction === 'switch' && 'for-switch'
                        ])}
                        required={required || softRequired}
                        optional={optional}
                        helper={helper}>
                        {label}
                    </Label>
                )}
                <Control className={interaction}>
                    {(interaction === 'checkbox') && (
                        <Checkbox
                            {...omit(props, 'field', 'form', 'whistle')}
                            name={name}
                            controlProps={controlProps}
                            id={salt}
                            checked={value}
                            onChange={this.onCheckboxChange}
                            disabled={!enabled}
                            ref={forwardedRef} />
                    )}
                    {(interaction === 'switch') && (
                        <Switch
                            {...controlProps}
                            htmlFor={salt}
                            className={animate}>
                            <CheckboxInput
                                {...omit(props, 'field', 'form', 'whistle')}
                                name={name}
                                id={salt}
                                checked={value}
                                role="switch"
                                onChange={this.onCheckboxChange}
                                disabled={!enabled}
                                ref={forwardedRef} />
                            <State className={animate}>
                                {(!enabled && !loading) && <Lock className="icon" size="16" />}
                                {(!!enabled && !!value && !loading) && (
                                    <Icon
                                        size="16"
                                        className={cls([
                                            'icon',
                                            iconClassName
                                        ])} />
                                )}
                            </State>
                            {(!error && loading) && (
                                <Loader
                                    animate={valueToOnOff(value)}
                                    initial={valueToOnOff(value)} />
                            )}
                        </Switch>
                    )}
                </Control>
            </Field>
        )
    }
}

export default props => {
    const form = useForm()

    return (
        <EditCheckbox
            {...props}
            form={form} />
    )
}