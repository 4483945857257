import React, { Component } from 'react'
import { useForm } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { Fieldset, Legend, Control } from 'components/form/field/s'
import { OptionsColumns, RadiobuttonColumn, Radiobutton } from './s'

const empty = null

class EditIcons extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => required ?
                !!value :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    select = option => this.setState({ value: option }, () => {
        this.props.onChange?.({ [this.props.name]: option.value })
    })

    unset = () => this.setState({ value: empty })

    render() {
        const { value } = this.state

        const {
            className,
            field = {},
            controlProps = {},
            label,
            name,
            options,
            enabled = true,
            framed = false,
            loading = false,
            salt
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldsetClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            controlProps?.className,
            framed && 'framed'
        ])

        const {
            required,
            softRequired,
            optional
        } = field

        return (
            <Fieldset {...(fieldsetClassName ? { className: fieldsetClassName } : null)}>
                {!!label && (
                    <Legend
                        className="accent"
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Legend>
                )}
                <Control {...(controlClassName ? { className: controlClassName } : null)}>
                    <OptionsColumns ref={this.props.forwardedRef}>
                        {options.map(option => {
                            const checked = option.checked || value === option.value
                            const disabled = !enabled || !!option?.disabled

                            const Icon = option.icon

                            return (
                                <RadiobuttonColumn key={`${salt}:option:${option.value}`}>
                                    <Icon
                                        size={24}
                                        aria-hidden />
                                    <Radiobutton
                                        name={name}
                                        defaultValue={option.value}
                                        defaultChecked={checked}
                                        onChange={() => this.select(option)}
                                        onClick={event => {
                                            // Only trigger onClick for non-keyboard events
                                            if(event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
                                                option.onClick?.()
                                            }
                                        }}
                                        {...(disabled ? { disabled: true } : null)}
                                        aria-label={option.label}
                                        {...(!!option?.ref ? { ref: option.ref } : null)} />
                                </RadiobuttonColumn>
                            )
                        })}
                    </OptionsColumns>
                </Control>
            </Fieldset>
        )
    }
}

export default props => {
    const form = useForm()

    return (
        <EditIcons
            {...props}
            form={form} />
    )
}