import styled from 'styled-components'
import { Label as LabelBase } from 'components/form/field/s'
import { Grid } from 'components/grid'
import { Columns } from 'components/flex'
import { Plain } from 'components/button'

export const MeetingTemplateList = styled(Grid)`
    position: relative;

    grid-auto-rows: auto;
    grid-auto-flow: row;

    border-radius: 8px;
    min-height: 72px;
    padding-inline: 16px;
    box-shadow: 0 0 0 1px var(--huma-color-border-default);
`

export const Label = styled(LabelBase)`
    ${MeetingTemplateList} + & {
        margin-block-start: 24px;
    }

    width: 100%;
`

export const ShowAll = styled(Columns)`
    position: relative;
    flex-wrap: wrap;
    column-gap: 4px;

    margin-block: 16px;
    min-height: 20px;
`

export const ShowAllCount = styled.span`
    font-size: 14px;
    line-height: 20px;
    color: var(--huma-color-foreground-subtle);
`

export const ShowAllButton = styled(Plain)`
    border: none;
    height: auto;

    font-size: 14px;
    line-height: 20px;
`
