import styled, { css } from 'styled-components'
import {
    svgStroke,
    darkMode
} from 'utilities/styled'
import { motion } from 'framer-motion'
import { ListItem as ListItemBase } from 'components/list'
import LinkBase, { linkCss } from 'components/link'
import { Simple } from 'components/button'
import HighlighterBase from 'react-highlight-words'
import { aiGradientCss } from 'components/content-assistant/s'

export const ListItem = styled(ListItemBase)`
    position: relative;

    display: flex;
    align-items: center;
    column-gap: 16px;

    min-height: var(--item-height, 48px);

    svg {
        ${svgStroke()}

        justify-self: center;

        &.integration {
            filter: grayscale(1) brightness(.5);

            ${darkMode`
                filter: grayscale(1) brightness(1.5);
            `}
        }
    }
`

const itemCss = css`
    --icon-width: 40px;
    --action-width: 24px;

    flex: 1;
    display: grid;
    grid-template-columns: 24px minmax(0, 1fr);
    align-items: center;
    column-gap: 16px;

    border-radius: 4px;
    min-height: inherit;
    padding-inline: 12px;

    text-align: start;

    &.entity-command {
        --action-width: 40px;

        max-width: 100%;
        padding-inline-start: calc(12px + var(--action-width));
    }

    &:where(.active, :hover, :focus-visible) {
        background-color: var(--huma-color-surface-minimal);

        svg.integration {
            filter: grayscale(0) brightness(1);
        }

        @media (prefers-reduced-motion: no-preference) {
            &.ai svg path {
                ${aiGradientCss}
            }
        }
    }

    & > * {
        pointer-events: none;
    }
`

export const Item = styled.div`
    ${itemCss}
`

export const Button = styled(Simple)`
    ${itemCss}
`

export const Link = styled(LinkBase)`
    ${itemCss}

    &:hover {
        text-decoration: none;
    }
`

export const Anchor = styled.a`
    ${linkCss}
    ${itemCss}

    &:hover {
        text-decoration: none;
    }
`

export const Label = styled.span`
    display: flex;
    column-gap: 4px;

    max-width: calc(100% - var(--icon-width) -var(--action-width));

    line-height: 24px;

    .label {
        overflow: hidden;

        display: inline-flex;
        column-gap: 0.5ch;

        text-overflow: ellipsis;
    }

    .sub-label {
        overflow: hidden;

        font-size: 14px;
        text-overflow: ellipsis;
        color: var(--huma-color-foreground-subtle);

        &::before {
            content: "(";

            font-weight: 400;
        }

        &::after {
            content: ")";

            font-weight: 400;
        }
    }
`

export const KeyHintInner = styled(motion.div).attrs(() => ({
    variants: {
        inactive: {
            opacity: 0,
            transition: {
                duration: .1
            }
        },
        active: {
            opacity: 1,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'inactive',
    exit: 'inactive'
}))`
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 100%;
    color: var(--huma-color-foreground-default);

    svg {
        stroke-width: 2;
    }
`

export const Highlighter = styled(HighlighterBase)`
    mark {
        position: relative;
        z-index: 1;

        background: none;

        color: var(--huma-color-foreground-default);

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            inset: -8px 0;

            background-color: var(--huma-color-surface-highlight-subtle);

            .sub-label & {
                inset: -4px 0;
            }
        }
    }
`