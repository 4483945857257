import styled from 'styled-components'
import {
    svgStroke,
    screenReaderOnly, fromPhone, belowPhone
} from 'utilities/styled'
import { Control as ControlBase } from 'components/form/field/s'

export const Control = styled(ControlBase)`
    container-type: inline-size;
`

export const Options = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;

    padding: 0;

    &.spread {
        justify-content: space-between;
    }

    &:not(.spread) {
        ${fromPhone`
            column-gap: min(64px, 8cqi);
        `}
    }

    ${belowPhone`
        justify-content: space-between;
    `}
`

export const Option = styled.li`
    ${Options}:not(.spread) & {
        ${fromPhone`
            padding-inline: 0px;
        `}
    }
`

export const OptionLabel = styled.label`
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;

    text-align: center;

    .icon {
        ${svgStroke()}
    }

    ${Option}:not(.checked) & .icon {
        color: var(--huma-color-foreground-subtle);
    }

    ${Option}:not(.checked):hover & .icon {
        color: var(--huma-color-border-constructive-bold);
    }

    ${Option}.filled & .icon {
        color: var(--huma-color-surface-constructive-subtle);

        html[data-theme="dark"] & {
            color: var(--huma-color-surface-constructive-minimal);
        }
    }

    ${Option}.filled:hover & .icon {
        html[data-theme="dark"] & {
            color: var(--huma-color-surface-constructive-bold);
        }
    }

    ${Option}.checked & .icon {
        color: var(--huma-color-surface-constructive-bold);

        &.emoji {
            &.unhappy {
                color: var(--huma-color-emoji-scale-1);
            }

            &.sad {
                color: var(--huma-color-emoji-scale-2);
            }

            &.smile {
                color: var(--huma-color-emoji-scale-3);
            }

            &.happy {
                color: var(--huma-color-emoji-scale-4);
            }
        }
    }
`

export const Number = styled.span`
    position: relative;

    display: block;

    width: 1lh;
    height: 1lh;

    transition: color .2s ease;

    font-size: clamp(14px, 1lh, 3vw);
    line-height: 2; /* Don’t ask me to align fonts ever again. */

    ${Options}.medium & {
        font-size: 1.4rem;
    }

    ${Options}.large & {
        font-size: 1.75rem;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;

        border-radius: 8px;

        transition: inset .2s cubic-bezier(.4, 0, .2, 1);
    }

    ${Option}:focus-within &::after {
        outline: 2px solid var(--huma-color-border-constructive-focus);
        outline-offset: 2px;
    }

    ${Option}.checked &::after {
        border-color: var(--huma-color-surface-bold);
        background-color: var(--huma-color-surface-bold);
    }

    /* Default version, used in ratings */
    ${Options}:not(.dashed) & {
        color: var(--huma-color-foreground-default);

        transition-property: color, scale;
    }

    ${Options}:not(.dashed) &::after {
        background-color: var(--huma-color-surface-minimal);
    }

    ${Options}:not(.dashed) ${Option}:not(.checked):where(:focus-within, :hover) &::after {
        background-color: var(--huma-color-surface-subtle);
    }

    ${Options}:not(.dashed) ${Option}:not(.checked):hover & {
        scale: 1.1;
    }

    ${Options}:not(.dashed) ${Option}.checked & {
        scale: 1.2;

        color: var(--huma-color-foreground-on-bold);

        &::after {
            background-color: var(--huma-color-surface-constructive-bold);
        }
    }

    /* Dashed version, used in competences */
    ${Options}.dashed &::after {
        border-radius: 50%;
        border: 2px solid var(--huma-color-foreground-subtle);
    }

    ${Options}.dashed.large &::after {
        border-width: 3px;
    }

    ${Options}.dashed ${Option}:hover &::after {
        inset: -2px;

        border-style: dashed;
        border-color: var(--huma-color-border-constructive-bold);
    }

    ${Options}.dashed ${Option}:not(.checked):hover & {
        color: var(--huma-color-foreground-constructive-bold);
        font-weight: 500;
    }

    ${Options}.dashed ${Option}.checked & {
        font-weight: 500;
    }

    ${Options}.dashed ${Option}.checked &::after {
        border-style: dashed;
    }

    ${Options}.dashed ${Option}.checked &::after {
        border-color: var(--huma-color-border-bold);
        background-color: transparent;
    }
`

export const NumberLabel = styled.span`
    line-height: 1.4;
`

export const OptionInput = styled.input.attrs({
    type: 'radio'
})`
    ${screenReaderOnly}
`