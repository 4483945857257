import React, { useState, useEffect } from 'react'
import { useSurveyResponse } from 'hooks/survey-response'
import { Scrollable as Modal } from 'modals/generic'
import SurveyProvider from 'contexts/survey'
import Hero from './hero'
import Content from './content'

const SurveyRespond = () => {
    const [show, setShow] = useState(false)

    const meta = useSurveyResponse()
    useEffect(() => { meta && setShow(true) }, [meta])

    const dismiss = () => setShow(false)

    const salt = 'survey:respond'

    return (
        <Modal
            show={show}
            dismiss={dismiss}>
            <SurveyProvider>
                <>
                    <Hero />
                    <Content
                        meta={meta}
                        dismiss={dismiss}
                        salt={salt} />
                </>
            </SurveyProvider>
        </Modal>
    )
}

export default SurveyRespond