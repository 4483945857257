import React, {
    useRef, forwardRef,
    useState, useCallback, useLayoutEffect,
    cloneElement, isValidElement
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useSize } from 'hooks/viewport'
import { numbers } from 'utilities/styled'
import { valuesOrdered, reduce, pick, omit, compact as compactObject } from 'utilities/object'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import {
    Container,
    MobileHeader, CloseButton
} from './s'
import Tooltip from 'components/tooltip'
import { Simple } from 'components/button'
import List from './list'
import Prompts from './prompts'
import Upgrade from 'modals/upgrade'
import {
    MoreHorizontal,
    Edit,
    StopCircle as Stop,
    Calendar,
    Paperclip as Attachment,
    Trash2 as Delete,
    X
} from 'styled-icons/feather'
import { Meetings } from 'components/feather'
import { hideChat, showChat } from 'hooks/voiceflow'

const ToggleButton = forwardRef(({ icon: Icon = MoreHorizontal, onClick, className, ...props }, ref) => {
    className = cls([
        className,
        'actions-button'
    ])

    return (
        <Simple
            {...props}
            className={className}
            onClick={onClick}
            icon={Icon}
            ref={ref} />
    )
})

const ContextMenu = ({
    actions = [],
    context = {},
    prebound = false,
    show: outsideShow = false,
    trigger: Trigger,
    triggerProps = null,
    wrapperProps = null,
    className,
    ...widget
}) => {
    const width = useSize({ dimension: 'width' })

    const [visible, setVisible] = useState(outsideShow)
    const [prompting, setPrompting] = useState(null)
    const [upgrading, setUpgrading] = useState(null)

    const toggleRef = useRef()

    const {
        placement,
        appendTo = document.body,
        salt
    } = widget

    const show = () => {
        setVisible(true)
        hideChat()
    }

    const hide = () => {
        setVisible(false)
        showChat()
    }

    const toggle = useCallback(() => {
        visible && hide()
        !visible && show()
    }, [visible])

    useLayoutEffect(() => {
        if(visible) {
            document.body.classList.add('blocking')
        } else {
            document.body.classList.remove('blocking')
        }
    }, [visible])

    actions = prebound ?
        getActionsArray(actions) :
        getBoundActions(actions, context)

    // Viewport checks
    actions = actions
        .filter(action => {
            if(action.hideOn && numbers[action.hideOn]) {
                return width <= numbers[action.hideOn]
            }

            return true
        })
        .map(action => omit(action, 'hideOn'))

    if(!actions.length) {
        return null
    }

    return (
        <>
            <Tooltip
                placement={placement ?? 'bottom-end'}
                className="widget"
                trigger="click"
                interactive={true}
                visible={visible}
                onClickOutside={hide}
                duration={0}
                animation={false}
                appendTo={appendTo}
                content={(
                    <Container {...(className ? { className } : null)}>
                        <MobileHeader>
                            <CloseButton onClick={hide}>
                                <X size={24} />
                                <FormattedMessage
                                    id="action_close"
                                    defaultMessage="Close" />
                            </CloseButton>
                        </MobileHeader>
                        <List
                            actions={actions}
                            setPrompting={setPrompting}
                            setUpgrading={setUpgrading}
                            onClickCapture={() => requestAnimationFrame(() => hide())}
                            salt={salt} />
                    </Container>
                )}
                wrapperClassName="widget"
                maxWidth="none"
                reference={toggleRef}>
                <div {...wrapperProps}>
                    {isValidElement(Trigger) ?
                        cloneElement(Trigger, {
                            ...triggerProps,
                            onClick: toggle,
                            ref: toggleRef
                        }) :
                        <ToggleButton
                            {...triggerProps}
                            onClick={toggle}
                            ref={toggleRef} />
                    }
                </div>
            </Tooltip>
            <Prompts
                {...prompting}
                actions={actions}
                setPrompting={setPrompting}
                salt={salt} />
            <Upgrade
                {...upgrading}
                dismiss={() => setUpgrading(null)}
                salt={`context-menu:upgrade:${salt}`} />
        </>
    )
}

export default ContextMenu

export const ToggleActionsButton = forwardRef(({ icon: Icon = MoreHorizontal, ...props }, ref) => (
    <Simple
        {...props}
        icon={Icon}
        ref={ref} />
))

export const getActionsArray = actions => {
    if(!actions) {
        return []
    }

    if(!Array.isArray(actions)) {
        actions = valuesOrdered(actions)
    }

    return compact(actions)
}

export const getBoundActions = (actions, context = {}) => {
    actions = getActionsArray(actions)

    return compact(actions.map(action => action(context)))
}

export const useEnrichedActions = ({ namedActions, context, icons, onAfter, onError, include }) => {
    const [loading, setLoading] = useState(reduce(namedActions, (accumulator, _, name) => ({
        ...accumulator,
        [name]: false
    })))

    const setNameLoading = (name, value) => setLoading(loading => ({
        ...loading,
        [name]: value
    }))

    const getDisabled = (except = []) => Object.values(omit(loading, ...except)).some(Boolean)

    // This is meant to be a system for generic, widely used actions,
    // and not for adding icons to any super-specific action
    const supportedActions = [
        'edit',
        'toggleCompleted',
        'endNow',
        'addToCalendar',
        'manageDocumentation',
        'createMeeting',
        'remove'
    ]

    const actions = compactObject(reduce(
        pick(namedActions, ...supportedActions),
        (accumulator, action, name) => ({
            ...accumulator,
            [name]: action?.(context)
        })
    ))

    if(actions.edit) {
        actions.edit.icon = <Edit size={24} />
        actions.edit.disabled = actions.edit.disabled || getDisabled()
    }

    if(actions.toggleCompleted) {
        const { onClick } = actions.toggleCompleted

        actions.toggleCompleted.disabled = actions.toggleCompleted.disabled || getDisabled(['toggleCompleted'])
        actions.toggleCompleted.loading = loading.toggleCompleted

        actions.toggleCompleted.onClick = async () => {
            setNameLoading('toggleCompleted', true)
            const { ok, response } = await onClick()
            setNameLoading('toggleCompleted', false)

            if(ok) {
                onAfter?.toggleCompleted?.(response)
            } else {
                onError?.toggleCompleted?.(response)
            }
        }
    }

    if(actions.endNow) {
        const { onClick } = actions.endNow

        actions.endNow.icon = <Stop size={24} />
        actions.endNow.disabled = actions.endNow.disabled || getDisabled(['endNow'])
        actions.endNow.loading = loading.endNow

        actions.endNow.onClick = async () => {
            setNameLoading('endNow', true)

            const { ok, response } = await onClick()

            if(ok) {
                await onAfter?.endNow?.(response)
            } else {
                await onError?.endNow?.(response)
            }

            setNameLoading('endNow', false)
        }
    }

    if(actions.addToCalendar) {
        actions.addToCalendar.icon = <Calendar size={24} />
        actions.addToCalendar.disabled = actions.addToCalendar.disabled || getDisabled()
    }

    if(actions.manageDocumentation) {
        actions.manageDocumentation.icon = <Attachment size={24} />
        actions.manageDocumentation.disabled = actions.manageDocumentation.disabled || getDisabled()
    }

    if(actions.createMeeting) {
        actions.createMeeting.icon = <Meetings size={24} />
        actions.createMeeting.disabled = actions.createMeeting.disabled || getDisabled()
    }

    if(actions.remove) {
        const { onClick } = actions.remove

        actions.remove.icon = <Delete size={24} />
        actions.remove.disabled = actions.remove.disabled || getDisabled(['remove'])
        actions.remove.loading = loading.remove

        actions.remove.onClick = async () => {
            setNameLoading('remove', true)

            const { ok, response } = await onClick()

            if(ok) {
                await onAfter?.remove?.(response)
            } else {
                await onError?.remove?.(response)
            }

            setNameLoading('remove', false)
        }
    }

    const enrichedActions = {
        ...actions,

        ...reduce(
            omit(namedActions, ...supportedActions),
            (accumulator, action, name) => {
                const bound = action?.(context)

                if(!bound) {
                    return accumulator
                }

                const icon = icons?.[name]

                return {
                    ...accumulator,
                    [name]: {
                        ...bound,
                        ...(icon ? { icon } : null),
                        disabled: getDisabled([name])
                    }
                }
            }
        )
    }

    return include ?
        pick(enrichedActions, ...include) :
        enrichedActions
}