import React, { useRef, useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { useRolesByUser } from 'contexts/roles-by-user'
import { useAccess } from 'contexts/access'
import { compact } from 'utilities/array'
import { getShortName } from 'utilities/person'
import { useGetRoleName } from 'pages/settings/pages/roles/utilities'
import { H2 as Heading } from 'components/typography/heading'
import Form from 'components/form/controller'
import PersonField from 'components/form/field/person'
import EntityField from 'components/form/field/entity'
import { SeePermissionsButton } from './s'
import OneOfField from 'components/form/field/one-of'
import EntitiesField from 'components/form/field/entities'
import Message from 'components/message'
import Paragraph from 'components/typography/paragraph'
import Link from 'components/link'
import WordList from 'components/word-list'
import PermissionsSummaryModal from 'pages/settings/pages/roles/pages/role/permissions-summary-modal'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const AssignGrant = ({ user = null, domain = 'user', onDone, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const { environment } = useEnvironment()

    const groupsRef = useRef()

    const { check } = useAccess()

    const manageOrganizationGrantsAccess = check('roles:manage')
    const manageGroupGrantsAccess = check('groups:grants:manage')
    const manageTeamGrantsAccess = check('teams:grants:manage')
    const manageLocationGrantsAccess = check('locations:grants:manage')

    const getRoleName = useGetRoleName()

    const [role, setRole] = useState(null)
    const [groups, setGroups] = useState([])
    const [influence, setInfluence] = useState((manageGroupGrantsAccess || manageTeamGrantsAccess || manageLocationGrantsAccess) ?
        'groups' :
        'everyone'
    )
    const [valid, setValid] = useState(false)
    const [preview, setPreview] = useState(false)
    const [granting, setGranting] = useState(false)

    const { addGrant } = useRolesByUser()

    useEffect(() => {
        setValid(
            role &&
            (
                (domain === 'user' && (!!groups.length || (influence === 'everyone'))) ||
                domain === 'organization'
            )
        )
    }, [role, domain, groups, influence])

    const grantRole = async (_, { reset }) => {
        setGranting(true)

        const { ok } = await addGrant({
            roleId: role.id,
            body: {
                domain,
                userIds: [user.id],
                ...((domain === 'user') ? {
                    groups: (influence === 'everyone') ?
                        [{ type: 'organization', id: '00112233-4455-6677-8899-aabbccddeeff' }] : // TODO: Remove stupid id
                        groups
                } : null)
            }
        })

        setGranting(false)

        if(ok) {
            reset()
            onDone?.({ user, role })
        }
    }

    salt = `${salt}:add`

    const groupsPickerTypes = (domain === 'user') ?
        compact([
            (manageGroupGrantsAccess || manageTeamGrantsAccess) && 'team',
            (manageGroupGrantsAccess || manageLocationGrantsAccess) && 'location'
        ]) :
        null

    return (
        <>
            <Heading>
                <FormattedMessage
                    id="grant_action_add"
                    defaultMessage="Add grant" />
            </Heading>
            <Form
                layout="vertical"
                onSubmit={grantRole}>
                {({ touched, trigger }) => (
                    <>
                        <PersonField
                            salt={salt}
                            label={false}
                            name="users"
                            field={{ value: user }}
                            enabled={false} />
                        <EntityField
                            salt={salt}
                            label={formatMessage({
                                id: 'grant_label_role_permissions',
                                defaultMessage: 'Gain this role’s permissions'
                            })}
                            name="roleId"
                            field={{
                                value: null,
                                required: true,
                                include: 'always'
                            }}
                            picker={{
                                heading: formatMessage({
                                    id: 'role_action_pick',
                                    defaultMessage: 'Pick role'
                                })
                            }}
                            entity={{
                                type: 'role',
                                path: '/roles',
                                params: {
                                    domain,
                                    assignable: true
                                }
                            }}
                            onChange={({ roleId: role }) => setRole(role)} />
                        {role && (
                            <SeePermissionsButton
                                onClick={() => setPreview(true)}
                                className="constructive">
                                <FormattedMessage
                                    id="role_permissions_action_see"
                                    defaultMessage="See permissions" />
                            </SeePermissionsButton>
                        )}
                        {(domain === 'user') && (
                            <OneOfField
                                salt={salt}
                                label={formatMessage({
                                    id: 'grant_label_over',
                                    defaultMessage: 'Over'
                                })}
                                name="influence"
                                field={{
                                    value: influence,
                                    include: 'always',
                                    options: [
                                        {
                                            value: 'groups',
                                            label: formatMessage({
                                                id: 'groups_members_of',
                                                defaultMessage: 'Members of teams and locations'
                                            }),
                                            disabled: !manageGroupGrantsAccess && !manageTeamGrantsAccess && !manageLocationGrantsAccess,
                                            controlRef: groupsRef,
                                            content: (
                                                <EntitiesField
                                                    salt={salt}
                                                    label={false}
                                                    name="groups"
                                                    field={{
                                                        value: groups,
                                                        include: 'always'
                                                    }}
                                                    picker={{
                                                        heading: formatMessage({
                                                            id: 'groups_action_pick',
                                                            defaultMessage: 'Pick groups'
                                                        }),
                                                        search: {
                                                            controlProps: {
                                                                placeholder: formatMessage({
                                                                    id: 'groups_action_find',
                                                                    defaultMessage: 'Find groups'
                                                                })
                                                            }
                                                        },
                                                        outer: false
                                                    }}
                                                    entity={{
                                                        type: 'group',
                                                        path: '/groups',
                                                        ...((groupsPickerTypes?.length === 1) ? {
                                                            params: {
                                                                types: groupsPickerTypes
                                                            }
                                                        } : null)
                                                    }}
                                                    onChange={({ groups }) => setGroups(groups)}
                                                    ref={groupsRef} />
                                            )
                                        },
                                        {
                                            value: 'everyone',
                                            label: formatMessage({
                                                id: 'noun_everyone',
                                                defaultMessage: 'Everyone'
                                            }),
                                            disabled: !manageOrganizationGrantsAccess,
                                            content: (
                                                <Message
                                                    type="info"
                                                    className="small"
                                                    message={formatMessage({
                                                        id: 'everyone_grant_information_intro',
                                                        defaultMessage: 'For bigger companies with delegated management, Huma recommends that you grant permissions over members of specific teams and locations, or use the Supervisor functionality.'
                                                    })}>
                                                    <Paragraph className="compact">
                                                        <FormattedMessage
                                                            id="everyone_grant_information_more"
                                                            defaultMessage="Read more about the <link>Principle of Least Privilege<\/link>, and why it matters."
                                                            values={{
                                                                link: chunks => (
                                                                    <Link
                                                                        href={`https://www.${environment.tld}/knowledge/principle_of_least_privilege`}
                                                                        target="_blank">
                                                                        {chunks}
                                                                    </Link>
                                                                )
                                                            }} />
                                                    </Paragraph>
                                                </Message>
                                            )
                                        }
                                    ]
                                }}
                                onChange={({ influence }) => setInfluence(influence)} />
                        )}
                        {(domain === 'user' && valid) && (
                            <Message
                                type={(influence === 'everyone') ?
                                    'warning' :
                                    'info'
                                }
                                message={formatMessage({
                                    id: (influence === 'everyone') ?
                                        'role_user_grant_everyone_summary' :
                                        'role_user_grant_summary',
                                    defaultMessage: (influence === 'everyone') ?
                                        'The role {role} will be granted to {people} over <strong>Everyone<\/strong>.' :
                                        'The role {role} will be granted to {people} over members of {groups}.'
                                }, {
                                    role: <strong>{getRoleName(role)}</strong>,
                                    people: getShortName(user),
                                    groups: (
                                        <WordList
                                            words={groups.map(({ name }) => name)}
                                            wordRenderer={name => <strong>{name}</strong>} />
                                    )
                                })} />
                        )}
                        {(domain === 'organization' && valid) && (
                            <Message
                                type="info"
                                message={formatMessage({
                                    id: 'role_organization_grant_summary',
                                    defaultMessage: 'The role {role} will be granted to {people} organization-wide.'
                                }, {
                                    role: <strong>{getRoleName(role)}</strong>,
                                    people: getShortName(user)
                                })} />
                        )}
                        <Actions className="compact">
                            <Plain
                                onClick={dismiss}
                                className="neutral">
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${granting ? ' loading' : ''}`}
                                disabled={!touched.length || !valid || granting}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_grant_access"
                                    defaultMessage="Grant access" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
            <PermissionsSummaryModal
                show={preview}
                domain={domain}
                id={role?.id}
                name={getRoleName(role)}
                onDone={() => setPreview(false)}
                dismiss={() => setPreview(false)}
                salt={salt} />
        </>
    )
}

export default AssignGrant