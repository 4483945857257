import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Description, Points, Meta } from './s'
import { Point, PointHeader, PointOrdinal, PointTitle } from 'pages/meetings/components/agenda'
import Divider from 'components/divider'
import Caption from 'components/typography/caption'

const PreviewTemplateDetails = ({ description, agenda, updatedAt, updatedBy, showCreatedBy = true, salt }) => (
    <>
        {!!description && <Description>{description}</Description>}
        {!!agenda?.length && (
            <Points className="small">
                {agenda.map((point, index) => {
                    const key = `${salt}:${point.id ?? index}`

                    return (
                        <Point key={key}>
                            <PointHeader>
                                <PointOrdinal>{index + 1}</PointOrdinal>
                                <PointTitle>{point.title}</PointTitle>
                            </PointHeader>
                        </Point>
                    )
                })}
            </Points>
        )}
        {showCreatedBy && (
            <>
                <Divider $size={24} />
                {(!updatedAt || !updatedBy) && (
                    <Caption className="compact">
                        <FormattedMessage
                            id="created_by_huma"
                            defaultMessage="Created by Huma" />
                    </Caption>
                )}
                {(!!updatedAt && updatedBy) && (
                    <Meta
                        type="updated"
                        by={updatedBy}
                        at={updatedAt} />
                )}
            </>
        )}
    </>
)

export default PreviewTemplateDetails
