import React, {
    createContext, useContext,
    forwardRef,
    Fragment
} from 'react'
import { cls } from 'utilities/dom'
import {
    ContainerQuery,
    CardListContainer, CardListHeader, CardListRow, CardListCell,
    SkeletonCell
} from './s'
import { SkeletonStrings, SkeletonAvatarAndFullName, SkeletonStatus } from 'components/skeleton'
import Ellipsify from 'components/ellipsify'
import Tooltip from 'components/tooltip'
import { ArrowSortedUp } from 'styled-icons/typicons'

const CardListContext = createContext()

const CardList = ({ name = 'card-list', breakpoint = 650, ...props }) => (
    <CardListContext.Provider value={{
        ...props,
        name,
        breakpoint
    }}>
        <ContainerQuery
            $name={name}
            $breakpoint={breakpoint}>
            <CardListContainer
                {...props}
                $name={name}
                $breakpoint={breakpoint} />
        </ContainerQuery>
    </CardListContext.Provider>
)

CardList.Header = props => {
    const {
        name,
        breakpoint
    } = useCardList()

    return (
        <CardListHeader
            {...props}
            $name={name}
            $breakpoint={breakpoint} />
    )
}

CardList.Row = forwardRef((props, ref) => {
    const {
        name,
        breakpoint
    } = useCardList()

    return (
        <CardListRow
            {...props}
            $name={name}
            $breakpoint={breakpoint}
            ref={ref} />
    )
})

CardList.Cell = ({ cell, className, ...props }) => {
    const {
        name,
        breakpoint
    } = useCardList()

    return (
        <CardListCell
            {...props}
            className={cls(className, cell)}
            $name={name}
            $breakpoint={breakpoint}
            $cell={cell} />
    )
}

CardList.Sortable = ({ cell, field, toggle, text, tooltip = null, sort, className, ...props }) => {
    const {
        name,
        breakpoint
    } = useCardList()

    const active = field === sort.by

    className = cls([
        className,
        sort.direction,
        'sortable',
        active && 'active'
    ])

    const [TextWrapper, textProps] = tooltip ?
        [Tooltip, {
            ...tooltip,
            ...(!tooltip.content ? { content: text } : null),
            delay: [0, 250],
        }]
        : [Fragment, null]

    return (
        <CardListCell
            {...props}
            className={className}
            $name={name}
            $breakpoint={breakpoint}
            $cell={cell}
            onClick={() => toggle(field)}>
            <TextWrapper {...textProps}>
                <Ellipsify text={text} />
            </TextWrapper>
            <ArrowSortedUp size={16} />
        </CardListCell>
    )
}

CardList.Loader = ({ loading = false, count = 5, items = [], ...props }) => {
    const { name } = useCardList()

    if(!loading) {
        return null
    }

    return [...Array(count).keys()].map(index => (
        <CardList.Row
            {...props}
            key={`${name}:skeleton:${index}`}>
            {items.map(item => {
                const {
                    type,
                    cell,
                    ...itemProps
                } = item

                const SkeletonItem = loaderToSkeletonMap?.[type]

                if(!SkeletonItem) return null

                return (
                    <SkeletonCell
                        $cell={cell}
                        key={`skeleton:${type}:${index}`}>
                        <SkeletonItem {...itemProps} />
                    </SkeletonCell>
                )
            })}
        </CardList.Row>
    ))

}

const loaderToSkeletonMap = {
    strings: SkeletonStrings,
    avatarAndFullName: SkeletonAvatarAndFullName,
    status: SkeletonStatus
}

export const useCardList = () => useContext(CardListContext)
export default CardList