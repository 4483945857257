import { useIntl } from 'react-intl'
import { capitalize } from 'utilities/string'
import {
    Key as Default,
    Shield as Locked
} from 'styled-icons/feather'
import { Subordinate as Supervisor } from 'components/feather'

export const getRoleIcon = role => {
    if(role.name === 'supervisor') {
        return Supervisor
    }

    if(role.updatable === false) {
        return Locked
    }

    return Default
}

export const useGetRoleName = () => {
    const { formatMessage } = useIntl()

    return role => {
        const roleName = role?.name ? formatMessage({
            id: `role_${role.name}_name`,
            defaultMessage: role?.deletable ? role.name : capitalize(role.name)
        }) : ''

        return roleName
    }
}

export const rolesOrder = [
    'admin',
    'deviation-handler',
    'whistleblowing-handler'
]