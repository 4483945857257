import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'

export const RoleWrapper = styled(Grid)`
    grid-template-rows:
        [icon-start name] 24px
        [meta] minmax(20px, auto) [icon-end];
    grid-template-columns:
        [icon] 40px
        [name meta] minmax(0, 1fr);
    column-gap: 16px;
`

export const IconWrapper = styled(Grid)`
    grid-area: icon;
    grid-template: 1fr / 1fr;
    place-items: center;

    block-size: 40px;
    inline-size: 40px;

    svg {
        ${svgStroke}

        grid-area: 1 / 1;
    }
`

export const Name = styled(Paragraph).attrs({ className: 'compact' })`
    grid-area: name;
`

export const Meta = styled(Paragraph).attrs({ className: 'caption small compact' })`
    grid-area: meta;
`