import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ValueDisplay } from './s'
import { Field, Label, Control } from 'components/form/field/s'

const ViewYepNopeField = ({ label, className, ...props }) => {
    const value = props.field?.value ?? null

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay {...(!value ? { className: 'empty' } : null)}>
                    {(value === true) && (
                        <FormattedMessage
                            id="boolean_true"
                            defaultMessage="Yes" />
                    )}
                    {(value === false) && (
                        <FormattedMessage
                            id="boolean_false"
                            defaultMessage="No" />
                    )}
                    {![true, false].includes(value) && (
                        <FormattedMessage
                            id="empty_not_chosen"
                            defaultMessage="Not chosen" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewYepNopeField