import React from 'react'
import {
    Corporation,
    Team,
    TeamPlus as AddTeam,
    LocationPlus as AddLocation,
    Jubilee,
    Subordinate,
    HandbookPin,
    Meetings,
    MeetingRound,
    Signature,
    ActivityLog,
    Integration,
    CompetenceProfiles,
    Deviation,
    Whistleblowing,
    AI,
    MoneyBag as Salary,
    MoneyBagReturn as SalaryRevision,
    SmartDocument,
    SmartDocumentTemplate,
    Survey
} from 'components/feather'
import {
    // Shared
    Share2 as Share,
    DownloadCloud as Export,
    UserX as Exclude,
    Clock,

    // Unique
    Briefcase as Subsidiaries,

    Grid as Dashboard,

    User as Person,
    Users as People,
    UserPlus as AddPerson,
    MapPin as Locations,
    Edit3 as CustomFields,
    UserCheck as DomainVerification,

    Book as Handbook,

    Calendar,
    Percent,

    MessageCircle as AgendaPoints,

    BookOpen as News,

    LogIn as Onboarding,
    LogOut as Offboarding,
    RotateCw as Processes,
    DownloadCloud as ImportExportTemplates,
    CheckCircle as ProcessesMultipleTemplates,

    File as Documents,

    Star as Competence,
    Target as CompetenceGap,

    Box as Equipment,

    CheckSquare as Tasks,
    CheckCircle as GroupTasks,

    TrendingUp as Insights,

    Smile as EmployeeSatisfaction,

    Settings,
    CreditCard as Subscription,
    ToggleRight as Modules,
    Key as Access,

    ArrowLeft as Back,

    HelpCircle as Help,
    Edit as ConfigureDashboard,
    Bell as NotificationSettings,
    Power as LogOut,

    Zap as Custom
} from 'styled-icons/feather'
import { Filter } from 'styled-icons/fluentui-system-regular'

const categoryToIconMap = {
    corporation: Corporation,
    subsidiaries: Subsidiaries,

    dashboard: Dashboard,

    person: Person,
    people: People,
    'add-person': AddPerson,
    teams: Team,
    team: Team,
    'add-team': AddTeam,
    locations: Locations,
    location: Locations,
    'add-location': AddLocation,
    subordinate: Subordinate,

    'organization-chart': Subordinate,
    jubilees: Jubilee,
    'custom-user-fields': CustomFields,
    'people-domain-signup': DomainVerification,
    salary: Salary,
    salaryRevision: SalaryRevision,
    'salary-revision': SalaryRevision,
    'salary-revision-reviewers': AddPerson,
    'salary-revision-by-supervisor': Subordinate,

    handbook: Handbook,
    'handbook-groups': Share,
    'handbook-featured': HandbookPin,
    'handbook-export': Export,

    absence: Clock,
    calendar: Calendar,
    'absence-graded': Percent,

    performance: Meetings,
    meetings: Meetings,
    meeting: Meetings,
    'meeting-templates': Custom,
    'meeting-templates-import-export': ImportExportTemplates,
    'meeting-rounds': MeetingRound,
    'meeting-agenda-points': AgendaPoints,

    news: News,
    article: News,
    'news-groups': Share,

    onboarding: Onboarding,
    'onboarding-import-templates': ImportExportTemplates,
    'onboarding-multiple-templates': ProcessesMultipleTemplates,

    offboarding: props => <Offboarding {...props} style={{ transform: 'rotate(180deg)' }} />,
    'offboarding-import-templates': ImportExportTemplates,
    'offboarding-multiple-templates': ProcessesMultipleTemplates,

    processes: Processes,
    'processes-import-templates': ImportExportTemplates,
    'processes-multiple-templates': ProcessesMultipleTemplates,

    documents: Documents,
    document: Documents,
    'digital-signing': Signature,
    'smart-documents': SmartDocument,
    'smart-document-templates': SmartDocumentTemplate,
    'smart-document-templates-import-export': ImportExportTemplates,

    competence: Competence,
    competenceRecord: Competence,
    competenceGap: CompetenceGap,
    'competence-profiles': CompetenceProfiles,
    'competence-gap': CompetenceGap,
    'competence-export': Export,

    equipment: Equipment,
    'equipment-import-export': Export,

    tasks: Tasks,
    task: Tasks,
    'group-tasks': GroupTasks,

    deviation: Deviation,

    insights: Insights,
    'insights-groups': Filter,
    'insights-exclude-people': Exclude,

    surveys: Survey,
    survey: Survey,
    employeeSatisfaction: EmployeeSatisfaction,
    'surveys-custom-schedule': Clock,
    'surveys-exclude-people': Exclude,

    whistleblowing: Whistleblowing,
    whistleblower: Whistleblowing,

    'activity-log': ActivityLog,

    integrations: Integration,

    settings: Settings,
    systemSettings: Settings,
    organization: Settings,
    help: Help,
    notificationSettings: NotificationSettings,
    configureDashboard: ConfigureDashboard,
    logOut: LogOut,

    subscription: Subscription,
    modules: Modules,
    'content-assistant': AI,

    accessroles: Access,
    roles: Access,
    role: Access,

    back: Back
}

export const getCategoryIcon = category => categoryToIconMap[category] ?? null

export const categoriesInOrder = [
    'organization',

    'corporation',
    'subsidiaries',

    'dashboard',

    'users',
    'people',
    'teams',
    'locations',
    'salary',
    'salary-revision',

    'handbook',

    'absence',

    'performance',
    'meetings',
    'meeting',
    'meeting-agenda-points',
    'meeting-templates',
    'meeting-templates-import-export',
    'meeting-rounds',

    'news',
    'articles',
    'article',

    'onboarding',
    'offboarding',
    'processes',

    'documents',
    'document',
    'smart-documents',
    'smart-document-templates',
    'smart-document-templates-import-export',

    'competence',
    'competenceRecord',
    'competence-profiles',
    'competenceGap',
    'competence-gap',
    'competence-export',

    'equipment',
    'equipment-import-export',

    'tasks',
    'task',

    'deviation',

    'insights',

    'surveys',
    'survey',
    'employeeSatisfaction',

    'whistleblowing',
    'whistleblower',

    'activityLog',
    'activity-log',

    'integrations',

    'settings',
    'systemSettings',
    'subscription',
    'customProfileFields',
    'modules',
    'jobTitles',
    'roles',
    'role',

    'subscription'
]

const processesImportTemplates = 'feature_employment_lifecycle_import_templates_heading'
const processesMultipleTemplates = 'feature_employment_lifecycle_multiple_templates_heading'

export const categoryToI18nMap = {
    // People
    'organization-chart': 'noun_org_chart',
    jubilees: 'dashboard_jubilees_heading',
    'custom-user-fields': 'profile_custom_fields_heading',
    'people-domain-signup': 'payment_plan_includes_general_domain_verification',

    // Salary
    'salary-revision-reviewers': 'salary_revision_label_reviewers',
    'salary-revision-by-supervisor': 'feature_salary_revision_by_supervisor',

    // Handbook
    'handbook-featured': 'handbooks_heading_pinned_topics',
    performance: 'noun_meetings',

    // Meetings
    'meeting-agenda-points': 'noun_agenda_points',
    'meeting-templates': 'noun_meeting_templates',
    'meeting-templates-import-export': 'feature_meetings_import_templates_heading',

    // News
    'news-groups': 'feature_news_groups',

    // Processes
    'onboarding-import-templates': processesImportTemplates,
    'onboarding-multiple-templates': processesMultipleTemplates,
    'offboarding-import-templates': processesImportTemplates,
    'offboarding-multiple-templates': processesMultipleTemplates,
    'processes-import-templates': processesImportTemplates,
    'processes-multiple-templates': processesMultipleTemplates,

    // Documents
    'digital-signing': 'documents_digital_signing_heading',
    'smart-documents': 'documents_smart_documents',
    'smart-document-templates': 'documents_smart_templates_noun',
    'smart-document-templates-import-export': 'feature_documents_smart_import_templates_heading',

    // Competence
    'competence-gap': 'noun_gap_analysis',
    'competence-profiles': 'feature_competence_profiles',
    'competence-export': 'feature_competence_export',

    // Equipment
    'equipment-import-export': 'feature_equipment_import_export',

    // Tasks
    'group-tasks': 'noun_group_tasks',

    // Insights
    'insights-groups': 'feature_insights_groups',
    'insights-exclude-people': 'feature_insights_exclude_people',

    // Surveys
    'surveys-custom-schedule': 'feature_surveys_custom_schedule',
    'surveys-exclude-people': 'feature_surveys_exclude_people',

    // Settings
    'content-assistant': 'feature_content_assistant'
}

export const nameToQuickGuideMap = key => {
    const map = {
        organization: null,
        dashboard: null,
        salary: 'salary-revision',
        performance: 'meetings',
        competenceGap: 'gap_analysis',
        integrations: null,
        process: 'processes'
    }

    if(key in map) {
        return map[key]
    }

    return key
}

export const quickGuideToNameMap = key => {
    const map = {
        meetings: 'performance',
        competenceGap: 'competence-gap'
    }

    if(key in map) {
        return map[key]
    }

    return key
}

export const enrichByName = name => {
    if(!name) {
        return
    }

    return ({
        type: name.split(':')[0],
        name: name.split(':')[1]
    })
}