import React from 'react'
import { useIntl } from 'react-intl'
import Message from 'components/message'

const ResultMessage = ({ success, error, meta, authorized }) => {
    const { formatMessage } = useIntl()

    if(!meta) {
        return null
    }

    return (
        <>
            {(success && !!meta.email) && (
                <Message
                    type="success"
                    message={formatMessage({
                        id: authorized ?
                            'person_emailaddress_change_success_authorized' :
                            'person_emailaddress_change_success',
                        defaultMessage: authorized ?
                            '<strong>{email}</strong> is now verified. All future emails from Huma (such as notifications of activity) will be sent to this address.\n\nIn order to continue using Huma, please log in again.' :
                            '<strong>{email}</strong> is now verified. All future emails from Huma (such as notifications of activity) will be sent to this address.\n\nPlease log in to continue using Huma.'
                    }, meta)} />
            )}
            {!!error && (
                <Message
                    type="error"
                    message={formatMessage(getError(error.code), meta)} />
            )}
        </>
    )
}

const knownErrors = {
    http_404: {
        id: 'person_emailaddress_change_error_canceled_or_already_verified',
        defaultMessage: '<strong>{email}</strong> has already been verified, or the change has been cancelled in the meantime.'
    },
    http_409: {
        id: 'person_emailaddress_change_error_duplicate',
        defaultMessage: 'Oops 😩 <strong>{email}</strong> is already in use. Ask an administrator to change your email address again.'
    },
    http_410: {
        id: 'person_emailaddress_change_error_expired',
        defaultMessage: 'This link has expired 😩 Ask an administrator to change your email address to <strong>{email}</strong> again.'
    }
}

knownErrors['identifier:duplicate'] = knownErrors.http_409
knownErrors.expired = knownErrors.http_410

const getError = code => knownErrors[code] ?? {
    id: 'person_emailaddress_change_error',
    defaultMessage: 'Something went wrong, and the new email address could not be verified. Please try again.'
}

export default ResultMessage