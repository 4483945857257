import React, { useRef, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Page from 'contexts/page'
import { usePerson } from 'contexts/person'
import { useTheme, ThemeProvider } from 'styled-components'
import { useUpgradable } from 'hooks/upgradable'
import { cls } from 'utilities/dom'
import { hasValue } from 'components/profile-completion'
import { ContainerQuery, Wrapper, Side, Main, HeadingColumns } from './s'
import Important from './important'
import Navigation, { getNavigationItems } from 'pages/people/pages/profile/components/navigation'
import Intro from './intro'
import Affiliation from './affiliation'
import Employment from './employment'
import Salary from './salary'
import Financial from './financial'
import Personalia from './personalia'
import Identifications from './identifications'
import Children from './children'
import EmergencyContacts from './emergency-contacts'
import Roles from './roles'
import { Plain } from 'components/button'
import UpgradeCTA from 'components/upgrade-cta'

const AboutPane = ({ lookingAtMyOwnProfile, access, customFieldsUpgradable, editingSection = null, setEditingSection, setLoading }) => {
    const theme = useTheme()

    const side = useRef()

    const { person } = usePerson()

    const [sideEmpty, setSideEmpty] = useState(true)

    useEffect(() => {
        if(!side?.current?.clientHeight === 0) {
            setSideEmpty(false)
        }
    }, [side?.current])

    const salt = 'profile:about'

    const getSectionProps = pepper => ({
        lookingAtMyOwnProfile,
        editingSection,
        setEditingSection,
        access: access?.[pepper],
        salt: `${salt}:${pepper}`
    })

    return (
        <ThemeProvider theme={{ ...theme, sideEmpty }}>
            <ContainerQuery>
                <Wrapper>
                    <Main>
                        <Intro {...getSectionProps('intro')} />
                        <Affiliation {...getSectionProps('affiliation')} />
                        <Employment {...getSectionProps('employment')} />
                        <Salary
                            {...getSectionProps('salary')}
                            userId={lookingAtMyOwnProfile ? 'me' : person.id} />
                        <Financial {...getSectionProps('financial')} />
                        <Personalia {...getSectionProps('personalia')} />
                        <Identifications {...getSectionProps('identifications')} />
                        <Children {...getSectionProps('children')} />
                        <EmergencyContacts {...getSectionProps('emergency-contacts')} />
                        <Roles {...getSectionProps('roles')} />
                        {customFieldsUpgradable && (
                            <UpgradeCTA
                                feature="custom-user-fields"
                                useUpgradeIcon
                                useFeatureOrModuleIcon
                                useFeatureTitle
                                useFeatureDescription
                                className="compact"
                                salt="custom-user-fields" />
                        )}
                    </Main>
                    <Side ref={side}>
                        <Important
                            access={access}
                            lookingAtMyOwnProfile={lookingAtMyOwnProfile}
                            setLoading={setLoading}
                            salt={salt} />
                        <Navigation
                            items={getNavigationItems(person, navigationItems.map(item => ({
                                ...item,
                                access: access?.[item.id]
                            })))}
                            salt={salt} />
                    </Side>
                </Wrapper>
            </ContainerQuery>
        </ThemeProvider>
    )
}

export const SectionHero = ({ editable, editing, toggleEditing, children, ...props }) => {
    const className = cls([
        editing && 'editing',
        editable && 'editable'
    ])

    return (
        <HeadingColumns
            {...props}
            {...(className ? { className } : null)}>
            {children}
            {!!editable && (
                <Plain
                    className="constructive"
                    onClick={() => toggleEditing(!editing)}>
                    <FormattedMessage
                        id={editing ?
                            'action_cancel' :
                            'action_edit'
                        }
                        defaultMessage={editing ?
                            'Cancel' :
                            'Edit'
                        } />
                </Plain>
            )}
        </HeadingColumns>
    )
}

const navigationItems = [
    {
        id: 'intro',
        fields: [
            'email',
            'phone',
            'interests',
            'funfacts',
            'birthDate'
        ]
    },
    {
        id: 'employment',
        fields: [
            'jobTitle',
            'jobDescription',
            'employmentType',
            'employmentPercentage',
            'employmentStartDate',
            'firstDayOfWork',
            'employmentEndDate',
            'lastDayOfWork',
            'employmentId'
        ]
    },
    {
        id: 'salary',
        fields: ['salary']
    },
    {
        id: 'financial',
        fields: ['bankAccount']
    },
    {
        id: 'personalia',
        fields: [
            'givenName',
            'preferredName',
            'familyName',
            'gender',
            'homeAddress',
            'nationality',
            'civilStatus',
            'dietaryRequirements'
        ]
    },
    {
        id: 'identifications',
        fields: ['identifications']
    },
    {
        id: 'children',
        fields: ['children']
    },
    {
        id: 'emergencycontacts',
        fields: ['emergencyContacts']
    },
    {
        id: 'roles',
        fields: ['roles']
    }
]

export const isFieldImportant = (profile, isMe) => field => {
    if(!isMe && !profile.completeness) {
        return null
    }

    const value = profile[field]?.value

    if(profile[field]?.completeness === 'important' && !hasValue(value) && !!profile.status?.active) {
        return { className: 'important' }
    }

    return null
}

export default ({ title, upgradable, lookingAtMyOwnProfile, ...props }) => {
    const customFieldsUpgradable = useUpgradable()({ feature: 'custom-user-fields' })

    return (
        <Page
            view={lookingAtMyOwnProfile ?
                'profile_me_about' :
                'profile_colleague_about'
            }
            title={title}
            upgrade={{
                enabled: upgradable || customFieldsUpgradable,
                passthrough: true
            }}>
            <AboutPane
                {...props}
                upgradable={upgradable}
                customFieldsUpgradable={customFieldsUpgradable}
                lookingAtMyOwnProfile={lookingAtMyOwnProfile} />
        </Page>
    )
}