import React, { Fragment } from 'react'
import { cls } from 'utilities/dom'
import { Wrapper, Avatar, AvatarWrapper, NoteWrapper, NoteContent } from './s'
import Tooltip from 'components/tooltip'
import Paragraph from 'components/typography/paragraph'
import { User } from 'styled-icons/feather'

const Note = ({ user = null, fakeUser = false, from = 'start', clamp = false, children, className }) => {
    className = cls([
        className,
        from
    ])

    const [ContentWrapper, contentProps] = clamp ?
        [Tooltip, {
            content: (
                <Paragraph className="compact small preserve-line-breaks">
                    {children}
                </Paragraph>
            )
        }]
        : [Fragment, null]

    return (
        <Wrapper className={className}>
            {user && (
                <Avatar
                    who={user}
                    size={24} />
            )}
            {(!user && fakeUser) && (
                <AvatarWrapper>
                    <User size={12} />
                </AvatarWrapper>
            )}
            <ContentWrapper {...contentProps}>
                <NoteWrapper>
                    <NoteContent className={cls([
                        'compact',
                        'preserve-line-breaks',
                        clamp && 'clamp'
                    ])}>
                        {children}
                    </NoteContent>
                </NoteWrapper>
            </ContentWrapper>
        </Wrapper>
    )
}

export default Note
