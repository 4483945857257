import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { MenuItemLink } from './'

const moduleIdentifier = 'settings'

const SystemSettings = props => {
    const {
        initialized,
        check
    } = useAccess()

    const access = initialized && [
        'users:manage',
        'users:custom-fields:manage',
        'roles:manage',
        'organization:manage'
    ]
        .map(check)
        .some(Boolean)

    return (
        <MenuItemLink
            {...props}
            to="settings.base"
            module={moduleIdentifier}
            access={access}
            upgradable={false}
            newlyAdded={true}
            className="xscender">
            <FormattedMessage
                id="noun_settings_system"
                defaultMessage="System settings" />
        </MenuItemLink>
    )
}

export default SystemSettings