import React from 'react'
import { usePendingSurveyRuns } from 'contexts/survey-pending-runs'
import { Wrap } from './s'
import PendingSurveyRun from './pending-survey-run'

const salt = 'survey-runs:pending'

const PendingSurveyRuns = ({ respond, className }) => {
    const { pendingSurveyRuns } = usePendingSurveyRuns()

    return (
        <Wrap {...(className ? { className } : null)}>
            {pendingSurveyRuns.map(pendingSurveyRun => {
                const key = `${salt}:${pendingSurveyRun.id}`

                return (
                    <PendingSurveyRun
                        pendingSurveyRun={pendingSurveyRun}
                        respond={respond}
                        salt={key}
                        key={key} />
                )
            })}
        </Wrap>
    )
}

export default PendingSurveyRuns