import React from 'react'
import { useParams, useSearchParams, Navigate } from 'react-router-dom'
import paths from 'app/paths'
import { getUrlFromPath } from 'utilities/url'

const PathRedirect = ({ path, getUrl, ...props }) => {
    const params = {
        ...props,
        ...useParams()
    }

    const [urlSearchParams] = useSearchParams()

    let to = path

    if(to !== paths.root) {
        to = getUrl ?
            (typeof getUrl() === 'function') ?
                getUrl(path)(params) :
                getUrl(params) :
            getUrlFromPath(path)(params)
    }

    if(urlSearchParams) {
        to = `${to}?${urlSearchParams}`
    }

    return <Navigate to={to} replace />
}

export default PathRedirect