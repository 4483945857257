import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { differenceInDays, endOfDay, isPast, isToday } from 'date-fns'
import { safeFormat, isofy } from 'utilities/date-time'
import { Message } from './s'

const CompleteAtStatus = ({ round, status, className }) => {
    const { dateLocale: locale } = useI18n()
    const { formatMessage } = useIntl()

    if(!round || !round?.dueAt || !['draft', 'open'].includes(status)) {
        return null
    }

    const { dueAt } = round
    const date = isofy(dueAt)

    const now = new Date()

    const daysInterval = differenceInDays(endOfDay(date), now)

    // Paste due date
    if(isPast(date) && !isToday(date)) {
        return (
            <Message
                type="error"
                message={formatMessage({
                    id: 'due_date_message_overdue',
                    defaultMessage: 'Should’ve been completed {days, plural, =0 {} =1 {one day} other {# days}} ago'
                }, { days: Math.abs(daysInterval) + 1 })}
                {...(className ? { className } : null)} />
        )
    }

    // Due date today
    if(isToday(date)) {
        return (
            <Message
                type="warning"
                message={formatMessage({
                    id: 'due_date_message_today',
                    defaultMessage: 'Should be completed today'
                })}
                {...(className ? { className } : null)} />
        )
    }

    // Complete within …
    return (
        <Message
            type={daysInterval > 7 ? 'info' : 'warning'}
            message={formatMessage({
                id: daysInterval > 7 ? 'due_date_message_date' : 'due_date_message_days',
                defaultMessage: daysInterval > 7 ? 'Complete by {date}' : 'Complete within {days, plural, =0 {} =1 {the next day} other {the next # days}}'
            }, {
                date: safeFormat(date, 'PP', { locale }),
                days: daysInterval
            })}
            {...(className ? { className } : null)} />
    )
}

export default CompleteAtStatus
