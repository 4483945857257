import React, { useState, useEffect, forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { cls } from 'utilities/dom'
import { compact } from 'utilities/array'
import { omit } from 'utilities/object'
import { categoryToMetaMap } from 'pages/competence/constants/category'
import { useCategories as useDocumentCategories } from 'pages/documents/utilities'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {
    EntityItem, EntityColumns, IconColumn, Symbol, NameColumn,
    CustomFieldTypeSymbol,
    ToggleButton, Drawer
} from './s'
import { FlexChildShrink } from 'components/flex'
import { Simple } from 'components/button'
import Ellipsify from 'components/ellipsify'
import { useGetRoleName } from 'pages/settings/pages/roles/utilities'
import Paragraph from 'components/typography/paragraph'
import Meta from 'components/meta'
import { getTypeDescriptionTranslation as geCustomProfileFieldTypeDescriptionTranslation } from 'pages/settings/pages/custom-profile-fields/components/type'
import { symbol as competenceSymbolFallback } from 'pages/competence'
import { symbol as equipmentSymbolFallback } from 'pages/equipment'
import LanguageLegislation from 'pages/documents/components/language-legislation'
import { ChevronDown as Chevron } from 'styled-icons/feather'

const Entity = forwardRef(({ entity, type, icon: Icon, iconColumn = false, picked, pick, isEmptyOption = false, isFiltering = false, salt }, ref) => {
    const { formatMessage } = useIntl()

    const categoryFormatter = useCategoryFormatter()
    const getRoleName = useGetRoleName()

    const { getCategoryLabel: getDocumentCategoryLabel } = useDocumentCategories()

    let {
        name,
        emoji = null,
        symbol = null,
        symbolSize = 40,
        entities = [],
        subName = '',
        className = ''
    } = entity

    const [expanded, setExpanded] = useState(!!entities?.length && picked?.id === entity.id)
    const [ready, setReady] = useState(picked?.id === entity.id)

    useEffect(() => {
        expanded && setReady(true)
    }, [expanded])

    useEffect(() => {
        if(isFiltering && !expanded) {
            setExpanded(true)
        }

        return () => {
            setExpanded(false)
            setReady(false)
        }
    }, [isFiltering])

    if(type === 'country') {
        emoji = getUnicodeFlagIcon(entity.id)

        if(!!entities.length) {
            subName = formatMessage({
                id: 'regions_count',
                defaultMessage: '{count, plural, =0 {No regions} =1 {1 region} other {{count} regions}}'
            }, { count: entities.length })
        }
    }

    if(type === 'customFieldType') {
        name = entity.label
    }

    if(type === 'competenceType') {
        name = categoryFormatter(entity)

        if(!symbol) {
            symbol = competenceSymbolFallback
        }

        className = cls([
            className,
            'large'
        ])

        if(entity?.category) {
            subName = formatMessage(categoryToMetaMap[entity.category].title)
        }
    }

    if(type === 'equipmentType' && !symbol) {
        symbol = equipmentSymbolFallback
    }

    if(type === 'absenceType') {
        name = formatMessage({
            id: `absence_type_${name}`,
            defaultMessage: name
        })
    }

    if(!!iconColumn) {
        className = cls([
            className,
            'sub-entity'
        ])
    }

    const isPicked = picked?.id === entity.id && !isEmptyOption

    return (
        <>
            <EntityItem
                className={className}
                ref={ref}>
                <EntityColumns>
                    {(type === 'customFieldType') && (
                        <CustomFieldTypeSymbol
                            type={entity.id}
                            size={40}
                            className="hover-effect" />
                    )}
                    {(!!Icon || !!emoji || !!symbol || !!iconColumn) && (
                        <IconColumn>
                            {!!Icon && <Icon size={20} />}
                            {emoji}
                            {!!symbol && (
                                <Symbol
                                    symbol={symbol}
                                    size={symbolSize}
                                    className="hover-effect" />
                            )}
                        </IconColumn>
                    )}
                    <NameColumn>
                        {(type !== 'role') && <Ellipsify text={name} />}
                        {(type === 'role') && <Ellipsify text={getRoleName(entity)} />}
                        {['onboardingTemplate', 'offboardingTemplate'].includes(type) && (
                            <Paragraph className="caption small compact">
                                <FormattedMessage
                                    id="tasks_count"
                                    defaultMessage="{count, plural, =0 {No tasks} =1 {One task} other {{count} tasks}}"
                                    values={{ count: entity.taskCount }} />
                                {!!entity.timeSpanDays && (
                                    <>
                                        {' · '}
                                        <FormattedMessage
                                            id="days_count"
                                            defaultMessage="{count, plural, =0 {0 days} =1 {1 day} other {{count} days}}"
                                            values={{ count: entity.timeSpanDays }} />
                                    </>
                                )}
                            </Paragraph>
                        )}
                        {(type === 'customFieldType') && (
                            <Paragraph className="caption small compact">
                                <Ellipsify text={formatMessage(geCustomProfileFieldTypeDescriptionTranslation({ type: entity.id }))} />
                            </Paragraph>
                        )}
                        {(type === 'smartTemplate') && (
                            <>
                                {entity?.language && (
                                    <LanguageLegislation
                                        className="ellipsify"
                                        {...entity} />
                                )}
                                <Paragraph className="caption small compact">
                                    {getDocumentCategoryLabel(entity.category)}
                                </Paragraph>
                                {!!entity.providedBy && (
                                    <Meta
                                        type="provided"
                                        by={entity.providedBy.name}
                                        link={false}
                                        className="compact small" />
                                )}
                            </>
                        )}
                        {!!subName && (
                            <Paragraph className="caption small compact">
                                <Ellipsify text={subName} />
                            </Paragraph>
                        )}
                    </NameColumn>
                    <FlexChildShrink>
                        {!entities.length && (
                            <Simple
                                className="constructive"
                                disabled={isPicked || entity?.disabled}
                                onClick={() => pick(entity)}>
                                <FormattedMessage
                                    id={isPicked ?
                                        'noun_picked' :
                                        'action_pick'
                                    }
                                    defaultMessage={isPicked ?
                                        'Picked' :
                                        'Pick'
                                    } />
                            </Simple>
                        )}
                        {!!entities.length && (
                            <ToggleButton
                                onClick={() => setExpanded(expanded => !expanded)}
                                icon={<Chevron size={20} />}
                                {...(expanded ? { className: 'expanded' } : null)} />
                        )}
                    </FlexChildShrink>
                </EntityColumns>
            </EntityItem>
            {!!entities.length && (
                <Drawer {...(expanded ? { className: 'expanded' } : null)}>
                    {!!ready && (
                        <>
                            <Entity
                                entity={{
                                    ...omit(entity, 'entities'),
                                    ...(subNameMap[type] ? { subName: `(${formatMessage(subNameMap[type])})` } : null)
                                }}
                                type={entity.entities[0].type}
                                iconColumn={true}
                                picked={picked}
                                pick={pick}
                                salt={salt}
                                key={compact([
                                    salt,
                                    entity.id,
                                    (picked?.id === entity.id) && 'picked'
                                ]).join(':')} />
                            {entities.map(subEntity => (
                                <Entity
                                    entity={{
                                        ...subEntity,
                                        ...(subEntity.name === entity.name ? { subName: `(${formatMessage(subNameMap[subEntity.type])})` } : null)
                                    }}
                                    type={subEntity.type}
                                    iconColumn={true}
                                    picked={picked}
                                    pick={pick}
                                    salt={subEntity.id}
                                    key={compact([
                                        salt,
                                        'entities',
                                        subEntity.id,
                                        (picked?.id === subEntity.id) && 'picked'
                                    ]).join(':')} />
                            ))}
                        </>
                    )}
                </Drawer>
            )}
        </>
    )
})

const subNameMap = {
    country: {
        id: 'address_label_country',
        defaultMessage: 'Country'
    },
    region: {
        id: 'address_label_region',
        defaultMessage: 'Region'
    }
}

export default Entity
