import styled from 'styled-components'
import SymbolBase from 'components/symbol'
import { H2 } from 'components/typography/heading'
import { fontSerif } from 's'
import Paragraph from 'components/typography/paragraph'
import { Points as PointsBase } from 'pages/meetings/components/agenda'
import MetaBase from 'components/meta'

export const Symbol = styled(SymbolBase)`
    margin-bottom: 16px;
    width: 56px;
    height: 56px;

    font-size: 26px;
`

export const Heading = styled(H2)`
    ${fontSerif}

    margin-bottom: 0;

    line-height: 30px;
`

export const Description = styled(Paragraph)`
    font-size: 20px;
    line-height: 28px;

    ${Heading} + & {
        margin-top: 16px;
    }
`

export const Points = styled(PointsBase)`
    padding: 0;

    ${Heading} + & {
        margin-top: 16px;
    }
`

export const Meta = styled(MetaBase)`
    margin-top: 0;
`