import styled from 'styled-components'
import { bp, belowLaptop, belowTablet, fromLaptop, fromDesktop, print } from 'utilities/styled'
import { Columns, FlexChildShrink } from 'components/flex'
import BackLinkBase from 'components/link/back'
import { GlobalContainer as GlobalMessage } from 'components/message/s'
import { Main as GlobalWrapper } from 'app/layouts/private/s'
import { BackButton as BackButtonBase } from 'components/button'

export const Sticky = styled.div.attrs(({ className: 'sticky-menu' }))`
    position: sticky;
    isolation: isolate;
    z-index: 2;
    inset-block-start: var(--huma-global-main-layout-header-height);

    ${bp.between('laptop', 'desktop')`
        margin-block-end: 56px;
    `}

    ${belowLaptop`
        margin-block-end: 32px;
    `}

    ${print`
        display: none;
    `}
`

export const Menu = styled(Columns).attrs(() => ({ as: 'nav' }))`
    justify-content: space-between;

    border-radius: 8px 8px 0 0;
    height: var(--huma-global-sticky-menu-height);
    background-color: var(--huma-color-surface-default);

    &.dashed {
        /* border-bottom: 4px dashed var(--huma-color-border-default); */
        background-image: repeating-linear-gradient(
            90deg,
            var(--huma-color-border-default),
            var(--huma-color-border-default) 24px,
            transparent 24px,
            transparent 32px
        );
        background-size: 100% 4px;
        background-position: 0 100%;
        background-repeat: no-repeat;
    }

    ${fromLaptop`
        margin: calc(var(--huma-global-sticky-menu-height) * -1) -48px 24px -48px;
        width: calc(100% + 96px);
        padding: 16px 48px;
    `}

    ${fromDesktop`
        margin: -72px -88px 0 -88px;
        width: calc(100% + 176px);
        padding: 16px 88px;

        transition: border-radius .05s ease-in-out;
    `}

    ${bp.between('laptop', 'desktop')`
        ${GlobalMessage} ~ ${GlobalWrapper} & {
            margin-block-start: -40px;
        }
    `}

    ${belowLaptop`
        position: relative;
        inset-inline: 50%;

        margin: -40px -50vw 0;
        border-radius: 0;
        width: 100vw;
        padding: 8px 24px;
        box-shadow: 0 1px 0 var(--huma-color-border-default);
    `}
`

export const MenuColumns = styled(Columns)`
    --gap: 24px;

    align-items: center;
    column-gap: var(--gap);

    ${Sticky}.tight & {
        --gap: 16px;
    }
`

export const MenuColumn = styled(FlexChildShrink)`
    &.from-tablet {
        ${belowTablet`
            display: none;
        `}
    }
`

export const BackLink = styled(BackLinkBase)`
    margin-block-end: 0;
    margin-inline-start: 0;
    padding-inline: 0;
`

export const BackButton = styled(BackButtonBase)`
    margin-block-end: 0;
`