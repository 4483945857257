import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'

export const Wrapper = styled.div`
    display: flex;
    column-gap: 8px;
    align-items: center;
`

export const Status = styled.div`
    isolation: isolate;
    position: relative;
    z-index: 1;

    cursor: help;

    border-radius: 8px;
    width: clamp(4px, 1.5cqi, 16px);
    height: 4px;
    background-color: var(--huma-color-surface-subtle);

    transition: background-color ease;

    &:hover {
        background-color: var(--huma-color-surface-subtle-hover);

        transition-duration: 0.2s;
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: -4px 0;
    }

    ${belowPhone`
        display: none;
    `}
`