import React, { Component } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { Toggler } from './s'
import { Field, Label, Control, Error } from 'components/form/field/s'
import DisplaySymbol from 'components/symbol'
import SymbolPicker from 'modals/symbol-picker'

const empty = null

class EditSymbol extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            picking: false
        }

        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = this.props?.field ?? {}

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                picking: false
            }),

            validator: value => required ?
                !!value :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    openPicker = () => this.setState({ picking: true })
    closePicker = () => this.setState({ picking: false })
    unset = () => this.onPicked(empty)

    onPicked = value => {
        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })

        // this.props.form.triggerChange(this.props.name)
    }

    render() {
        const {
            value = empty,
            picking
        } = this.state

        const {
            className,
            controlProps = {},
            symbolProps = {},
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            picker = {},
            formatMessage,
            error: outsideError,
            ...props
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = ((name in this.props.form.errors) && touched) || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const id = `${salt}:${name}`

        const {
            required,
            softRequired,
            optional,
            unsettable = true
        } = field

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    {(!!value?.emoji && !!value?.colorToken) && (
                        <>
                            <input
                                name={`${name}[emoji]`}
                                defaultValue={value?.emoji}
                                type="hidden"
                                key={`${id}:input:emoji:${value?.emoji ?? 'empty'}`} />
                            <input
                                name={`${name}[colorToken]`}
                                defaultValue={value?.colorToken}
                                type="hidden"
                                key={`${id}:input:color-token:${value?.colorToken ?? 'empty'}`} />
                        </>
                    )}
                    {(!value?.emoji || !value?.colorToken) && (
                        <input
                            name={name}
                            defaultValue=""
                            type="hidden"
                            key={`${id}:input:empty`} />
                    )}
                    <Toggler
                        {...controlProps}
                        {...omit(props, 'form', 'field', 'whistle')}
                        id={id}
                        onClick={this.openPicker}
                        active={picking}
                        unset={(!!value && unsettable) && this.unset}
                        unsettable={unsettable}
                        disabled={!enabled}
                        ref={this.props.forwardedRef}>
                        {!!value && (
                            <DisplaySymbol
                                symbol={value}
                                {...symbolProps} />
                        )}
                    </Toggler>
                    <SymbolPicker
                        show={picking}
                        salt={id}
                        label={label}
                        symbol={value}
                        {...picker}
                        dismiss={this.closePicker}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: this.closePicker
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'action_done',
                                defaultMessage: 'Done'
                            }),
                            effect: 'constructive',
                            onClick: () => this.onPicked(picked)
                        })}
                        key={`${id}:picker:${value}`} />
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                </Control>
            </Field>
        )
    }
}

export default props => {
    const { formatMessage } = useIntl()
    const form = useForm()

    return (
        <EditSymbol
            {...props}
            form={form}
            formatMessage={formatMessage} />
    )
}