import styled, { css, keyframes } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Naked } from 'components/button'

export const fadeGradient = keyframes`
    0% {
        fill: var(--huma-color-surface-constructive-bold);
        stroke: var(--huma-color-surface-constructive-bold);
    }

    25% {
        fill: var(--huma-color-surface-destructive-bold);
        stroke: var(--huma-color-surface-destructive-bold);
    }

    50% {
        fill: var(--huma-color-surface-warning-bold);
        stroke: var(--huma-color-surface-warning-bold);
    }

    75% {
        fill: var(--huma-color-surface-success-bold);
        stroke: var(--huma-color-surface-success-bold);
    }

    100% {
        fill: var(--huma-color-surface-brand-bold);
        stroke: var(--huma-color-surface-brand-bold);
    }
`

export const aiGradientCss = css`
    animation: ${fadeGradient} 2.5s linear infinite;

    &:nth-child(2) {
        animation-delay: 0.3s;
    }
`

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;

    margin-block-start: 16px;
`

export const Chip = styled(Naked)`
    display: flex;
    align-items: center;
    column-gap: 8px;

    border-radius: 4px;
    padding: 6px 8px;
    background-color: var(--huma-color-surface-minimal);

    transition: all .1s ease-in-out;
    will-change: background-color, color;

    font-size: 14px;
    line-height: 20px;

    svg {
        ${svgStroke()}
    }

    @media (prefers-reduced-motion: no-preference) {
        &.ai:not(:disabled):where(:hover, :focus-visible) svg path {
            ${aiGradientCss}
        }
    }

    &:where(:hover, :focus-visible) {
        background-color: var(--huma-color-surface-constructive-minimal);
        color: var(--huma-color-foreground-constructive-bold-active);
    }

    &:not(:active):focus-visible {
        outline: 2px solid var(--huma-color-border-constructive-focus);
        outline-offset: 1px;
    }

    @media (prefers-reduced-motion: no-preference) {
        &:focus-visible {
            transition: outline-offset 75ms ease-out;
        }

        &:not(:active):focus-visible {
            transition-duration: 0.1s;
        }
    }

    &:disabled {
        cursor: not-allowed;

        background-color: var(--huma-color-surface-disabled);

        color: var(--huma-color-foreground-on-disabled);
    }
`