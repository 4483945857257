import React, { Component, Fragment } from 'react'
import { useForm } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { Fieldset, Legend, Control } from 'components/form/field/s'
import { OptionColumns, RadiobuttonColumn, TextColumn, LabelText, LabelHelptext, UncapButton } from './s'
import Radiobutton from 'components/form/input/radiobutton'

const empty = null

class EditRadiobuttons extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            capped: !!props.cap
        }

        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => required ?
                !!value :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    select = option => this.setState({ value: option }, () => {
        this.props.onChange?.({ [this.props.name]: option.value })
    })

    unset = () => this.setState({
        value: empty,
        capped: !!this.props.cap
    })

    render() {
        const {
            value,
            capped
        } = this.state

        const {
            className,
            controlProps = {},
            field = {},
            label,
            name,
            options,
            enabled = true,
            cap = {},
            direction = 'ltr',
            framed = false,
            loading = false,
            salt
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldsetClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            controlProps?.className,
            framed && 'framed'
        ])

        const {
            required,
            softRequired,
            optional
        } = field

        const capAt = cap?.at ?? Infinity

        return (
            <Fieldset {...(fieldsetClassName ? { className: fieldsetClassName } : null)}>
                {!!label && (
                    <Legend
                        className="accent"
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Legend>
                )}
                <Control {...(controlClassName ? { className: controlClassName } : null)}>
                    {options.map((option, index) => {
                        if(capped && index >= capAt) {
                            return null
                        }

                        const [Text, textProps = null] = (typeof option.name === 'string') ?
                            [LabelText, { className: 'compact' }] :
                            [Fragment]

                        const checked = option.checked || value?.value === option.value
                        const disabled = !enabled || !!option?.disabled

                        const last = capped ?
                            index + 1 === capAt :
                            index + 1 === options.length

                        const optionClassName = cls([
                            className,
                            option.className,
                            disabled && 'disabled',
                            last && 'last'
                        ])

                        return (
                            <OptionColumns
                                {...(!!option?.onClick ? { onClick: option.onClick } : null)}
                                {...optionClassName ? { className: optionClassName } : null}
                                $direction={direction}
                                key={`${salt}:option:${option.value}:checked:${checked}`}>
                                <RadiobuttonColumn>
                                    <Radiobutton
                                        name={name}
                                        defaultValue={option.value}
                                        defaultChecked={checked}
                                        onChange={() => this.select(option)}
                                        onClick={event => {
                                            // Only trigger onClick for non-keyboard events
                                            if(event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
                                                option.onClick?.()
                                            }
                                        }}
                                        {...(disabled ? { disabled: true } : null)}
                                        controlProps={{ as: 'p' }}
                                        {...(!!option?.ref ? { ref: option.ref } : null)} />
                                </RadiobuttonColumn>
                                <TextColumn className={className}>
                                    <Text {...textProps}>{option.name}</Text>
                                    {!!option.helptext && (
                                        <LabelHelptext className="compact">{option.helptext}</LabelHelptext>
                                    )}
                                </TextColumn>
                            </OptionColumns>
                        )
                    })}
                    {(capped && capAt > 0 && options.length > cap.at) && (
                        <UncapButton
                            className="constructive"
                            onClick={() => this.setState({ capped: false })}>
                            {cap.text}
                        </UncapButton>
                    )}
                </Control>
            </Fieldset>
        )
    }
}

export default props => {
    const form = useForm()

    return (
        <EditRadiobuttons
            {...props}
            form={form} />
    )
}