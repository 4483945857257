import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { outpost, getUrl } from 'api'
import { pick } from 'utilities/object'
import { revokeAccess } from 'utilities/auth'
import { ModalHeading } from 'components/typography/heading'
import ResultMessage from './result-message'
import Label from 'components/typography/caption'
import Value from 'components/typography/paragraph'
import Actions from 'components/form/actions'
import { Button, Plain } from 'components/button'

const EmailVerificationContent = ({ meta, authorized, onResolve, dismiss }) => {
    const [verifying, setVerifying] = useState(false)
    const [result, setResult] = useState(null)

    useEffect(() => {
        if(authorized && result?.success) {
            const bounceOnRefresh = () => revokeAccess({ bounce: false })

            global.addEventListener('beforeunload', bounceOnRefresh)
            return () => global.removeEventListener('beforeunload', bounceOnRefresh)
        }
    }, [authorized, result])

    const verify = async () => {
        setVerifying(true)

        const {
            ok,
            response,
            status
        } = await outpost(getUrl('/users/verify-email'), { body: pick(meta, 'jwt') })

        setVerifying(false)

        const result = ok ?
            { success: true } :
            {
                error: {
                    code: response?.errorCode ?? `http_${status}`,
                    message: response?.errorMessage ?? response?.error_description ?? 'Unknown Error'
                }
            }

        setResult(result)

        onResolve?.({
            ...meta,
            ...result
        })
    }

    return (
        <>
            <ModalHeading>
                <FormattedMessage
                    id="person_emailaddress_change_verify_heading"
                    defaultMessage="Verify your new email address" />
            </ModalHeading>
            {!!result && (
                <ResultMessage
                    {...result}
                    meta={meta}
                    authorized={authorized} />
            )}
            {!result && (
                <>
                    <Label className="compact">
                        <FormattedMessage
                            id="person_emailaddress_change_verify_new_email_address_label"
                            defaultMessage="New email address" />
                    </Label>
                    <Value className="compact">{meta.email}</Value>
                    <Actions>
                        <Plain onClick={() => dismiss({ cancel: true })}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <Button
                            onClick={verify}
                            className={`constructive${verifying ? ' loading' : ''}`}
                            disabled={verifying}>
                            <FormattedMessage
                                id="action_verify"
                                defaultMessage="Verify" />
                        </Button>
                    </Actions>
                </>
            )}
            {!!result && (
                <Actions className="compact centered">
                    <Plain onClick={() => dismiss({ cancel: !!result?.error })}>
                        <FormattedMessage
                            id={!!result?.error ?
                                'action_close' :
                                'person_emailaddress_change_success_action_proceed'
                            }
                            defaultMessage={!!result?.error ?
                                'Close' :
                                'Proceed to login'
                            } />
                    </Plain>
                </Actions>
            )}
        </>
    )
}

export default EmailVerificationContent