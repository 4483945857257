import styled from 'styled-components'
import { fromTablet, belowTablet } from 'utilities/styled'
import Form from 'components/form/controller'
import { Grid } from 'components/grid'
import ActionsBase from 'components/form/actions'
import { Filter } from 'components/button'

export const Wrapper = styled(Form)`
    display: grid;
    grid-template-rows: subgrid;
    grid-row: header / content-end;
    grid-column: 1 / -1;

    transition: .2s ease;
    transition-delay: .2s;
    transition-property: opacity, transform;

    &:not(.running) {
        opacity: 0;

        ${fromTablet`
            transform: translateY(32px);
        `}

        ${belowTablet`
            transform: translateX(32px);
        `}
    }

    & > form {
        grid-row: content;
    }
`

export const Header = styled.div`
    position: sticky;
    top: 0;
    grid-row: header;

    margin-inline: auto;
    max-width: var(--huma-global-content-tight-width);
    width: 100%;
    padding-inline: 32px;
`

export const SymbolAndTitle = styled(Grid)`
    --hanging-symbol: 64px;

    grid-template-columns: 40px 1fr;
    column-gap: 24px;
    width: calc(100% + var(--hanging-symbol));
    position: relative;
    left: calc(var(--hanging-symbol) * -1);

    ${belowTablet`
        display: none;
    `}
`

export const Layout = styled.div`
    display: grid;
    grid-template-rows: 1fr max-content;

    height: 100%;

    &.centered {
        place-items: center;
    }
`

export const Questions = styled.div`
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    scroll-timeline: --survey-scroll block;

    display: flex;
    flex-direction: column;

    height: 100%;

    ${belowTablet`
        overflow-y: hidden;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-timeline: --survey-scroll inline;

        flex-direction: row;
    `}
`

export const Actions = styled(ActionsBase)`
    border-top: 1px solid var(--huma-color-border-default);
    padding: 16px 32px;
`

export const Feedback = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 8px;
    align-items: center;
    height: 100%;
`

export const FeedbackButton = styled(Filter)`
    padding-inline: 16px;
`

export const FeedbackCount = styled.span`
    display: inline-grid;
    place-items: center;

    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: var(--huma-color-surface-constructive-bold);

    font-size: 14px;
    color: var(--huma-color-foreground-on-bold);

    ${FeedbackButton}:disabled & {
        opacity: .6;
    }
`