import React, { forwardRef, useState, useCallback, useEffect } from 'react'
import { get } from 'api'
import { compact } from 'utilities/array'
import View from './view'
import Edit from './edit'

const SelectField = forwardRef(({ name, whistle, salt, ...props }, forwardedRef) => {
    const {
        field = {},
        mode = 'edit',
        options: initialOptions = [],
        optionsUrl,
        optionsFilter = () => true,
        optionsMapper
    } = props

    const { editable = true } = field

    const [options, setOptions] = useState(compact(initialOptions))
    const [value, setValue] = useState(null)
    const [ready, setReady] = useState(false)

    const fetchOptions = useCallback(async () => {
        if(optionsUrl) {
            setReady(false)

            let { response: options, ok } = await get({ path: optionsUrl })

            if(ok) {
                setOptions(compact(options.filter(optionsFilter).map(optionsMapper)))
                setReady(true)
            }
        }
    }, [optionsUrl, optionsMapper])

    useEffect(() => {
        setValue(unpackValue(field.value))
    }, [field.value, whistle])

    useEffect(() => {
        if(!!initialOptions?.length) {
            const options = compact(initialOptions)
            setOptions(options)

            if(!!value || value === 0) {
                setValue(unpackValue(value))
            }

            setReady(true)
        } else if(optionsUrl) {
            fetchOptions()
        } else setReady(true)
    }, [initialOptions.map(({ key }) => key).join(':'), optionsUrl])

    if(!ready) {
        return null
    }

    props = {
        ...props,
        field: {
            ...field,
            ...((mode === 'edit' && editable) ?
                { value } :
                { value: getDisplayValue(value, options) }
            )
        },
        name,
        options,
        forwardedRef,
        whistle,
        salt: `${salt}:${name}`
    }

    if(mode === 'edit' && editable) {
        return <Edit {...props} />
    }

    return <View {...props} />
})

export default SelectField

export const empty = {
    value: '',
    text: ''
}

const unpackValue = value => (!!value && typeof value === 'object') ?
    value.id :
    value

export const getDisplayValue = (value, options) => compact(options)
    .find(option => `${option.value}` === `${value}`)
    ?.text ?? ''
