import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import Paragraph from 'components/typography/paragraph'
import { Link } from './s'
import PermissionsSummaryModal from 'pages/settings/pages/roles/pages/role/permissions-summary-modal'

const SupervisorResponsibility = ({ salt }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const rolesManage = check('roles:manage')

    const [showPermissions, setShowPermissions] = useState(false)

    return (
        <>
            <Paragraph>
                <FormattedMessage
                    id="supervisor_responsibility_explainer"
                    defaultMessage="A supervisor is responsible for the employee and will have extended access to their profile. <link>See permissions</link>"
                    values={{
                        link: chunks => rolesManage ? (
                            <Link onClick={() => setShowPermissions(true)}>
                                {chunks}
                            </Link>
                        ) : null
                    }} />
            </Paragraph>
            <PermissionsSummaryModal
                show={showPermissions}
                domain="user"
                id="supervisor"
                name={formatMessage({
                    id: 'role_supervisor_name',
                    defaultMessage: 'Supervisor'
                })}
                onDone={() => setShowPermissions(false)}
                dismiss={() => setShowPermissions(false)}
                salt={salt} />
        </>
    )
}

export default SupervisorResponsibility