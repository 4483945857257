import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from 'components/grid'
import ParagraphBase from 'components/typography/paragraph'

export const Wrapper = styled(Grid)`
    position: relative;

    &.spaced {
        row-gap: 8px;
    }

    &:not(.compact) {
        margin-block-end: 24px;
    }
`

export const AnimatedParagraph = styled(motion(ParagraphBase)).attrs(() => ({
    variants: {
        contracted: {
            height: '48px'
        },
        expanded: {
            height: 'auto'
        }
    },
    transition: {
        duration: .25
    },
    initial: 'contracted'
}))`
    overflow: hidden;

    margin-block-end: 8px;
`

export const Paragraph = styled(ParagraphBase)`
    margin-block-end: 8px;

    word-break: break-word;
`