import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import AvatarBase from 'components/avatar'
import Paragraph from 'components/typography/paragraph'

export const Wrapper = styled.div`
    display: flex;
    flex-basis: 100%;
    column-gap: 8px;

    &.end {
        flex-direction: row-reverse;
    }
`

export const Avatar = styled(AvatarBase)`
    flex: 0 0 24px;
    height: 24px;
`

export const AvatarWrapper = styled.div`
    display: grid;
    place-items: center;
    grid-template: 1fr / 1fr;

    border-radius: 50%;
    block-size: 24px;
    aspect-ratio: 1;
    background-color: var(--huma-color-surface-minimal);

    svg {
        grid-area: 1 / 1;

        ${svgStroke()}
    }
`

export const NoteWrapper = styled.div`
    display: inline-block;
    align-self: flex-start;
    flex-basis: auto;

    padding: 8px 16px;
    background-color: var(--huma-color-surface-minimal);

    line-height: 0.5;

    ${Wrapper}.start & {
        border-radius: 0 16px 16px 16px;
    }

    ${Wrapper}.end & {
        border-radius: 16px 0 16px 16px;
    }

    ${Wrapper}.empty & {
        opacity: .5;
        border-radius: 16px;
    }
`

export const NoteContent = styled(Paragraph)`
    &.clamp {
        overflow: hidden;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
`