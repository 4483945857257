import styled from 'styled-components'
import { belowContainer } from 'utilities/styled'
import CardListBase from 'components/card-list'

export const containerProps = {
    name: 'roles-grants',
    breakpoint: 650
}

export const CardList = styled(CardListBase)`
    --__huma-component-roles-grants-grid:
        [name] minmax(0, 1fr)
        [roles] minmax(0, 1fr)
        [toggle] 24px;

    grid-template-columns: var(--__huma-component-roles-grants-grid);

    ${belowContainer(containerProps)`
        --__huma-component-roles-grants-grid:
            [name] minmax(0, 1fr)
            [roles] max-content
            [toggle] 24px;

        display: grid;
        column-gap: 16px;
    `}
`

export const CardListLoader = styled(CardList.Loader)`
    ${belowContainer(containerProps)`
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
        column-gap: 16px;
    `}
`