import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import MeetingEventsProvider, { useMeetingEvents } from 'contexts/meeting-events'
import TemplateProvider from 'pages/meetings/components/template-provider'
import { useMe } from 'contexts/me'
import { useNavigate } from 'react-router-dom'
import { getListRepresentationFromProfile, getFullName } from 'utilities/person'
import { omit, pick, size } from 'utilities/object'
import { compact } from 'utilities/array'
import { getMeetingEventUrl } from 'utilities/url'
import { Label, QuickSelectButton } from './s'
import { H2 as Heading } from 'components/typography/heading'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import PersonField from 'components/form/field/person'
import PeopleField from 'components/form/field/people'
import { Helper } from 'components/form/field/s'
import MeetingTemplateItem from 'pages/meetings/components/meeting-template-item'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { Field } from 'components/form/field/s'
import { Scrollable as Modal } from 'modals/generic'
import PreviewTemplate from 'pages/meetings/modals/template/preview'

const AddMeetingContent = ({
    setSelectingTemplate, profile = null, concerns = null,
    template = null, templateLocked = false,
    onDone, salt
}) => {
    const { formatMessage } = useIntl()

    const { createEvent } = useMeetingEvents()

    const {
        me,
        isItMyOwnId
    } = useMe()

    const navigate = useNavigate()

    const [creating, setCreating] = useState(false)
    const [previewing, setPreviewing] = useState(null)

    const [concernsUser, setConcernsUser] = useState(null)
    const [attendees, setAttendees] = useState(compact([profile]))

    useEffect(() => {
        if(concerns || profile) {
            setConcernsUser(concerns || profile)
        }
    }, [concerns, profile])

    const update = async body => {
        setCreating(true)

        body = {
            ...omit(body, 'attendees'),
            ...pick(template, 'description', 'symbol', 'agenda'),
            ...(!!body?.attendees?.length ? {
                attendees: body.attendees.map(({ id }) => id)
            } : null),
            ...(!!body?.meetingHost ? {
                host: body.meetingHost
            } : null)
        }

        const { ok, response } = await createEvent(body)

        if(ok) {
            navigate(getMeetingEventUrl({ id: response.id }))
            onDone?.()
        }

        setCreating(false)
    }

    return (
        <>
            <Heading>
                <FormattedMessage
                    id="meeting_action_add"
                    defaultMessage="New meeting" />
            </Heading>
            <Form
                layout="vertical"
                onSubmit={update}>
                {({ touched, values, errors, trigger, triggerChange }) => (
                    <>
                        <Field>
                            <Label
                                htmlFor="meeting-template"
                                {...(!templateLocked ? { onClick: () => setSelectingTemplate(true) } : null)}
                                required={true}>
                                <FormattedMessage
                                    id="noun_meeting_template"
                                    defaultMessage="Meeting template" />
                            </Label>
                            <MeetingTemplateItem
                                id="meeting-template"
                                template={template}
                                preview={() => setPreviewing(template)}
                                {...(!templateLocked ? {
                                    action: {
                                        label: formatMessage({
                                            id: 'action_change',
                                            defaultMessage: 'Change'
                                        }),
                                        onClick: () => setSelectingTemplate(true)
                                    }
                                } : null)} />
                        </Field>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'meeting_label_name',
                                defaultMessage: 'Meeting name'
                            })}
                            name="title"
                            field={{
                                value: template?.id ? template.name : '',
                                required: true,
                                include: 'always'
                            }} />
                        <PersonField
                            salt={salt}
                            label={formatMessage({
                                id: 'label_regarding',
                                defaultMessage: 'Regarding'
                            })}
                            name="concerns"
                            field={{
                                value: (concerns ?? profile),
                                include: (concerns || profile) ? 'always' : 'touched'
                            }}
                            enabled={!profile}
                            picker={{ outer: false }}
                            onChange={({ concerns }) => setConcernsUser(concerns)} />
                        <Helper>
                            <FormattedMessage
                                id="meeting_regarding_helper"
                                defaultMessage="Is the meeting about a specific person?" />
                        </Helper>
                        <PersonField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_facilitator',
                                defaultMessage: 'Meeting host'
                            })}
                            name="meetingHost"
                            field={{
                                value: getListRepresentationFromProfile(me),
                                include: 'always'
                            }}
                            enabled={false}
                            picker={{ outer: false }} />
                        <Helper>
                            <FormattedMessage
                                id="meeting_facilitator_helper"
                                defaultMessage="You will be the meeting host" />
                        </Helper>
                        <PeopleField
                            salt={salt}
                            className="compact"
                            label={formatMessage({
                                id: 'noun_attendees',
                                defaultMessage: 'Attendees'
                            })}
                            name="attendees"
                            field={{
                                value: attendees,
                                include: profile ? 'always' : 'touched'
                            }}
                            picker={{ outer: false }}
                            entity={{ params: { excludeIds: ['me'] } }}
                            onChange={({ attendees }) => {
                                setAttendees(attendees)
                                global.setTimeout(() => triggerChange('attendees'), 50) // Let attendees remount due to key change
                            }}
                            onUnset={() => {
                                setAttendees([])
                                global.setTimeout(() => triggerChange('attendees'), 50) // Let attendees remount due to key change
                            }}
                            key={[
                                salt,
                                'attendees',
                                attendees.map(({ id }) => id).join('+') ?? 'empty'
                            ].join(':')} />
                        {!!values?.concerns && !isItMyOwnId(values.concerns) && concernsUser?.status?.active !== false && !attendees?.some(({ id }) => id === values?.concerns) && (
                            <QuickSelectButton
                                onClick={() => {
                                    setAttendees(previousAttendees => [
                                        ...previousAttendees,
                                        concernsUser
                                    ])
                                    global.setTimeout(() => triggerChange('attendees'), 50) // Let attendees remount due to key change
                                }}>
                                <FormattedMessage
                                    id="action_add_user"
                                    defaultMessage="Add {name}"
                                    values={{ name: getFullName(concernsUser) }} />
                            </QuickSelectButton>
                        )}
                        <Actions>
                            <Plain
                                onClick={onDone}
                                className="neutral"
                                disabled={creating}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${creating ? ' loading' : ''}`}
                                disabled={(!touched.length && !template) || !!size(errors)}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_continue"
                                    defaultMessage="Continue" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
            <Modal
                show={!!previewing}
                dismiss={() => setPreviewing(null)}
                salt={`${salt}:preview`}>
                <PreviewTemplate
                    {...previewing}
                    dismiss={() => setPreviewing(null)}
                    salt={`${salt}:preview`} />
            </Modal>
        </>
    )
}

export default ({ provide = true, ...props }) => {
    if(provide) {
        return (
            <MeetingEventsProvider fetchOnMount={false}>
                <TemplateProvider {...props}>
                    <AddMeetingContent {...props} />
                </TemplateProvider>
            </MeetingEventsProvider>
        )
    }

    return <AddMeetingContent {...props} />
}