import React, { forwardRef } from 'react'
import View from './view'
import Edit from './edit'

const ContractableSelectField = forwardRef(({ field = {}, name, mode = 'edit', view = null, salt, ...props }, forwardedRef) => {
    const { editable = true } = field

    props = {
        ...props,
        field,
        name,
        salt: `${salt}:${name}`,
        forwardedRef
    }

    if(mode === 'edit' && editable) {
        return <Edit {...props} />
    }

    return (
        <View
            {...props}
            {...view} />
    )
})

export default ContractableSelectField