import React, {
    useState,
    Children, cloneElement, isValidElement
} from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { useI18n } from 'contexts/i18n'
import MeetingTemplatesProvider, { useMeetingTemplates } from 'contexts/meeting-templates'
import { map, pick } from 'utilities/object'
import { capitalize } from 'utilities/string'
import {
    Label, MeetingTemplateList,
    ShowAll, ShowAllCount, ShowAllButton
} from './s'
import { ModalHeader } from 'modals/generic'
import MeetingTemplateItem from 'pages/meetings/components/meeting-template-item'
import Form from 'components/form/controller'
import SelectField from 'components/form/field/select'
import UpgradeCTA from 'components/upgrade-cta'
import Loader from 'components/loader'
import { Scrollable as Modal } from 'modals/generic'
import PreviewTemplate from 'pages/meetings/modals/template/preview'
import { blankSymbol } from 'pages/meetings'

const templatesCut = 5

const TemplatePicker = ({
    setSelectingTemplate,
    setTemplate,
    dismiss = null,
    onDone, salt
}) => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    const {
        locale,
        locales
    } = useI18n()

    const {
        templates,
        total,
        templateLocales,
        humaTemplates,
        fetch,
        fetching,
        fetchHumaTemplates,
        fetchingHumaTemplates,
        hasFetchedHumaTemplates,
        loading,
        intersecter,
        paging = {},
        autoFetch
    } = useMeetingTemplates()

    const { hasNextPage } = paging

    const { checkFeature } = useAccess()
    const meetingTemplatesAvailable = checkFeature('meeting-templates')

    const upgradable = useUpgradable()({ feature: 'meeting-templates' })

    const [previewing, setPreviewing] = useState(null)
    const [showAllHumaTemplates, setShowAllHumaTemplates] = useState(false)

    if(!dismiss) {
        dismiss = onDone
    }

    const blankTemplate = {
        name: formatMessage({
            id: 'meeting_template_blank_name',
            defaultMessage: 'Blank meeting'
        }),
        symbol: blankSymbol
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'action_select_template',
                    defaultMessage: 'Select template'
                })}
                dismiss={dismiss} />
            {!!meetingTemplatesAvailable && (
                <>
                    <Label required={true}>
                        <FormattedMessage
                            id="noun_meeting_template"
                            defaultMessage="Meeting template" />
                    </Label>
                    <MeetingTemplateList>
                        <MeetingTemplateItem
                            template={blankTemplate}
                            action={{
                                label: formatMessage({
                                    id: 'action_select',
                                    defaultMessage: 'Select'
                                }),
                                onClick: () => {
                                    setTemplate(blankTemplate)
                                    setSelectingTemplate(false)
                                }
                            }} />
                        {templates?.map((template, index) => {
                            const last = index + 1 === templates.length

                            return (
                                <MeetingTemplateItem
                                    template={template}
                                    preview={() => setPreviewing(template)}
                                    action={{
                                        label: formatMessage({
                                            id: 'action_select',
                                            defaultMessage: 'Select'
                                        }),
                                        onClick: () => {
                                            setTemplate(template)
                                            setSelectingTemplate(false)
                                        }
                                    }}
                                    {...((!!intersecter && last) ? { ref: intersecter } : null)}
                                    key={template.id} />
                            )
                        })}
                        {!!hasNextPage && (
                            <ShowAll>
                                {(!loading && !autoFetch) && (
                                    <>
                                        <ShowAllCount>
                                            + <FormattedMessage
                                                id="templates_count"
                                                defaultMessage="{count, plural, =0 {} =1 {1 template} other {{count} templates}}"
                                                values={{
                                                    count: total - templates.length
                                                }} />
                                        </ShowAllCount>
                                        <ShowAllButton
                                            onClick={fetch}
                                            className="constructive"
                                            disabled={fetching}>
                                            <FormattedMessage
                                                id="action_show_all"
                                                defaultMessage="Show all" />
                                        </ShowAllButton>
                                    </>
                                )}
                                {!!loading && <Loader />}
                            </ShowAll>
                        )}
                    </MeetingTemplateList>
                </>
            )}
            {upgradable && (
                <UpgradeCTA
                    feature="meeting-templates"
                    useUpgradeIcon
                    useFeatureOrModuleIcon
                    useFeatureTitle
                    useFeatureDescription
                    className="spacious"
                    salt={salt} />
            )}
            <Label required={true}>
                <FormattedMessage
                    id="noun_huma_meeting_templates"
                    defaultMessage="Meeting templates created by Huma" />
            </Label>
            <Form>
                <SelectField
                    label={false}
                    name="locale"
                    field={{
                        value: templateLocales.includes(locale) ?
                            locale :
                            templateLocales[0],
                        allowEmptyOption: false
                    }}
                    options={map(pick(locales, ...templateLocales), ({ flag }, locale) => ({
                        key: locale,
                        value: locale,
                        text: `${flag} ${capitalize(formatDisplayName(locale, { type: 'language' }))}`
                    }))}
                    onChange={({ locale }) => {
                        setShowAllHumaTemplates(false)
                        fetchHumaTemplates(locale)
                    }} />
            </Form>
            <MeetingTemplateList>
                {humaTemplates
                    .slice(0, (showAllHumaTemplates ? Infinity : templatesCut))
                    .map((template, index) => {
                        const last = index + 1 === humaTemplates.length

                        return (
                            <MeetingTemplateItem
                                template={template}
                                preview={() => setPreviewing(template)}
                                action={{
                                    label: formatMessage({
                                        id: 'action_select',
                                        defaultMessage: 'Select'
                                    }),
                                    onClick: () => {
                                        setTemplate(template)
                                        setSelectingTemplate(false)
                                    }
                                }}
                                {...((!!intersecter && last) ? { ref: intersecter } : null)}
                                key={template.id} />
                        )
                    }
                )}
                {fetchingHumaTemplates && !hasFetchedHumaTemplates && <Loader />}
                {(humaTemplates?.length > templatesCut && !showAllHumaTemplates) && (
                    <ShowAll>
                        <ShowAllCount>
                            + <FormattedMessage
                                id="templates_count"
                                defaultMessage="{count, plural, =0 {} =1 {1 template} other {{count} templates}}"
                                values={{
                                    count: humaTemplates.length - templatesCut
                                }} />
                        </ShowAllCount>
                        <ShowAllButton
                            onClick={() => setShowAllHumaTemplates(true)}
                            className="constructive">
                            <FormattedMessage
                                id="action_show_all"
                                defaultMessage="Show all" />
                        </ShowAllButton>
                    </ShowAll>
                )}
            </MeetingTemplateList>
            <Modal
                show={!!previewing}
                dismiss={() => setPreviewing(null)}>
                <PreviewTemplate
                    {...previewing}
                    dismiss={() => setPreviewing(null)}
                    salt={salt} />
            </Modal>
        </>
    )
}

const TemplateProvider = ({
    template: initialTemplate,
    onDone, children, ...props
}) => {
    const [selectingTemplate, setSelectingTemplate] = useState(false)
    const [template, setTemplate] = useState(initialTemplate)
    const [templateLocked] = useState(!!initialTemplate)

    props = {
        ...props,
        selectingTemplate,
        setSelectingTemplate,
        template,
        setTemplate,
        templateLocked
    }

    if(!!selectingTemplate || !template) {
        return (
            <TemplatePicker
                {...props}
                dismiss={() => {
                    if(!template) {
                        onDone?.()
                    }

                    setSelectingTemplate(false)
                }} />
        )
    }

    return Children.map(children, child => {
        if(isValidElement(child)) {
            return cloneElement(child, props)
        }

        return child
    })
}

export default props => (
    <MeetingTemplatesProvider
        fetchHumaTemplatesOnMount={true}
        paging={{ limit: templatesCut - 1 }}>
        <TemplateProvider {...props} />
    </MeetingTemplatesProvider>
)