import React, { Component, createContext, createRef, useContext } from 'react'
import JsonData from 'form-serialize'
import { omit, each, reduce, size, filter, reject, get, set } from 'utilities/object'
import { prune, without } from 'utilities/array'
import { cls } from 'utilities/dom'
import debounce from 'lodash.debounce'
import { decode } from 'he'

const nonAttributeProps = [
    'json',
    'layout',
    'wait',
    'submitOnChange',
    'ignoreErrorsOnSubmit',
    'resetTouchedOnSubmit',
    'silenceEvents',
    'showing'
]

export const FormContext = createContext()

class Form extends Component {
    constructor(props) {
        super(props)

        this.form = createRef()
        this.trigger = createRef()

        this.state = {
            data: {},
            values: {},
            nested: {},
            meta: {},
            touched: [],
            fields: {},
            errors: {},
            submitted: false,
            lastReset: Date.now(),
            mode: props.mode ?? 'json',
            token: this.tokenize({}),
            trigger: this.trigger,

            serialize: this.serialize,
            submit: this.submit,
            reset: this.reset,
            resetTouched: this.resetTouched,
            resetValues: this.resetValues,
            registerField: this.registerField,
            unregisterField: this.unregisterField,
            triggerChange: this.updateOnChange
        }
    }

    componentDidMount() {
        this.clearIndividualFieldDebouncing = this.setupIndividualFieldDebouncing()

        this.setState({
            ...this.serialize(),
            errors: this.getErrors(),
            token: this.tokenize()
        })
    }

    componentDidUpdate(props, { token: previousToken }) {
        const token = this.tokenize()

        if(previousToken !== token) {
            this.setState({
                ...this.serialize(),
                errors: this.getErrors(),
                token
            })
        }
    }

    componentWillUnmount() {
        this.clearIndividualFieldDebouncing()
    }

    setupIndividualFieldDebouncing() {
        const { wait = {} } = this.props

        this.debouncedFields = reduce(wait, (accumulator, milliseconds, name) => ({
            ...accumulator,
            [name]: debounce((options = {}) => this.onAfterChange(name, options), milliseconds)
        }), {})

        return () => each(this.debouncedFields, ({ cancel }) => cancel?.())
    }

    serialize = (mode = 'all') => {
        const { current: form } = this.form

        const {
            fields = {},
            touched = []
        } = this.state

        const include = (mode === 'slim') ?
            getFieldsToInclude(fields, touched) :
            Object.keys(fields)

        return (this.state.mode === 'multipart') ?
            this.multipart(form, include) :
            this.json(form, include)
    }

    json = (form, include) => {
        if(!form) {
            return {}
        }

        // https://github.com/defunctzombie/form-serialize#options
        const options = {
            hash: true,
            empty: true,
            ...(this.props.json || {})
        }

        const data = JsonData(form, options)

        const { values, nested } = include.reduce((accumulator, name) => {
            let value = data[name]
            let levels = [name]

            if(name.includes('[') || name.includes(']')) {
                const bracketsRegex = /([\[|\]])/g

                levels = name
                    .split(bracketsRegex)
                    .filter(level => !!level && !bracketsRegex.test(level))

                value = get(data, levels.join('.'))
            }

            if(!value && value !== 0) {
                value = this.state.fields[name].empty
            }

            if(this.state.fields[name]?.type === 'boolean') {
                value = value === 'on'
            }

            if(this.state.fields[name]?.type === 'trilean') {
                if(['on', 'true', true].includes(value)) {
                    value = true
                } else if(['off', 'false', false].includes(value)) {
                    value = false
                } else {
                    value = null
                }
            }

            if(this.state.fields[name]?.type === 'number') {
                try {
                    value = !value ?
                        null :
                        Number(value)
                } catch(error) { // eslint-disable-line no-unused-vars
                    value = null
                }
            }

            if(['email', 'url'].includes(this.state.fields[name]?.type)) {
                value = value?.trim?.() ?? value
            }

            if(['email', 'url', 'select'].includes(this.state.fields[name]?.type)) {
                if(value === '') {
                    value = null
                }
            }

            if(this.state.fields[name]?.type === 'options-array') {
                value = Object.entries(value)
                    .filter(([value, label]) => !!label) // eslint-disable-line no-unused-vars
                    .map(([value, label]) => ({ value, label }))
            }

            if(this.state.fields[name]?.type === 'options-object') {
                value = Object.fromEntries(
                    Object.entries(value)
                        .filter(([key, value]) => !!value) // eslint-disable-line no-unused-vars
                )
            }

            return {
                values: {
                    ...accumulator.values,
                    [name]: value
                },
                nested: set(accumulator.nested, levels.join('.'), value)
            }
        }, {
            values: {},
            nested: {}
        })

        return {
            data: values,
            values,
            nested
        }
    }

    multipart = (form, include) => {
        const data = new FormData()

        if(!form) {
            return data
        }

        const all = new FormData(form)
        const values = {}

        include
            .map(name => [name, all.get(name)])
            .filter(([, value]) => !!value || value === 0)
            .forEach(([name, value]) => {
                if(value?.startsWith?.('encoded:')) {
                    value = decode(value.replace('encoded:', ''))
                }

                data.set(name, value)
                values[name] = value
            })

        return {
            data,
            values
        }
    }

    sanitize = data => {
        if(this.state.mode === 'multipart') {
            return data
        }

        return reduce(data, (accumulator, value, name) => {
            if(typeof value === 'string') {
                value = value.trim()
            }

            return {
                ...accumulator,
                [name]: value
            }
        }, {})
    }

    validate = () => {
        const { values } = this.serialize()

        const {
            fields,
            meta
        } = this.state

        return reduce(values, (accumulator, value, name) => ({
            ...accumulator,
            [name]: fields[name].validator(value, meta[name] ?? {})
        }), {})
    }

    // This one’s a bit weird... errors have the value `false`
    getErrors = () => reject(this.validate(), valid => valid)

    getSubmissible = () => {
        const {
            touched,
            errors
        } = this.state

        const { ignoreErrorsOnSubmit } = this.props
        const { current: trigger } = this.trigger

        const anyTouched = !!touched.length
        const anyErrors = !!size(errors)

        // Generic requirements if the trigger isn’t linked up
        if(!trigger) {
            return anyTouched && (!anyErrors || ignoreErrorsOnSubmit)
        }

        // Specific requirements as implemented in the view
        // represented by the trigger’s `disabled` attribute
        return !trigger.disabled
    }

    onChange = e => {
        if(this.props.silenceEvents) {
            e.preventDefault()
            e.stopPropagation()
        }

        this.updateOnChange(e.target.name)
    }

    updateOnChange = (name, options = {}) => {
        const {
            touched = true,
            meta
        } = options

        !!touched && this.addTouched(name)
        this.updateMeta(name, meta)

        this.setState({
            ...this.serialize(),
            errors: this.getErrors()
        }, () => {
            if(name in this.debouncedFields) {
                const debouncedOnAfterChange = this.debouncedFields[name]
                if(!debouncedOnAfterChange.pending) {
                    this.clearIndividualFieldDebouncing()
                }

                this.debouncedFields[name](options)
            } else {
                this.clearIndividualFieldDebouncing()
                this.onAfterChange(name, options)
            }
        })
    }

    onAfterChange = (name, options = {}) => {
        const { touched = true } = options
        !!touched && this.addTouched(name)

        const {
            onChange,
            submitOnChange,
            ignoreErrorsOnSubmit
        } = this.props

        const errors = this.getErrors()

        onChange?.(this.state.values, {
            errors,
            touched: this.state.touched,
            reset: this.reset,
            resetValues: this.resetValues,
            resetTouched: this.resetTouched,
            resetSubmitted: this.resetSubmitted,
        })

        if(submitOnChange && (!size(errors) || ignoreErrorsOnSubmit)) {
            this.submit()
        }
    }

    onSubmit = e => {
        e.preventDefault()
        e.stopPropagation()

        if(this.getSubmissible()) {
            this.submit()

            const { resetTouchedOnSubmit = false } = this.props
            resetTouchedOnSubmit && this.resetTouched()
        }
    }

    submit = () => {
        const { onSubmit } = this.props
        const { touched } = this.state

        if(onSubmit) {
            const {
                data,
                values,
                nested
            } = this.serialize('slim')

            onSubmit(this.sanitize(data), {
                values: this.sanitize(values),
                nested: this.sanitize(nested),
                reset: this.reset,
                resetValues: this.resetValues,
                resetTouched: this.resetTouched,
                resetSubmitted: this.resetSubmitted,
                errors: this.getErrors(),
                touched
            })

            this.setState({ submitted: true })
        }
    }

    addTouched = name => this.setState(({ fields, touched }) => {
        if(!Object.keys(fields).includes(name) || touched.includes(name)) {
            return null
        }

        return {
            touched: prune([
                ...touched,
                name
            ])
        }
    })

    removeTouched = name => this.setState(({ touched }) => ({
        touched: without(touched, [name])
    }))

    updateMeta = (name, meta) => this.setState(({ meta: previous }) => ({
        meta: !!meta ? {
            ...previous,
            [name]: meta
        } : omit(previous, name)
    }))

    reset = () => {
        this.unsetFields()

        this.setState({
            data: {},
            values: {},
            touched: [],
            errors: {},
            submitted: false,
            lastReset: Date.now()
        })
    }

    resetValues = () => {
        this.unsetFields()

        this.setState({
            data: {},
            values: {},
            errors: {},
            lastReset: Date.now()
        })
    }

    resetTouched = () => this.setState({
        touched: [],
        lastReset: Date.now()
    })

    resetSubmitted = () => this.setState({
        submitted: false,
        lastReset: Date.now()
    })

    unsetFields = () => each(this.state.fields, ({ unset }) => unset())

    registerField = async (name, { file = false, ...field }, update = false) => {
        // Wait 20 ms in order for any new fields to register after
        // any removed ones
        await new Promise(resolve => global.setTimeout(resolve, 20))

        this.setState(({ fields }) => ({
            fields: {
                ...fields,
                [name]: {
                    ...field,
                    file
                }
            },
            ...(!!file ? { mode: 'multipart' } : null)
        }), () => {
            if(update) {
                this.setState({
                    ...this.serialize(),
                    errors: this.getErrors()
                })
            }
        })
    }

    unregisterField = (name, file = false) => {
        this.setState(({ fields }) => {
            const update = {
                fields: omit(fields, name)
            }

            if(file && !size(filter(update.fields, ({ file }) => !!file))) {
                update.mode = 'json'
            }

            return update
        }, () => {
            this.removeTouched(name)
            this.setState({
                ...this.serialize(),
                errors: this.getErrors(),
                token: this.tokenize()
            })
        })
    }

    tokenize = (fields = this.state.fields) => Object
        .keys(fields)
        .sort()
        .join(':')

    render() {
        const {
            children = null,
            className,
            layout = 'horizontal',
            ...props
        } = this.props

        const formClassName = cls([
            className,
            layout
        ])

        return (
            <FormContext.Provider value={this.state}>
                <form
                    {...omit(props, ...nonAttributeProps)}
                    {...(formClassName ? { className: formClassName } : null)}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    ref={this.form}>
                    {(typeof children === 'function') && children(this.state)}
                    {(typeof children !== 'function') && children}
                </form>
            </FormContext.Provider>
        )
    }
}

export default Form

const getFieldsToInclude = (fields, touched) => reduce(fields, (accumulator, { include }, name) => {
    if(include === 'never') {
        return accumulator
    }

    if(!touched.includes(name) && include === 'touched') {
        return accumulator
    }

    return [
        ...accumulator,
        name
    ]
}, [])

export const useForm = () => useContext(FormContext)