import React, { useState, useEffect, useCallback, useRef, createRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSurvey } from 'contexts/survey'
import { useDirty } from 'contexts/modal'
import { useBreakpoint } from 'hooks/viewport'
import { getFallbackSymbol } from 'pages/surveys/utilities'
import debounce from 'lodash.debounce'
import { clamp } from 'utilities/math'
import { prune } from 'utilities/array'
import {
    Wrapper,
    Header, SymbolAndTitle,
    Layout, Questions, Actions,
    Feedback, FeedbackButton, FeedbackCount
} from './s'
import Symbol from 'components/symbol'
import { Title } from 'components/typography/heading'
import ProgressIndicator from 'components/progress-indicator'
import Question from './question'
import Submitted from './submitted'
import { Plain, Button, ButtonSubmit } from 'components/button'
import { ArrowLeft } from 'styled-icons/feather'

const RespondSurveyQuestions = ({
    started, answerable,
    currentQuestion, setCurrentQuestion,
    reachedQuestion, setReachedQuestion,
    visitedQuestions, setVisitedQuestions,
    onDone, salt
}) => {
    const {
        getResponseRun,
        respond
    } = useSurvey()

    const [, setDirty] = useDirty()
    const belowPhone = useBreakpoint()('phone')

    const source = getResponseRun()

    const {
        title,
        questions = []
    } = source

    const wrapperRef = useRef()
    const questionsRef = useRef([])
    questionsRef.current = questions.map((_, index) => questionsRef.current[index] ?? createRef())

    const [clickedNavigation, setClickedNavigation] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const scrollToIndex = useCallback(index => {
        const questionRef = questionsRef.current[index]
        if(!questionRef?.current) {
            return
        }

        questionRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })

        setClickedNavigation(true)
        setTimeout(() => setClickedNavigation(false), 800)
    }, [])

    useEffect(() => {
        // If the user has manually scrolled to a question, update the current question index
        if(!wrapperRef.current || !!submitted) {
            return
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    const toIndex = questionsRef.current.findIndex(ref => ref.current === entry.target)

                    const top = entry.intersectionRect.top
                    const bottom = entry.rootBounds.height - entry.intersectionRect.bottom
                    const direction = (top < bottom) ? 'up' : 'down'

                    if(!!~toIndex) {
                        setCurrentQuestion(toIndex)
                        setReachedQuestion(previouslyReachedQuestion => Math.max(previouslyReachedQuestion, toIndex))

                        // If scrolling up from the last question, all questions are visited
                        const questionsToSetVisited = (direction === 'up' && toIndex + 1 === questions.length - 1) ?
                            questions :
                            questions.slice(0, toIndex + (direction === 'down' ? 0 : 1))

                        setVisitedQuestions(visitedQuestions => prune([
                            ...visitedQuestions,
                            ...questionsToSetVisited.map(({ id }) => id)
                        ]))
                    }
                }
            })
        }, {
            root: wrapperRef.current,
            rootMargin: '0px',
            threshold: .5
        })

        questionsRef.current.forEach(ref => observer.observe(ref.current))
        return () => observer.disconnect()
    }, [wrapperRef, submitted])

    const scrollNext = useCallback(debounce(() => {
        let currentQuestion
        let nextQuestion
        let visited = false

        setCurrentQuestion(current => {
            currentQuestion = current

            nextQuestion = current + 1
            if(nextQuestion < questions.length) {
                const nextQuestionRef = questionsRef.current[nextQuestion]

                if(nextQuestionRef?.current) {
                    setTimeout(() => scrollToIndex(nextQuestion), 100)

                    visited = true
                    return nextQuestion
                }
            }

            nextQuestion = current
            return nextQuestion
        })

        setReachedQuestion(previouslyReachedQuestion => Math.max(previouslyReachedQuestion, nextQuestion))

        visited && setVisitedQuestions(visitedQuestions => prune([
            ...visitedQuestions,
            questions[currentQuestion].id
        ]))
    }, debounceOptions), [questions.length])

    const scrollPrevious = useCallback(debounce(() => {
        let currentQuestion
        let previousQuestion
        let visited = false

        setCurrentQuestion(current => {
            currentQuestion = current

            previousQuestion = current - 1
            if(previousQuestion >= 0) {
                const previousQuestionRef = questionsRef.current[previousQuestion]

                if(previousQuestionRef?.current) {
                    setTimeout(() => scrollToIndex(previousQuestion), 100)

                    visited = true
                    return previousQuestion
                }
            }

            previousQuestion = current
            return current
        })

        visited && setVisitedQuestions(visitedQuestions => prune([
            ...visitedQuestions,
            questions[currentQuestion].id
        ]))
    }, debounceOptions), [questions.length])

    const submit = async body => {
        if(!answerable) {
            return void setSubmitted(true)
        }

        const answers = questions.map(question => ({
            questionId: question.id,
            type: question.type,
            value: body[question.id]
        }))

        const { ok } = await respond({
            type: 'custom',
            answers
        }, source.id)

        if(ok) {
            setSubmitted(true)
            onDone?.(source)
        }
    }

    const getProgress = ({ fields, errors }) => {
        if(submitted) {
            return 100
        }

        const max = (reachedQuestion + 1) / questions.length * 100

        return clamp(
            100 - (Object.keys(errors).length / Object.keys(fields).length * 100),
            3, max
        )
    }

    const first = currentQuestion === 0
    const last = currentQuestion + 1 === questions.length

    return (
        <Wrapper
            layout="vertical"
            {...(started ? { className: 'running' } : { inert: 'true' })}
            onChange={() => !source.preview && setDirty(true)}
            onSubmit={submit}>
            {({ fields, errors, touched, trigger }) => {
                const feedback = questions
                    .slice(0, reachedQuestion + 1)
                    .map((question, index) => {
                        if(!(question.id in errors) || !visitedQuestions.includes(question.id)) {
                            return null
                        }

                        return {
                            ...question,
                            index,
                            ordinal: index + 1,
                            severity: (touched.includes(question.id) || questions.length === visitedQuestions.length) ?
                                'error' :
                                'inactive'
                        }
                    })
                    .filter(Boolean)

                const showPreviousButton = (!first && !!visitedQuestions.length) && !(feedback.length && belowPhone)

                return (
                    <>
                        <Header>
                            <SymbolAndTitle>
                                <Symbol
                                    symbol={source.symbol ?? getFallbackSymbol(source)}
                                    size={40} />
                                <Title>{title}</Title>
                            </SymbolAndTitle>
                            <ProgressIndicator
                                values={[getProgress({ fields, errors })]}
                                className="constructive" />
                        </Header>
                        {!submitted && (
                            <Layout>
                                <Questions ref={wrapperRef}>
                                    {questions.map((question, index) => (
                                        <Question
                                            question={question}
                                            proceed={() => {
                                                if(questions.length === visitedQuestions.length && !!feedback.length) {
                                                    let nextError = feedback[0].index
                                                    if(nextError === index) {
                                                        nextError = feedback[1]?.index
                                                    }

                                                    if(!!~nextError) {
                                                        setCurrentQuestion(nextError)
                                                        scrollToIndex(nextError)
                                                        return
                                                    }
                                                }

                                                !last && scrollNext()
                                            }}
                                            isCurrent={currentQuestion === index}
                                            clickedNavigation={clickedNavigation}
                                            index={index}
                                            salt={salt}
                                            ref={questionsRef.current[index]}
                                            key={`${salt}:question:${question.id ?? index}`} />
                                    ))}
                                </Questions>
                                <Actions className="spread">
                                    {(!showPreviousButton && !belowPhone) && <div />}
                                    {showPreviousButton && (
                                        <Plain
                                            onClick={scrollPrevious}
                                            icon={ArrowLeft}>
                                            <FormattedMessage
                                                id="action_previous"
                                                defaultMessage="Previous" />
                                        </Plain>
                                    )}
                                    {!!feedback.length && (
                                        <Feedback>
                                            <FeedbackButton
                                                onClick={() => {
                                                    setCurrentQuestion(feedback[0].index)
                                                    scrollToIndex(feedback[0].index)
                                                }}
                                                disabled={currentQuestion === feedback[0].index}>
                                                <FeedbackCount>{feedback.length}</FeedbackCount>
                                                <span>
                                                    <FormattedMessage
                                                        id="survey_feedback_label"
                                                        defaultMessage="Forgotten something?" />
                                                </span>
                                            </FeedbackButton>
                                        </Feedback>
                                    )}
                                    {!last && (
                                        <Button
                                            className="constructive"
                                            onClick={scrollNext}>
                                            <FormattedMessage
                                                id="action_next"
                                                defaultMessage="Next" />
                                        </Button>
                                    )}
                                    {!!last && (
                                        <ButtonSubmit
                                            className="constructive"
                                            disabled={!!Object.keys(errors).length || !!submitted}
                                            ref={trigger}>
                                            <FormattedMessage
                                                id="action_submit"
                                                defaultMessage="Submit" />
                                        </ButtonSubmit>
                                    )}
                                </Actions>
                            </Layout>
                        )}
                        {submitted && (
                            <Layout
                                className="centered"
                                ref={wrapperRef}>
                                <Submitted answerable={answerable} />
                            </Layout>
                        )}
                    </>
                )
            }}
        </Wrapper>
    )
}

const debounceOptions = { leading: true, trailing: false, maxWait: 500 }

export default RespondSurveyQuestions