import React, { useState, useEffect } from 'react'
import { useSurvey } from 'contexts/survey'
import { modalify, Wrapper as ModalWrapper, Veil, Modal } from 'utilities/modal'
import { cls } from 'utilities/dom'
import { Container, Wrapper } from './s'
import Hero from './hero'
import Intro from './intro'
import Content from 'pages/surveys/components/questions/respond'

const Respond = ({ show, setShow, answerable, onDone, salt }) => {
    const { respondRun } = useSurvey()

    const [started, setStarted] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [reachedQuestion, setReachedQuestion] = useState(0)
    const [visitedQuestions, setVisitedQuestions] = useState([])

    useEffect(() => {
        if(!show) {
            setStarted(false)
            setCurrentQuestion(0)
            setReachedQuestion(0)
            setVisitedQuestions([])
        }
    }, [show])

    const animate = show ? 'in' : 'out'

    return modalify(
        <ModalWrapper>
            {!!show && (
                <>
                    <Veil animate={animate} />
                    <Modal dismiss={() => setShow(false)}>
                        <Container
                            className={cls(['large', !respondRun && 'preview'])}
                            animate={animate}>
                            <Wrapper>
                                <Hero
                                    started={started}
                                    currentQuestion={currentQuestion} />
                                <Intro
                                    started={started}
                                    setStarted={setStarted} />
                                <Content
                                    started={started}
                                    currentQuestion={currentQuestion}
                                    setCurrentQuestion={setCurrentQuestion}
                                    reachedQuestion={reachedQuestion}
                                    setReachedQuestion={setReachedQuestion}
                                    visitedQuestions={visitedQuestions}
                                    setVisitedQuestions={setVisitedQuestions}
                                    answerable={answerable}
                                    onDone={onDone}
                                    salt={salt} />
                            </Wrapper>
                        </Container>
                    </Modal>
                </>
            )}
        </ModalWrapper>
    )
}

export default Respond