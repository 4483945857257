import { useState, useCallback, useEffect } from 'react'
import { getUserAgent } from 'utilities/user-agent'
import { numbers } from 'utilities/styled'

export const useSize = (options = {}) => {
    const { dimension = 'both' } = options

    const getSize = useCallback(() => {
        let {
            innerWidth: width,
            innerHeight: height
        } = global

        if(global?.visualViewport) {
            ({ width, height } = global.visualViewport)
        }

        return {
            width,
            height
        }
    }, [])

    const [width, setWidth] = useState(getSize().width)
    const [height, setHeight] = useState(getSize().height)

    const onResize = useCallback(() => requestAnimationFrame(() => {
        const resize = getSize()

        if(['both', 'width'].includes(dimension) && resize.width !== width) {
            setWidth(resize.width)
        }

        if(['both', 'height'].includes(dimension) && resize.height !== height) {
            setHeight(resize.height)
        }
    }), [dimension, width, height])

    useEffect(() => {
        global.addEventListener('resize', onResize)
        global?.visualViewport?.addEventListener('resize', onResize)

        return () => {
            global.removeEventListener('resize', onResize)
            global?.visualViewport?.removeEventListener('resize', onResize)
        }
    }, [])

    const size = {
        width,
        height
    }

    if(dimension === 'both') {
        return size
    }

    return size[dimension]
}

export const useScroll = () => {
    const [scrollTop, setScrollTop] = useState(document.documentElement.scrollTop)

    const onScroll = useCallback(() => requestAnimationFrame(() => {
        if(document.documentElement.scrollTop !== scrollTop) {
            setScrollTop(document.documentElement.scrollTop)
        }
    }), [scrollTop])

    useEffect(() => {
        global.addEventListener('scroll', onScroll)
        return () => global.removeEventListener('scroll', onScroll)
    }, [])

    return scroll
}

export const useTouch = () => {
    const [touch, setTouch] = useState(false)

    const onTouch = useCallback(() => {
        setTouch(true)
        global.removeEventListener('touchstart', onTouch)
    }, [])

    useEffect(() => {
        global.addEventListener('touchstart', onTouch)
        return () => global.removeEventListener('touchstart', onTouch)
    }, [])

    return touch
}

export const useCssVariables = () => {
    const height = useSize({ dimension: 'height' })
    const { os: { isIOS } } = getUserAgent()

    const updateIOSViewportCssVariables = useCallback(() => {
        if(isIOS) {
            const offsetY = global.visualViewport.height - global.innerHeight + global.visualViewport.offsetTop

            document.documentElement.style.setProperty('--viewport-height', `${global.visualViewport.height}px`)
            document.documentElement.style.setProperty('--window-height', `${global.innerHeight}px`)
            document.documentElement.style.setProperty('--offset-y', `${offsetY}px`)
            document.documentElement.style.setProperty('--viewport-scale', global.visualViewport.scale)
        }
    }, [height])

    useEffect(() => {
        updateIOSViewportCssVariables()
    }, [height])

    return null
}

export const useBreakpoint = () => {
    const width = useSize({ dimension: 'width' })

    return (breakpoint, options = {}) => {
        if(!(breakpoint in numbers)) {
            return false
        }

        const { from = false } = options

        if(from) {
            return width >= numbers[breakpoint]
        }

        // Default to below
        return width < numbers[breakpoint]
    }
}