import { useI18n } from 'contexts/i18n'
import { useIntl } from 'react-intl'
import { useDurationFormatter } from 'utilities/date-time'
import { safeFormat } from 'utilities/date-time'
import { surveyLabels, surveyClassNames } from 'pages/surveys/constants'

export const useSurveyStatus = () => {
    const { formatMessage } = useIntl()

    return status => {
        if(!status) {
            return null
        }

        status = status.toLowerCase()

        const label = surveyLabels[status] ?? null

        return {
            name: status,
            className: surveyClassNames[status] ?? 'neutral',
            label: label ?
                formatMessage(label)
                : null
        }
    }
}

export const useFormatSurveyRunTitle = () => {
    const { dateLocale: locale } = useI18n()
    return (run, format = 'PP') => safeFormat(run.createdAt, format, { locale })
}

export const useFormatWeekFrequency = () => {
    const { formatMessage } = useIntl()

    return (weeks, isDefault = false) => formatMessage({
        id: (weeks === 2) ?
            isDefault ?
                'employee_satisfaction_survey_value_every_other_week_default' :
                'employee_satisfaction_survey_value_every_other_week' :
            isDefault ?
                'employee_satisfaction_survey_value_every_weeks_default' :
                'employee_satisfaction_survey_value_every_weeks',
        defaultMessage: (weeks === 2) ?
            isDefault ?
                'Every other week (default)' :
                'Every other week' :
            isDefault ?
                '{count, plural, =0 {} =1 {Every week (default)} other {Every {count} weeks (default)}}' :
                '{count, plural, =0 {} =1 {Every week} other {Every {count} weeks}}'
    }, { count: weeks })
}

const customFallbackSymbol = {
    emoji: 'speech_balloon',
    colorToken: 'symbol-blue-1'
}

const enpsFallbackSymbol = {
    emoji: 'relaxed',
    colorToken: 'symbol-yellow-1'
}

export const getFallbackSymbol = survey => ({
    enps: enpsFallbackSymbol,
    custom: customFallbackSymbol
})?.[survey?.type] ?? customFallbackSymbol

const questionTypeToDuration = {
    text: 15,
    textarea: 30,
    radiobuttons: 15,
    checkboxes: 20,
    rating: 10,
    scale: 10,
    date: 20
}

export const estimateSurveyRunDuration = questions => questions.reduce((total, question) => (
    total + (questionTypeToDuration?.[question.meta.type] ?? 10)
), 0)

export const useFormatSurveyRunDuration = () => {
    const { formatMessage } = useIntl()
    const formatDuration = useDurationFormatter()

    return surveyRun => {
        const duration = estimateSurveyRunDuration(surveyRun.questions)

        return formatMessage({
            id: 'about_time',
            defaultMessage: 'About {time}'
        }, {
            time: formatDuration({
                minutes: Math.floor(duration / 60),
                ...((Math.floor(duration / 60) === 0) ? { seconds: duration % 60 } : null)
            })
        })
    }
}

export const useFriendlyFormatNextSurveyRunTime = () => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const defaultFormat = formatMessage({
        id: 'date_fns_format_weekday_date_time_friendly',
        defaultMessage: "EEEE PPP 'at' p"
    })

    return (survey, format = defaultFormat) => safeFormat(
        survey.nextRunTime,
        format,
        { locale }
    )
}

export const weeksInHours = weeks => Number(weeks) * 24 * 7
export const hoursInWeeks = hours => Number(hours) / 24 / 7