import { pick } from 'utilities/object'
import { cls } from 'utilities/dom'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import RadiobuttonsField from 'components/form/field/radiobuttons'
import CheckboxesField from 'components/form/field/checkboxes'
import RatingField from 'components/form/field/rating'
import YepNopeField from 'components/form/field/yep-nope'

export const typeToFieldMap = {
    text: StringField,
    textarea: TextField,
    radiobuttons: RadiobuttonsField,
    checkboxes: CheckboxesField,
    rating: RatingField,
    scale: RatingField,
    boolean: YepNopeField
}

export const getPropsByType = type => ({
    text: ({ props }) => ({
        ...(props ?? null),
        controlProps: {
            ...(props?.controlProps ?? null),
            maxLength: 2040
        }
    }),
    textarea: ({ props }) => ({
        ...(props ?? null),
        controlProps: {
            ...(props?.controlProps ?? null),
            maxLength: 10200
        }
    }),
    radiobuttons: ({ props }) => ({
        ...(props ?? null),
        options: props?.options ?? [],
        direction: 'rtl',
        framed: true
    }),
    checkboxes: ({ props }) => ({
        ...(props ?? null),
        field: {
            ...(props?.field ?? null),
            reversed: true
        },
        options: props?.options ?? [],
        framed: true
    }),
    rating: ({ props }) => {
        const min = props?.field?.min ?? 1
        const max = props?.field?.max ?? 5
        const total = max - min + 1

        return {
            ...(props ?? null),
            field: {
                ...(props?.field ?? null),
                min,
                max
            },
            controlProps: pick(props?.controlProps, 'placeholder', 'autoFocus', 'variant') ?? null,
            optionsClassName: cls([
                total >= 5 && 'spread',
                total <= 4 && 'large',
                (4 <= total && total <= 6) && 'medium'
            ])
        }
    },
    scale: ({ props }) => {
        const min = props?.field?.min ?? 1
        const max = props?.field?.max ?? 5
        const total = max - min + 1

        return {
            ...(props ?? null),
            field: {
                ...(props?.field ?? null),
                min,
                max
            },
            controlProps: pick(props?.controlProps, 'placeholder', 'autoFocus') ?? null,
            optionsClassName: cls([
                total >= 5 && 'spread',
                total <= 4 && 'large',
                (4 <= total && total <= 6) && 'medium'
            ])
        }
    },
    boolean: ({ props }) => ({
        ...(props ?? null)
    })
})[type] ?? (({ props }) => props)