import React, { forwardRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'components/form/controller'
import { pick } from 'utilities/object'
import { Wrapper, TitleWrapper, OrdinalAndTitle, Ordinal, Title, Field, Footer } from './s'
import { Required } from 'components/form/field/s'
import { Plain } from 'components/button'
import Paragraph from 'components/typography/paragraph'
import FieldPropsProvider from './field'

const Question = forwardRef(({
    question,
    proceed,
    index, isCurrent = false, clickedNavigation = false,
    salt
}, ref) => {
    const {
        fields,
        values
    } = useForm()

    const [whistle, setWhistle] = useState(Date.now())

    const field = fields?.[question.id]
    const value = values?.[question.id]

    const showUnsetButton = !question?.required && !!field?.unset
    const unsetButtonDisabled = !hasValue(value, question.type)

    let limitationText = null

    if(question.type === 'multiple_select') {
        if('min' in question && 'max' in question) {
            limitationText = {
                id: 'survey_multiple_select_limitation_both',
                defaultMessage: 'Select at least {min} and max {max} options.'
            }
        }

        if('min' in question && !('max' in question)) {
            limitationText = {
                id: 'survey_multiple_select_limitation_min',
                defaultMessage: '{min, plural, =0 {} =1 {Select at least 1 option.} other {Select at least {min} options.}}'
            }
        }

        if(!('min' in question) && 'max' in question) {
            limitationText = {
                id: 'survey_multiple_select_limitation_max',
                defaultMessage: 'Select up to {max} options.'
            }
        }
    }

    return (
        <Wrapper ref={ref}>
            <TitleWrapper>
                <OrdinalAndTitle>
                    <Ordinal {...(!isCurrent ? { className: 'inactive' } : null)}>
                        {index + 1}
                    </Ordinal>
                    <Title>
                        {question.title}
                        {!!question?.required && <Required>*</Required>}
                    </Title>
                </OrdinalAndTitle>
            </TitleWrapper>
            {question?.description && (
                <Paragraph className="caption compact">{question?.description}</Paragraph>
            )}
            <Field>
                <FieldPropsProvider
                    field={question}
                    proceed={proceed}
                    index={index}
                    isCurrent={isCurrent}
                    clickedNavigation={clickedNavigation}
                    whistle={whistle}
                    salt={salt} />
                {(!!question?.helperText || limitationText || showUnsetButton) && (
                    <Footer {...((!question?.helperText && !limitationText) ? { className: 'only-reset' } : null)}>
                        {(!!question?.helperText || limitationText) && (
                            <Paragraph className="caption compact">
                                {question?.helperText ?? null}
                                {(!!question.helperText && limitationText) && <br />}
                                {limitationText && (
                                    <FormattedMessage
                                        {...limitationText}
                                        values={pick(question, 'min', 'max')} />
                                )}
                            </Paragraph>
                        )}
                        {showUnsetButton && (
                            <Plain
                                className="destructive text-aligned"
                                onClick={() => {
                                    field.unset()
                                    setWhistle(Date.now())
                                }}
                                disabled={unsetButtonDisabled}>
                                <FormattedMessage
                                    id="action_reset"
                                    defaultMessage="Reset" />
                            </Plain>
                        )}
                    </Footer>
                )}
            </Field>
        </Wrapper>
    )
})

const hasValue = (value, type) => ({
    text: value => !!value?.trim(),
    single_select: value => !!value,
    multiple_select: value => !!value?.length,
    scale: value => value !== null && !!~value,
    boolean: value => [true, false].includes(value)
})[type]?.(value) ?? !!value

export default Question