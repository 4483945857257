import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'surveys'

const Surveys = props => {
    const {
        initialized,
        checkModule,
        check
    } = useAccess()

    const access = initialized && checkModule(moduleIdentifier) && ['surveys:read', 'surveys:write'].some(check)
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="surveys.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}
            newlyAdded={true}>
            <FormattedMessage
                id="noun_surveys"
                defaultMessage="Surveys" />
        </MenuItemLink>
    )
}

export default Surveys
