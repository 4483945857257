import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Page from 'contexts/page'
import { useLocation } from 'react-router-dom'
import { getPeopleProfileUrl } from 'utilities/url'
import { getFullName } from 'utilities/person'
import Message from 'components/message'
import Link from 'components/link'
import UncompletedEvents from 'pages/meetings/components/uncompleted-events'
import CompletedEvents from 'pages/meetings/components/completed-events'

const salt = 'meeting:events'

const MeetingEventsPane = () => {
    const { formatMessage } = useIntl()

    const { state } = useLocation()

    const [totalUncompleted, setTotalUncompleted] = useState(0)
    const [totalCompleted, setTotalCompleted] = useState(0)
    const [hasFetchedUncompleted, setHasFetchedUncompleted] = useState(false)
    const [hasFetchedCompleted, setHasFetchedCompleted] = useState(false)
    const [filtersApplied, setFiltersApplied] = useState(false)

    return (
        <>
            {!!state?.meeting && (
                <Message
                    type="success"
                    message={formatMessage({
                        id: 'meeting_message_new_host',
                        defaultMessage: '{user} is now the host of ”{meetingTitle}”.'
                    }, {
                        user: (
                            <Link
                                to={getPeopleProfileUrl(state.meeting.host)}
                                target="_blank">
                                {getFullName(state.meeting.host)}
                            </Link>
                        ),
                        meetingTitle: state.meeting.title
                    })} />
            )}
            <UncompletedEvents
                hasFetchedCompleted={hasFetchedCompleted}
                setHasFetchedUncompleted={setHasFetchedUncompleted}
                total={totalUncompleted + totalCompleted}
                setTotal={setTotalUncompleted}
                totalUncompleted={totalUncompleted}
                filtersApplied={filtersApplied}
                setFiltersApplied={setFiltersApplied}
                salt={salt} />
            <CompletedEvents
                hasFetchedUncompleted={hasFetchedUncompleted}
                setHasFetchedCompleted={setHasFetchedCompleted}
                total={totalCompleted + totalUncompleted}
                setTotal={setTotalCompleted}
                filtersApplied={filtersApplied}
                salt={salt} />
        </>
    )
}

export default ({ title, upgradable, ...props }) => (
    <Page
        view="meeting_events"
        title={title}
        upgrade={{
            enabled: upgradable,
            tutorial: {
                which: 'meetings',
                upgrade: { module: 'meetings' }
            }
        }}>
        <MeetingEventsPane {...props} />
    </Page>
)